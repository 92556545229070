
export function loadData(session, profile, table, isMounted, setColumns, setRows) {
    if (!isMounted.current) {
        return;
    }
    // clear data before load
    setRows({});
    setColumns([]);
    // have to load base data (including columns) & edit layer
    Promise.all([
        session.getJson('/user-edits/base/' + table.id),
        session.getJson('/user-edits/profiles/' + profile.id + "/edits/" + table.id) 
    ]).then(([baseData, edits]) => {
        if (!isMounted.current) {
            return;
        }
        // process columns - setting keys and types
        processColumns(baseData.fields, table);
        setColumns(baseData.fields);
        // TODO: validate?
        // now generate the row data from the data, columns, and edits
        const rowData = generateRows(baseData, edits.data, table);
        setRows(rowData);
    }).catch(error => {
        if (error !== "Forbidden") { // detect auth failure
            window.alert("Unable to load table data!");
        }
        console.log(error);
        if (!isMounted.current) {
            return;
        }
        setColumns([]);
        setRows({});
    });
}

function generateRows({rows, fields}, edits, table) {
    const transformed = {};
    rows.forEach(row => {
        const pkValue = getPrimaryKeyFor(row, table._primaryKey);
        if (transformed[pkValue]) {
            throw new Error("Duplicate primary keys in table " + table.name);
        }
        row._primaryKey = pkValue;
        row._selected = false;
        transformed[pkValue] = row;
    });
    // now apply edits
    let error = false;
    edits.forEach(({ pkValue, columnValue }, index) => {
        const record = transformed[pkValue];
        if (record) {
            if (columnValue === "deleted") {
                record._deleted = true;
                return;
            }
            let values;
            try {
                values = JSON.parse(columnValue);
            } catch (exception) {
                console.log("Error parsing edits for", record);
                console.log(exception);
                error = true;
                return;
            }
            const valueKeys = Object.keys(values);
            fields.forEach(field => {
                if (!field._isPrimaryKey && valueKeys.indexOf(field.name) >= 0) {
                    record["_u_" + field.name] = values[field.name];
                }
            });
            return;
        }
        // else the record was added
        let newRecord;
        let newError;
        try {
            newRecord = parseAddedRecord(table, fields, pkValue, JSON.parse(columnValue));
        } catch (exception) {
            newError = exception;
        }
        if (!newRecord || newError) {
            console.log("Invalid addition!", pkValue, columnValue, newError);
            error = true;
            return;
        }
        transformed[pkValue] = newRecord; 
    });
    if (error) {
        window.alert("There were errors loading the edits for this table! See the console for details.");
    }
    return transformed;
}

export function parseAddedRecord(table, fields, pkValue, columnValues) {
    // parse the primary key value into fields
    const newRecord = {
        _primaryKey: pkValue,
        _addedRow: true,
        _selected: false
    };
    try {
        setPrimaryKeyValues(newRecord, pkValue, table._primaryKey);
    } catch(error) {
        console.log(error);
        return;
    }
    // now set the value for any non-primary key column
    const valueKeys = Object.keys(columnValues);
    fields.filter(f => !f._isPrimaryKey).forEach(field => {
        if (valueKeys.indexOf(field.name) >= 0) {
            newRecord[field.name] = columnValues[field.name];
        } else if (field._input.type === "checkbox") {
            newRecord[field.name] = 0; // init boolean values to false instead of empty
        } else {
            newRecord[field.name] = "";
        }
    });
    return newRecord;
}

function processColumns(columns, table) {
    // mark the primary keys
    columns.forEach(column => {
        if (table._primaryKey.indexOf(column.name) >= 0) {
            column._isPrimaryKey = true;
        }
        column._input = getInputTypeFor(column.type);
    });
}

// convert a mysql type (https://stackoverflow.com/a/64774749) to an input 'type' (text, number, etc.)
function getInputTypeFor(mysqlType) {
    switch (mysqlType) {
        case "VARCHAR":
        case "VAR_STRING":
        case "STRING":
            return { type: "text" };
        case "TINY":
            return { type: "checkbox" };
        case "SHORT":
        case "LONG":
        case "LONGLONG":
            return { type: "number", step: 1 };
        case "FLOAT":
        case "DECIMAL":
        case "DOUBLE":
            return { type: "number", step: 0.0001 };
        case "DATE":
            return { type: "date" };
        case "DATETIME":
            return { type: "datetime-local" };
        default:
            break;
    }
    console.log("Unknown type", mysqlType);
    return { type: "text" };
}

export function getPrimaryKeyFor(row, keys) {
    return JSON.stringify(keys.map(key => row[key]));
}

function setPrimaryKeyValues(row, primaryKey, keys) {
    const pkArray = JSON.parse(primaryKey);
    if (pkArray.length !== keys.length) {
        throw new Error("Invalid primary key: " + primaryKey);
    }
    for (var i = 0; i < pkArray.length; ++i) {
        row[keys[i]] = pkArray[i];
    }
}

export function saveData(session, profileId, dataTableId, rows, columns) {
    // parse out the user edits from rows   TODO:  wrap in promise?
    const edits = [];
    Object.entries(rows).forEach(([pkValue, row]) => {
        if (row._deleted) {
            edits.push({ profileId, dataTableId, pkValue, columnValue: "deleted" });
        } else if (row._addedRow) {
            // columnValue is all non-primary keys
            const value = {};
            columns.forEach(column => {
                if (!column._isPrimaryKey) {
                    value[column.name] = row[column.name];
                }
            });
            edits.push({ profileId, dataTableId, pkValue, columnValue: JSON.stringify(value) });
        } else {
            const rowChanges = parseChanges(row, columns);
            if (rowChanges) {
                edits.push({ profileId, dataTableId, pkValue, columnValue: JSON.stringify(rowChanges) });
            }
        }

    });
    // TODO: other client-side validation?
    console.log("Saving", edits.length, "edits");
    return session.postJson('/user-edits/profiles/' + profileId + "/edits/" + dataTableId, { edits });
}

function parseChanges(row, columns) {
    const changes = {};
    const rowKeys = Object.keys(row);
    let foundChanges = false;
    columns.forEach(column => {
        if (!column._isPrimaryKey && rowKeys.indexOf('_u_' + column.name) >= 0) {
            foundChanges = true;
            changes[column.name] = row['_u_' + column.name];
        }
    });
    return foundChanges ? changes : null;
}

export function rowHasEdits(row) {
    // any keys with '_u_' prefix TODO: good enough?
    return Object.keys(row).filter(key => key.startsWith("_u_")).length > 0;
}
