
import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faX } from '@fortawesome/free-solid-svg-icons';

import PaginatedTable, { Header } from "../Table/PaginatedTable";
import useFilter from "../../util/useFilter";
import genericSort from '../../util/generic-sort';

const FACILITY_COLS = [ "facilityfrom", "facilityto", "routefrom", "routeto" ];

// 3 step process:
// choose routes to add
// optional update from/to, including duplicating with new from/to
// view new routes (& check against dupes), & submit

export default function AddModalNewRoutes({ sites, newRoutes, setNewRoutes, setDisplayIds }) {
    const [tableData, setTableData] = React.useState([]);
    const [filter, updateFilter] = useFilter();
    const [sort, setSort] = React.useState(null);
    const [selected, setSelected] = React.useState({});
    const [clearSelectedRows, setClearSelectedRows] = React.useState(false);

    React.useEffect(() => {
        setTableData(doSortAndFilter(newRoutes, filter, sort));
        setClearSelectedRows(v => !v);
    }, [filter, sort, newRoutes]);

    React.useEffect(() => {
        setDisplayIds((selected?.selectedRows || []).map(r => r.routeid));
    }, [selected, setDisplayIds]);

    const onSort = (column, direction) => {
        let order = null;
        if (FACILITY_COLS.includes(column)) {
            order = sites.ids; // pre-sorted by facility name
        }
        setSort(direction ? { column, direction, order } : null);
    };

    // build filter headings
    const columns = buildHeaders(sort, onSort, updateFilter, sites, setNewRoutes);

    const tableProps = {
        columns,
        fixedHeader: true,
        fixedHeaderScrollHeight: "250px",
        selectableRows: true,
        clearSelectedRows,
        onSelectedRowsChange: setSelected,
    };

    return (<Row>
        <Col>
            <PaginatedTable data={tableData} tableProps={tableProps} />
        </Col>
    </Row>);
}

function buildHeaders(sort, onSort, onFilter, sites, setNewRoutes) {
    
    const opts = { sortable: true, sort, onSort, onFilter, maxFilterHeight: "150px" };

    const siteIds = sites.ids;
    const siteDisplay = id => facilityName(id, sites);
    const copyRow = row => setNewRoutes(prev => {
        const next = [...prev];
        const index = next.map(r => r.index).reduce((i1, i2) => Math.max(i1, i2), 0) + 1;
        const nextIndex = next.findIndex(r => r.index === row.index) + 1;
        next.splice(nextIndex, 0, { ...row, index });
        return next;
    });
    const deleteRow = row => setNewRoutes(prev => prev.filter(r => r.index !== row.index));

    const idHead = <Header text="Route ID" column="routeid" {...opts} />;
    const fromHead = <Header text="Route From" column="facilityfrom" {...opts} idList={siteIds} displayFn={siteDisplay} />;
    const toHead = <Header text="Route To" column="facilityto" {...opts} idList={siteIds} displayFn={siteDisplay} />;
    const routeFromHead = <Header text="From" column="routefrom" {...opts} idList={siteIds} displayFn={siteDisplay} />;
    const routeToHead = <Header text="To" column="routeto" {...opts} idList={siteIds} displayFn={siteDisplay} />;

    const columns = [
        { name: idHead, grow: 0.5, selector: row => row.routeid },
        { name: fromHead, selector: row => row.facilityfrom, format: row => siteDisplay(row.facilityfrom) },
        { name: toHead, selector: row => row.facilityto, format: row => siteDisplay(row.facilityto) },
        // use key for site selector - it needs to be distinct for each row
        { name: routeFromHead, selector: row => row.routefrom,
            format: row => <SiteSelector key={row.index} prop="routefrom" {...{row, sites, setNewRoutes}} /> },
        { name: routeToHead, selector: row => row.routeto,
            format: row => <SiteSelector key={row.index} prop="routeto" {...{row, sites, setNewRoutes}} /> },
        { name: '', width: '32px', center: true, selector: row => row.routeid,
            format: row => <FontAwesomeIcon className="clickable" icon={faCopy} title="Copy this row" onClick={() => copyRow(row)} /> },
        { name: '', width: '32px', center: true, selector: row => row.routeid,
            format: row => <FontAwesomeIcon className="clickable text-danger" icon={faX}
                title="Delete this row" onClick={() => deleteRow(row)} /> }
    ];

    return columns;
}

function SiteSelector({ row, prop, sites, setNewRoutes }) {
    const [siteId, setSiteId] = React.useState(row[prop]);

    React.useEffect(() => {
        setSiteId(row[prop]);
    }, [row, prop]);

    const onSiteChange = e => {
        const newSiteId = parseInt(e.target.value);
        setSiteId(newSiteId);
        setNewRoutes(prev => {
            const prevRowIdx = prev.findIndex(r => r.index === row.index);
            const prevRow = prev[prevRowIdx];
            if (prevRow[prop] !== newSiteId) {
                const next = [...prev];
                next[prevRowIdx] = { ...prevRow, [prop]: newSiteId };
                return next;
            }
            return prev;
        })
    };

    return <select className="w-100" value={siteId} onChange={onSiteChange}>
        {sites.ids.map(id => <option key={id} value={id}>
            {facilityName(id, sites)}
        </option>)}
    </select>;
}

function doSortAndFilter(newRoutes, filter, sort) {
    const data = newRoutes.filter(route => checkFilter(route, filter));
    if (Array.isArray(sort?.order)) {
        const field = sort.column;
        sort = { ...sort, column: (row) => sort.order.indexOf(row[field]) };
    }
    return genericSort(data, sort);
}

function checkFilter(route, filter) {
    return !Object.entries(filter).find(([field, value]) => {
        // TODO: string wildcard?
        return route[field] !== value;
    })
}

function facilityName(id, sites) {
    return sites.data[id]?.name || `Facility ${id}`;
}
