
import React from 'react';

import { useSessionContext } from '../../session-context';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

const BASE_USER = { name: '', email: '', active: false, admin: false, gov: false };

export default function AddModal({ open, onClose, onSave }) {
    const [data, setData] = React.useState(BASE_USER);

    React.useEffect(() => {
        setData(BASE_USER);
    }, [open]);
    
    return <Modal animation={false} show={open} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title>Add user</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <AddBody data={data} setData={setData} onSave={onSave} />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" type="submit" form="add-user-form">Save</Button>
            <Button variant="danger" type="button" onClick={onClose}>Cancel</Button>
        </Modal.Footer>
    </Modal>;
}

function AddBody({ data, setData, onSave }) {
    const session = useSessionContext();
    return <Form id="add-user-form" onSubmit={e => doSubmit(e, session, data, onSave)}>
        <Form.Group className="mb-2">
            <Form.Label>Username</Form.Label>
            <Form.Control type="text" value={data.name} required onChange={e => setData(old => ({ ...old, name: e.target.value }))} />
        </Form.Group>
        <Form.Group className="mb-2">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" value={data.email} required onChange={e => setData(old => ({ ...old, email: e.target.value }))} />
        </Form.Group>
        <Form.Group className="mb-2 user-select-none" controlId="edit-user-active-check">
            <Form.Check type="checkbox" label="Active" checked={data.active}
                onChange={e => setData(old => ({ ...old, active: e.target.checked }))} />
        </Form.Group>
        <Form.Group className="mb-2 user-select-none" controlId="edit-user-admin-check">
            <Form.Check type="checkbox" label="Admin" checked={data.admin}
                onChange={e => setData(old => ({ ...old, admin: e.target.checked }))} />
        </Form.Group>
        <Form.Group className="mb-2 user-select-none" controlId="edit-user-gov-check">
            <Form.Check type="checkbox" label="Govt User" checked={data.gov}
                onChange={e => setData(old => ({ ...old, gov: e.target.checked }))} />
        </Form.Group>
        <Form.Text>After adding the user, a password can be set with the 'Forgot Password?' link on the login page</Form.Text>
    </Form>;
}

function doSubmit(e, session, data, onSave) {
    e.preventDefault();
    if (!data.name || !data.email) {
        window.alert("You must provide a username and email!");
        return;
    }

    // save
    session.postJson('/authenticate/user/', data).then(onSave).catch(error => {
        console.log("Error adding user", error);
        window.alert("Error saving user!");
    });
}
