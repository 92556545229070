import React from 'react';

import { useSessionContext } from '../../session-context';
import StorageDisplay from '../StorageDisplay';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import SharedTable from './sharedTable';
import NewComparisonModal from '../Comparison/NewComparisonModal';
import { loadSharedResults } from '../../util/result-data';

export default function SharedResults() {
    const session = useSessionContext();
    const [shared, setShared] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    // hook for comparing a scenario
    const [toCompare, setToCompare] = React.useState(null);

    React.useEffect(() => {
        setLoading(true);
        setShared([]);
        loadSharedResults(session).then(data => {
            data.forEach(scen => scen.resultDir = scen.result_dir);
            setShared(data);
            setLoading(false);
        }).catch(error => {
            console.log("Error loading shared scenarios!", error);
            setShared([]);
            setLoading(false);
        });
    }, [session]);

    return (<>
        <StorageDisplay />
        <div className="px-3">
            <Container fluid className="text-center mb-3">
                <Row><Col><h3>Shared with Me</h3></Col></Row>
                <Row><Col><SharedTable scenarios={shared} loading={loading} setToCompare={setToCompare}/></Col></Row>
            </Container>
        </div>
        <NewComparisonModal show={!!toCompare} setShow={() => setToCompare(null)} seed={toCompare} />
    </>);
}
