
import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import ColumnInput, { nullsToBlank } from './columnInput';
import { getPrimaryKeyFor, parseAddedRecord } from './data';
import { Action } from './dataReducer';

export default function AddRecordForm({ data, dataDispatch, table, columns, setModalMessage }) {
    const [primaryKey, setPrimaryKey] = React.useState({});
    const [duplicate, setDuplicate] = React.useState(false);

    const primaryKeyColumns = columns.filter(col => col._isPrimaryKey);

    React.useEffect(() => {
        const pkString = getPrimaryKeyFor(primaryKey, table._primaryKey);
        setDuplicate(!!data.rows[pkString]);
    }, [data, primaryKey, table]);

    const onSubmit = e => {
        e.preventDefault();
        if (duplicate) {
            return window.alert("Can't create new record with duplicate id!");
        }
        // initialize new records with no set fields (other than pk values)
        const newRecord = parseAddedRecord(table, columns, getPrimaryKeyFor(primaryKey, table._primaryKey), {});
        dataDispatch({ type: Action.ADD_RECORD, payload: newRecord });
        setModalMessage({});
    };

    return (<>
        <p>Enter the primary key to use for the new record:</p>
        <Form onSubmit={onSubmit}>
            {primaryKeyColumns.map(col =>
                <PrimaryKeyField key={col.name} column={col} primaryKey={primaryKey} setPrimaryKey={setPrimaryKey} />)}
            
            <p className="text-danger">{duplicate ? "Duplicate primary key!" : ""}&nbsp;</p>
            <Button type="submit" variant="primary" disabled={duplicate}>Add new record</Button>
        </Form>
    </>)
}

function PrimaryKeyField({ column, primaryKey, setPrimaryKey }) {
    const value = nullsToBlank(primaryKey[column.name]);
    const onChange = newValue => {
        const pk = {...primaryKey};
        pk[column.name] = newValue;
        setPrimaryKey(pk);
    };
    // checkboxes are more complicated in bootstrap...
    if (column._input.type === "checkbox") {
        return (<Form.Group className="mb-3">
            <div className="form-check">
                <ColumnInput column={column} value={value} onChange={onChange} className="form-check-input" />
                <div className="form-check-label">{column.name}</div>
            </div>
        </Form.Group>);
    }
    return (<Form.Group className="mb-3">
        <Form.Label>{column.name}</Form.Label>
        <ColumnInput column={column} value={value} onChange={onChange} className="form-control" />
    </Form.Group>);
}
