
import Table from '../../util/Table';

import { DATE_FMT } from '../../util/scenarioFunctions';
import DownloadLink from '../../util/DownloadLink';

// base columns
const BASE_COLUMNS = [
    { name: "Scenario", sortable: true, grow: 2, selector: row => row.scenario, format: scenarioName, wrap: true },
    { name: "Run Date", sortable: true, selector: scen => scen.runDate ? scen.runDate.format(DATE_FMT) : scen.result_dir},
    { name: "Size", sortable: true, grow: 0.75, selector: scen => scen.sizeBytes, format: scen => scen.size },
    {
        name: "Download", ignoreRowClick: true, allowOverflow: true, selector: row => row.scenario,
        format: scen => (<DownloadLink href={`/results/archive/${encodeURIComponent(scen.scenario)}/${scen.archiveFile}`}>
            Download
        </DownloadLink>)
    }
];

function scenarioName(scenario) {
    return scenario.gov ? (<><span className="text-danger fw-bold">OUO</span> {scenario.scenario}</>) : scenario.scenario;
}

export default function ArchiveTable({ scenarios, loading }) {
    const columns = BASE_COLUMNS.slice();
    // TODO: deletion policy for archived results?
    // columns.push({ name: "Actions", ignoreRowClick: true, allowOverflow: true, selector: row => row.scenario,
    //     format: scenario => <SharedActionDropdown {...{ scenario, setToCompare}} /> });
    
    return <Table columns={columns} data={scenarios} progressPending={loading} />
}
