
import React from 'react';

import { useSessionContext } from '../../session-context';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';

import { useParams, useNavigate } from 'react-router-dom';

import EditTable from './editTable';
import ActionEventEmitter, { Actions } from './actionEventEmitter';
import useIsMountedRef from '../../util/useIsMountedRef';

export default function EditProfile() {
    const session = useSessionContext();
    // profile, available tables and selected table
    const [profile, setProfile] = React.useState({});
    const [tables, setTables] = React.useState([]);
    const [selected, setSelected] = React.useState(null);
    // profile edit summary so we know which tables have mods
    const [summary, setSummary] = React.useState([]);

    // does the current table have changes in browser?
    // we need this up here to warn about unsaved on exit or change
    const [changes, setChanges] = React.useState(false);
    const [eventEmitter] = React.useState(new ActionEventEmitter());
    const isMounted = useIsMountedRef();

    const navigate = useNavigate();

    const editable = profile.username === session.user.name;
    eventEmitter.on(Actions.EXIT, () => {
        if (editable && changes && !window.confirm("You have unsaved changes! Are you sure you want to exit?")) {
            return;
        }
        navigate("/user-edits");
    });
    const emitExit = () => eventEmitter.dispatchEvent(Actions.EXIT);

    // profile id comes from the url
    const { profileId } = useParams();

    // load the profile and tables
    React.useEffect(() => loadProfile(session, profileId, isMounted, navigate, setProfile), [session, profileId, navigate, isMounted]);
    React.useEffect(() => loadTables(session, profile.schema, isMounted, setTables), [session, profile, isMounted]);
    // on profile change, clear selected
    React.useEffect(() => setSelected(null), [profileId]);
    // on profile or table change, clear changes
    React.useEffect(() => setChanges(false), [profileId, selected]);
    // load profile summary. reload on changes value change (save, change table, etc.)
    React.useEffect(() => loadSummary(session, profileId, isMounted, setSummary), [session, profileId, changes, isMounted]);

    const warnSetSelected = (newSelected) => {
        if (changes && !window.confirm("You have unsaved changes! Are you sure you want to change tables?")) {
            return;
        }
        setSelected(newSelected);
    };

    return <div className="px-3"><Container fluid>
        <Row className="mt-2">
            <Col md="6" className="mb-1 d-flex align-items-center">
                <div><Button size="sm" type="button" variant="danger" className="me-2"
                        title="Close editing session" onClick={emitExit}>X</Button></div>
                <div><h4 className="mb-0">Edit Profile: {profile.profileName}</h4></div>
            </Col>
            <Col md="6" className="mb-1">
                <TableChooser tables={tables} summary={summary} selected={selected} setSelected={warnSetSelected} />
            </Col>
        </Row>
        {selected ?
            <EditTable profile={profile} table={selected} eventEmitter={eventEmitter} setChanges={setChanges} /> :
            <Row><Col><p>Choose a table to begin</p></Col></Row> }
    </Container></div>;
}

function TableChooser({tables, summary, selected, setSelected}) {
    const selectedId = selected ? selected.id : "";
    const setById = id => setSelected(tables.find(table => table.id === id));

    return (<Form onSubmit={e => e.preventDefault()}>
        <Form.Group controlId="tableSelect">
            <Form.Label className="sr-only">Table</Form.Label>
            <Form.Select value={selectedId} onChange={e => setById(parseInt(e.target.value))}>
                <option value="">Select a table</option>
                {tables.map(t => <option key={t.id} value={t.id}>{getTableNameDisplay(t.name, summary)}</option>)}
            </Form.Select>
        </Form.Group>
    </Form>);
}

function getTableNameDisplay(tableName, summary) {
    const tableSummary = summary.find(val => val.table_name === tableName);
    if (!tableSummary) {
        return tableName;
    }
    const count = tableSummary.edit_count;
    return '*' + tableName + " (" + count + " edit" + (count === 1 ? '' : 's') + ")";
}

function loadProfile(session, profileId, isMounted, navigate, setProfile) {
    if (!isMounted.current) {
        return;
    }
    setProfile({});
    session.getJson('/user-edits/profiles/' + profileId).then(({ data }) => {
        if (!isMounted.current) {
            return;
        }
        setProfile(data);
    }).catch(error => {
        console.log(error);
        if (isMounted.current) {
            window.alert("Unable to load profile!");
            navigate("/user-edits");
        }
    });
}

function loadTables(session, dataSchema, isMounted, setTables) {
    if (!isMounted.current) {
        return;
    }
    setTables([]);
    if (!dataSchema) {
        return;
    }
    session.getJson('/user-edits/data/schemas/' + dataSchema.id + "/tables").then(({ data }) => {
        if (!isMounted.current) {
            return;
        }
        data = data.sort((t1, t2) => t1.name.localeCompare(t2.name));
        parseTableKeys(data);
        setTables(data);
    }).catch(error => {
        console.log(error);
    });
}

function parseTableKeys(tables) {
    tables.forEach(table => {
        // set the primary key array of column(s)
        // we rely on the server providing consistent ordering
        table._primaryKey = table.dataTableKeys.filter(key => key.keyName === "PRIMARY").map(key => key.tableColumn);
        table._uniqueKeys = {};
        table.dataTableKeys.filter(key => key.keyName !== "PRIMARY").forEach(key => {
            const keyArray = table._uniqueKeys[key.keyName] || (table._uniqueKeys[key.keyName] = []);
            keyArray.push(key.tableColumn);
        });
    });
}

function loadSummary(session, profileId, isMounted, setSummary) {
    if (!isMounted.current) {
        return;
    }
    setSummary([]);
    session.getJson("/user-edits/profiles/" + profileId + "/summary").then(({ data })=> {
        if (!isMounted.current) {
            return;
        }
        setSummary(data);
    }).catch(error => {
        console.log("Error loading summary", error);
    });
}
