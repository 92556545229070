
import React from 'react';

import { useSessionContext } from '../../session-context';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import { DATE_FMT } from '../../util/scenarioFunctions';

export default function ShareModal({ state, setState }) {
    
    return <Modal animation={false} show={!!state} onHide={() => setState(null)}>
        <Modal.Header closeButton>
            <Modal.Title>Manage result sharing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            { state ? <ShareBody setState={setState} {...state}></ShareBody> : null }
        </Modal.Body>
    </Modal>
}

function ShareBody({ scenario, users, sharing, setState }) {
    const session = useSessionContext();
    const [allUsers, setAllUsers] = React.useState(!!sharing?.all_users);
    const initial = {};
    if (!allUsers && sharing?.user_id_run_users) {
        sharing.user_id_run_users.forEach(({ id }) => initial[id] = true);
    }
    const [userState, setUserState] = React.useState(initial);

    return <>
        <p className="mb-0" style={{ wordBreak: "break-all" }}>
            <strong>Scenario: </strong>{scenario.scenario}
        </p>
        <p><strong>Results: </strong>{scenario.runDate ? scenario.runDate.format(DATE_FMT) : '???'}</p>
        <Form onSubmit={e => doSubmit(e, session, scenario, allUsers, userState, setState)}>
            <Form.Group className="mb-3" controlId="all-users-check">
                <Form.Check type="checkbox" label="Share with all users" checked={allUsers}
                    onChange={e => setAllUsers(e.target.checked)} />
            </Form.Group>
            <hr/>
            <UserCheckboxes users={users} state={userState} setState={setUserState} disabled={allUsers} />
            
            <Button variant="primary" type="submit">Save</Button>
            <Button className="ms-2" variant="danger" type="button" onClick={() => setState(null)}>Cancel</Button>
        </Form>
    </>;
}

function UserCheckboxes({users, state, setState, disabled}) {

    const handleUserCheck = (e, userId) => {
        const isChecked = e.target.checked;
        setState(previous => ({...previous, [userId]: isChecked }));
    };

    return (<>
        {users.map(user => (<Form.Group className="mb-3" key={user.id} controlId={user.id + "-check"}>
            <Form.Check type="checkbox" label={user.name} disabled={disabled} checked={state[user.id] ? true : false}
                onChange={e => handleUserCheck(e, user.id)}/>
        </Form.Group>))}
    </>);
}

function doSubmit(e, session, scenario, allUsers, userState, setState) {
    e.preventDefault();
    const update = {};
    if (allUsers) {
        update.all_users = true;
    } else {
        update.users = [];
        Object.keys(userState).forEach(id => {
            if (userState[id]) {
                update.users.push(parseInt(id, 10));
            }
        });
    }
    // save
    session.postJson('/scenarios/shared/' + encodeURIComponent(scenario.scenario) + "/" + scenario.resultDir, update)
        .then(() => setState(null))
        .catch(error => console.log("Error updating sharing", error));
}
