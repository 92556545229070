
import React from 'react';

import { useSessionContext } from '../../session-context';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';

export default function SummaryModal({ profile, setProfile, onNotesUpdate }) {
    const session = useSessionContext();
    const [summary, setSummary] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (!profile) {
            setSummary([]);
            setLoading(false);
            return;
        } // else, get profile summary
        setLoading(true);
        session.getJson("/user-edits/profiles/" + profile.id + "/summary").then(({ data }) => {
            setSummary(data);
            setLoading(false);
        }).catch(error => {
            console.log("Error loading summary", error);
        });
    }, [session, profile]); // refresh every profile change

    return (<Modal animation={false} show={!!profile} onHide={() => setProfile(null)}>
        <Modal.Header closeButton>
            <Modal.Title>
                Summary {profile ? ("'" + profile.profileName + "'") : ''}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <SummaryBody summary={summary} loading={loading} />
            <NotesBody profile={profile} onNotesUpdate={onNotesUpdate} />
        </Modal.Body>
    </Modal>);
}

function SummaryBody({ summary, loading }) {
    if (loading) {
        return <p>Loading...</p>;
    }
    if (!summary || summary.length < 1) {
        return <p>No edits found</p>;
    }
    return (<ul>
        {summary.map(({ table_name, edit_count }) =>
            <li key={table_name}>{table_name}: {edit_count} edit{edit_count === 1 ? '' : 's'}</li>)}
    </ul>);
}

function NotesBody({ profile, onNotesUpdate }) {
    const [notes, setNotes] = React.useState("");
    const [rows, setRows] = React.useState(4);

    React.useEffect(() => {
        const newNotes = profile?.notes || "";
        setNotes(newNotes);
        const lines = newNotes.split('\n').length;
        setRows(lines < 4 ? 4 : (lines > 10 ? 10 : lines));
    }, [profile]);

    const noSave = notes.trim() === profile?.notes?.trim();
    const onSave = () => onNotesUpdate(profile.id, notes.trim());

    if (!profile?.mine) {
        return (<>
            <p className="fw-bold mb-1">Notes:</p>
            <p style={{ whiteSpace: "pre-wrap" }}>{profile?.notes || "{no notes}"}</p>
        </>);
    }
    return (<>
        <p className="fw-bold mb-1">Notes:</p>
        <FormControl as="textarea" className='w-100 mb-3' rows={rows} value={notes} onChange={e => setNotes(e.target.value)} />
        <Button disabled={noSave} variant='success' onClick={onSave}>Save notes</Button>
    </>);
}
