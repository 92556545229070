
import { resultDirToMoment } from './scenarioFunctions';

export function loadResults(session) {
    return session.getJson('/scenarios').then(data => {
        const loaded = data.data || [];
        // parse result directory into moment & sort
        loaded.forEach(s => s.runDate = resultDirToMoment(s.resultDir));
        return loaded;
    });
}

export function loadSharedResults(session) {
    return session.getJson('/scenarios/shared').then(data => {
        const loaded = data.scenarios || [];
        loaded.forEach(s => {
            s.gov = s.user.gov;
            s.runDate = resultDirToMoment(s.result_dir)
        });
        return loaded;
    });
}

export function loadArchived(session) {
    return session.getJson('/results/archive').then(archived => {
        // slice off .zip
        archived.forEach(s => s.runDate = resultDirToMoment(s.archiveFile?.slice(0, -4)));
        return archived;
    });
}
