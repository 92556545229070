
import React from 'react';
import { useSessionContext } from '../session-context';

// from routes provider
const FACILITY_PATH = "/api/v1/routes/facility";

/**
 * Site context, loaded from user edit tables - requires session
 * @returns
 */
export default function useSites(refresh) {
    const session = useSessionContext();
    const [sites, setSites] = React.useState({ ids: [], data: {} });
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        setError(null);
        loadData(session).then(setSites).catch(error => {
            console.log(error);
            setSites({ ids: [], data: {} });
            setError(error);
        });
    }, [session, refresh]);

    return [ sites, error ];
}

async function loadData(session) {
    const rows = await session.getJson(FACILITY_PATH);
    // sort ids by name
    const ids = rows.sort(nameCompare).map(f => f.id);
    const data = {};
    rows.forEach(f => data[f.id] = f);
    return { ids, data };
}

/**
 * @param {{ name: string }} u1 
 * @param {{ name: string }} u2 
 * @returns 
 */
function nameCompare(u1, u2) {
    return u1.name.localeCompare(u2.name, undefined, { sensitivity: 'base' });
}
