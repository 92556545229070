
import React from 'react';

import { useSessionContext } from '../../session-context';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import StickyTable from '../../util/StickyTable';

import ActionPanel, { fontSizes } from './actionPanel';
import HeaderCell, { StatusHeaderCell } from './headerCell';
import MessageModal from './messageModal';
import PaginationControl from './paginationControl';
import TableWarnings from './tableWarnings';
import TableFilter from './tableFilter';

import { loadData, saveData } from './data';
import { Actions } from './actionEventEmitter';
import useIsMountedRef from '../../util/useIsMountedRef';
import useWindowSize from '../../util/useWindowSize';
import dataReducer, { Action, initialize, getRowValue } from './dataReducer';
import { getValue, storeValue, FT_SIZE_KEY } from '../../util/localStorageJson';

import './editTable.css';

const defaultState = initialize({});
 // from local storage or default to md
const initialFontSize = fontSizes.find(s => s.name === getValue(FT_SIZE_KEY, 'string')) || fontSizes[2];

// TODO: resizeable columns? headercell width: +/-

export default function EditTable({ profile, table, eventEmitter, setChanges }) {
    const session = useSessionContext();
    const isMounted = useIsMountedRef();
    // for controlling table height
    const [ windowX, windowY ] = useWindowSize();
    // note: we can't ref the StickyTable directly, so we use an empty span
    const [tableNode, setTableNode] = React.useState(null);
    const [tableHeight, setTableHeight] = React.useState(500);

    const [fontSize, setFontSize] = React.useState(initialFontSize);

    // table data
    const [columns, setColumns] = React.useState([]);
    const [tableCols, setTableCols] = React.useState([]);
    const [data, dataDispatch] = React.useReducer(dataReducer, defaultState);
    // unique key violations
    const [uniqueViolations, setUniqueViolations] = React.useState({});
    
    // popup for displaying warnings, etc
    const [modalMessage, setModalMessage] = React.useState({});

    // trigger for forcing a re-render
    // we shouldn't have to do this, but there's something about the table that doesn't draw borders properly
    const [renderKey, setRenderKey] = React.useState('key');

    // table resizing
    const setTableRef = React.useCallback(domNode => setTableNode(domNode), []);
    React.useEffect(() => updateTableHeight(tableNode, windowY, setTableHeight), [tableNode, windowY, windowX]);

    React.useEffect(() => storeValue(FT_SIZE_KEY, fontSize.name), [fontSize]);
    
    const refreshHeight = React.useCallback(() => updateTableHeight(tableNode, windowY, setTableHeight), [tableNode, windowY]);

    const editable = profile.username === session.user.name;

    React.useEffect(() => {
        setTableCols(buildColumns(columns, editable, data.sorter, dataDispatch));
    }, [columns, editable, data.sorter]);

    // unique key checking
    React.useEffect(() => {
        setUniqueViolations(validateUniques(data.rows, table));
    }, [data.rows, table]);

    // data loader
    React.useEffect(
        () => loadData(session, profile, table, isMounted, setColumns, rows => dataDispatch({ type: Action.INIT, payload: rows })),
        [session, profile, table, isMounted]
    );

    React.useEffect(() => setChanges(data.hasChanges), [data.hasChanges, setChanges]);

    // force remount on font size change & sort - fixes table display
    const dataSortKey = data.sorter?.name || "";
    React.useEffect(() => {
        setRenderKey("key" + Date.now());
    }, [ fontSize.value, dataSortKey, modalMessage ])
    
    if (columns.length < 1) {
        return <Row><Col><p>Loading table...</p></Col></Row>;
    }

    // register events
    // if save is/can be long running, make sure we're blocking input/navigation
    eventEmitter.on(Actions.SAVE, () => {
        saveData(session, profile.id, table.id, data.rows, columns).then(() => {
            window.alert("Save successful!");
            dataDispatch({type: Action.SET_SAVED});
        }).catch(error => {
            console.log("Error saving!", error);
            if (isMounted.current) {
                window.alert("Unable to save edits!");
            }
        });
    });
    
    // state objects to pass along
    const paginationProps = { records: data.filtered.length, data, dataDispatch };

    const saveAction = () => eventEmitter.dispatchEvent(Actions.SAVE);

    return (<>
        <Row>
            <Col xs="8" md="12" className="mb-1">
                <ActionPanel {...{
                    editable, columns, data, dataDispatch, table, saveAction, setModalMessage, fontSize, setFontSize,
                    }} />
            </Col>
            <Col xs="4" md="4" className="d-flex justify-content-end justify-content-md-start mb-1">
                <span className="mx-2 mt-1 order-12 order-md-0">
                    {data.display.filter(rowIdx => data.rows[rowIdx]._selected).length} selected
                </span>
                <TableWarnings table={table} uniqueViolations={uniqueViolations} setModalMessage={setModalMessage} />
            </Col>
            <Col xs="12" md="8" className="mb-1"><PaginationControl {...paginationProps} /></Col>
        </Row>
        <Row><Col>
            <TableFilter data={data} dataDispatch={dataDispatch} columns={columns} refreshHeight={refreshHeight}/>
        </Col></Row>
        <Row><Col className="mt-2">
            <span ref={setTableRef}></span>
            <div key={renderKey} style={{ height: tableHeight, overflow: "auto", fontSize: fontSize.value }}>
                <StickyTable columns={tableCols} rowIds={data.display} data={data.rows} numSticky={editable ? 2 : 1} />
            </div>
        </Col></Row>
        <MessageModal modalMessage={modalMessage} setModalMessage={setModalMessage} />
    </>);
}

function buildColumns(columnList, editable, sorter, dataDispatch) {
    const columns = [];
    if (editable) {
        const onChange = (event, rowIdx) =>
            dataDispatch({type: Action.SELECT, payload: { selected: event.target.checked, indices: [rowIdx] }});
        columns.push({
            name: "Selected", centered: true,
            getValue: (row, id) => <input type="checkbox" checked={row._selected} onChange={e => onChange(e, id)} /> });
    }
    columns.push(new StatusHeaderCell(sorter, dataDispatch));
    columns.push(...columnList.map(col => new HeaderCell(col, editable, sorter, dataDispatch)));
    return columns;
}

function updateTableHeight(tableNode, windowHeight, setTableHeight) {
    if (tableNode) {
        setTableHeight(windowHeight - (tableNode.getBoundingClientRect().y + 10));
    }
}

function validateUniques(rows, table) {
    const keys = table._uniqueKeys;
    const keyNames = Object.keys(keys);
    if (keyNames.length < 1) {
        return {};
    }
    const uniqueValues = {};
    const uniqueViolations = {};
    keyNames.forEach(name => uniqueValues[name] = {});

    // for each row, calculate the keys
    Object.keys(rows).forEach(rowIdx => {
        const row = rows[rowIdx];
        keyNames.forEach(keyName => {
            const key = keys[keyName];
            const rowValue = calculateRowUnique(row, key);
            if (uniqueValues[keyName][rowValue]) {
                // dupe found!
                const violations = uniqueViolations[keyName] || (uniqueViolations[keyName] = {});
                // get or create the violation record, adding this new rowIdx
                (violations[rowValue] || (violations[rowValue] = {
                    key, records: [ uniqueValues[keyName][rowValue] ] 
                })).records.push(rowIdx);
            } else {
                uniqueValues[keyName][rowValue] = rowIdx;
            }
        });
    });
    return uniqueViolations;
}

function calculateRowUnique(row, key) {
    const keyValue = key.map(colName => getRowValue(row, colName));
    return JSON.stringify(keyValue);
}
