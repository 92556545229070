
import React from 'react';

import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';

import RouteTable from '../UserRoutes/RouteTable';
import useResizeObserver from '../../util/useResizeObserver';

// 3 step process:
// choose routes to add
// optional update from/to, including duplicating with new from/to
// view new routes (& check against dupes), & submit

export default function AddModalBaseSelect({ sites, selected, setSelected, setDisplayIds }) {
    const [marked, setMarked] = React.useState([]);
    const [clearTblSelect, setClearTblSelect] = React.useState(false);
    const [expand, setExpand] = React.useState(true);
    const [tableDiv, setTableDiv] = useResizeObserver();

    const selText = (selected.length === 1) ? "route" : "routes";
    const markText = (marked.length === 1) ? "route" : "routes";

    const doSelection = () => {
        setSelected(prev => [...prev, ...marked]);
        setMarked([]);
        setClearTblSelect(v => !v);
    };

    const setTableSelect = React.useCallback(tableSelection => {
        const markedRows = tableSelection?.selectedRows || [];
        // console.log(markedRows, selected);
        // don't add dupes
        const toAdd = markedRows.filter(m => !selected.find(s => s.routeid === m.routeid));
        setMarked(toAdd);
        setDisplayIds(markedRows.map(s => s.routeid));
    }, [setDisplayIds, selected]);

    const markedRowStyle = [{
        when: row => selected.find(s => s.routeid === row.routeid),
        style: { backgroundColor: 'lightgrey' },
    }];

    return (<Row>
        <Col lg={{ span: 4, order: 'last' }} className="d-flex flex-column pb-2" style={{ maxHeight: `${tableDiv?.height || 250}px` }}>
            <div className="d-flex align-items-center">
                <Button size="sm" onClick={doSelection} disabled={marked.length < 1}>
                    Select {marked.length} {markText}
                </Button>
                <span className="mx-2">{selected.length} {selText} selected</span>
                <FontAwesomeIcon icon={ expand ? faCaretDown : faCaretRight}
                    className="clickable" title={`Click to ${expand ? 'hide' : 'show' } routes`}
                    onClick={() => setExpand(v => !v)} />
            </div>
            <CollapsibleRoutes sites={sites} expand={expand} selected={selected} setSelected={setSelected} />
        </Col>
        <Col lg="8">
            <div ref={setTableDiv} className='position-relative'>
                <RouteTable setSelected={setTableSelect} fixedHeight="250px" maxFilterHeight="150px"
                    parentClearSelected={clearTblSelect} conditionalRowStyles={markedRowStyle}/>
            </div>
        </Col>
    </Row>);
}

function CollapsibleRoutes({ sites, expand, selected, setSelected }) {
    const clearRoute = route => setSelected(prev => prev.filter(r => r.routeid !== route.routeid));

    return <Collapse in={expand}>
        <div style={{ overflow: 'auto' }}>
            { selected.length > 0 ? <Button variant='link' className="text-danger p-0" onClick={() => setSelected([])} >
                X - Clear All
            </Button> : null }
            { selected.map(r => <div key={r.routeid} className="d-flex align-items-center">
                <Button variant='link' className="text-danger p-0 me-2" style={{ textDecoration: 'none' }}
                    onClick={() => clearRoute(r)} >X</Button>
                Route {r.routeid} - {facilityName(r.facilityfrom, sites)} to {facilityName(r.facilityto, sites)}
            </div>) }
        </div>
    </Collapse>;
}

function facilityName(id, sites) {
    return sites.data[id]?.name || `Facility ${id}`;
}
