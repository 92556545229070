
export function CompareNumbersNullsLast(n1, n2) {
    const n1Null = !n1 && n1 !== 0;
    const n2Null = !n2 && n2 !== 0;
    if (n1Null && n2Null) {
        return 0;
    } else if (n1Null) {
        return 1;
    } else if (n2Null) {
        return -1;
    } else {
        return CompareNumbersNonNull(n1, n2);
    }
}

export function CompareNumbersNonNull(n1, n2) {
    if (n1 < n2) {
        return -1;
    } else if (n1 > n2) {
        return 1;
    } else {
        return 0;
    }
}

export function CompareStringsBlanksLast(s1, s2) {
    if (!s1 && !s2) {
        return 0;
    } else if (!s1) {
        return 1;
    } else if (!s2) {
        return -1;
    } else {
        return s1.localeCompare(s2);
    }
}
