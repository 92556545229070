import React from "react";

import { Header } from "../Table/PaginatedTable";
import PaginatedServerTable from "../Table/PaginatedServerTable";
import useFilter, { buildFilterString } from "../../util/useFilter";
import useUsers from "../../util/useUsers";
import useSites from "../../util/useSites";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangleList } from "@fortawesome/free-solid-svg-icons";

// Note: this is also used in the profile editor add modal

export default function RouteTable(props) {
    const {
        setSelected, setShowLinks, refresh, fixedHeight, maxFilterHeight, parentClearSelected, conditionalRowStyles
    } = props;
    const [filter, updateFilter] = useFilter();
    const [filterStr, setFilterStr] = React.useState(null);
    const [sort, setSort] = React.useState(null);
    const [clearSelectedRows, setClearSelectedRows] = React.useState(false);
    const [ users ] = useUsers({ includeInactive: true });
    const [ sites ] = useSites();

    React.useEffect(() => {
        setFilterStr(buildFilterString(filter));
        setClearSelectedRows(v => !v);
    }, [filter]);

    React.useEffect(() => {
        setSelected({});
    }, [clearSelectedRows, setSelected]);

    React.useEffect(() => {
        setClearSelectedRows(v => !v);
    }, [parentClearSelected]);

    const onSort = (column, direction) => {
        let order = null;
        if (column === "createdby") {
            order = users.ids; // pre-sorted by user name
        }
        setSort(direction ? { column, direction, order } : null);
        setClearSelectedRows(v => !v);
    };

    // build filter headings
    const columns = buildHeaders(sort, onSort, updateFilter, setShowLinks, users, sites, maxFilterHeight);

    const tableProps = {
        columns,
        fixedHeader: true,
        fixedHeaderScrollHeight: fixedHeight || "300px",
        conditionalRowStyles,
        selectableRows: true,
        clearSelectedRows,
        onSelectedRowsChange: setSelected,
    };

    return (<PaginatedServerTable dataUrl="/api/v1/routes" refresh={refresh} filter={filterStr} sort={sort} tableProps={tableProps} />);
}

/**
 * @param {{ column: string, direction: string }?} sort
 * @param {function} onSort 
 * @param {function} onFilter 
 * @param {function?} setShowLinks
 * @param {{ ids: [], data: {} }} users
 * @param {{ ids: [], data: {} }} sites
 * @returns 
 */
function buildHeaders(sort, onSort, onFilter, setShowLinks, users, sites, maxFilterHeight) {
    
    const opts = { sortable: true, sort, onSort, onFilter, maxFilterHeight };

    const idHead = <Header text="Route ID" column="routeid" {...opts} />;
    const fromHead = <Header text="From" column="facilityfrom" {...opts}
        idList={sites.ids} displayFn={id => sites.data[id]?.name} />;
    const toHead = <Header text="To" column="facilityto" {...opts}
        idList={sites.ids} displayFn={id => sites.data[id]?.name} />;
    const creatorHead = <Header text="Created By" column="createdby" {...opts}
        idList={users.ids} displayFn={id => users.data[id]?.name} />;
    const dateHead = <Header text="Created" column="createdate" {...opts} />;

    const columns = [
        { name: idHead, grow: 0.75, selector: row => row.routeid },
        { name: fromHead, selector: row => row.facilityfrom,
            format: row => sites.data[row.facilityfrom]?.name || row.facilityfrom },
        { name: toHead, selector: row => row.facilityto,
            format: row => sites.data[row.facilityto]?.name || row.facilityto },
        { name: creatorHead, selector: row => row.createdby, format: row => users.data[row.createdby]?.name || "--" },
        { name: dateHead, selector: row => row.createdate, format: row => row.createdate?.slice(0, 10) },
    ];
    if (typeof setShowLinks === 'function') {
        columns.push({ name: '', width: "48px", selector: row => row.routeid, center: true,
            format: row => <FontAwesomeIcon icon={faRectangleList} className="clickable text-primary"
                title="View route details" onClick={() => setShowLinks(row.routeid)} /> });
    }

    return columns;
}
