
import React from 'react';

import Accordion from 'react-bootstrap/Accordion';

import LoadingSpinner from '../LoadingSpinner';

import { useSessionContext } from '../../session-context';

// 3 step process:
// choose routes to add
// optional update from/to, including duplicating with new from/to
// view new routes (& check against dupes), & submit

export default function AddModalVerification({ profileId, sites, newRoutes, verification, setVerification }) {
    const session = useSessionContext();

    React.useEffect(() => {
        console.log("Running verification");
        setVerification({ running: true });
        verify(session, profileId, newRoutes).then(setVerification);
    }, [profileId, session, newRoutes, setVerification]);

    return <div className="position-relative" style={{ minHeight: "250px" }}>
        {verification.running ? <LoadingSpinner /> : null}
        <Verification verification={verification} sites={sites} />
    </div>
}

function Verification({ verification, sites }) {
    const { error, status, update } = verification;
    if (error) {
        return <p className="text-danger">
            {error}
        </p>;
    } else if (!status) {
        return null; // Loading spinner in parent
    }
    const { localDuplicates, serverOverwrites, serverMatches } = status;
    const tabs = [];
    if (localDuplicates.length > 0) {
        let eventKey = tabs.length.toString();
        tabs.push({
            eventKey,
            header: "Errors",
            body: <ul>
                {localDuplicates.map(r => <li key={r.index}>
                    Duplicate route for {facilityName(r.routefrom, sites)} to {facilityName(r.routeto, sites)}. Route ID: {r.routeid}
                </li>)}
            </ul>
        });
    }
    if (serverOverwrites.length > 0) {
        let eventKey = tabs.length.toString();
        tabs.push({
            eventKey,
            header: "Warnings",
            body: <ul>
                {serverOverwrites.map(({ serverRoute, localRoute }) => <li key={localRoute.index}>
                    The route for {facilityName(localRoute.routefrom, sites)} to {facilityName(localRoute.routeto, sites)} will
                    be overwritten. Current route ID: {serverRoute.routeid} New route ID: {localRoute.routeid}
                </li>)}
            </ul>
        });
    }
    let eventKey = tabs.length.toString();
    const numUpdates = update.add.length;
    const updateText = numUpdates === 0 ? "No new or updated routes" :
        (numUpdates === 1 ? "1 new or updated route" : `${numUpdates} new or updated routes`);
    tabs.push({
        eventKey,
        header: "Info",
        body: <ul>
            <li>{updateText}</li>
            {serverMatches.map(route => <li key={route.index}>
                The route for {facilityName(route.routefrom, sites)} to {facilityName(route.routeto, sites)} is
                identical and will not be updated.
            </li>)}
        </ul>
    });

    return <Accordion defaultActiveKey="0">
        {tabs.map(t => <Accordion.Item key={t.eventKey} eventKey={t.eventKey}>
            <Accordion.Header>{t.header}</Accordion.Header>
            <Accordion.Body>{t.body}</Accordion.Body>
        </Accordion.Item>)}
    </Accordion>
}

/**
 * 
 * @param {import('../../session-context').Session} session 
 * @param {number} profileId 
 * @param {{ index: number, routeid: number, routefrom: number, routeto: number }[]} newRoutes 
 * @returns 
 */
async function verify(session, profileId, newRoutes) {
    try {
        const serverRoutes = await session.getJson(`/api/v1/routes/profiles/${profileId}/routes`);
        const localDuplicates = []; // new routes that have the same endpoints
        const serverOverwrites = []; // new route that would overwrite a server route
        const serverMatches = []; // routes already matching server routes
        const update = { remove: [], add: [] }; // update body to post
        newRoutes.forEach(route => {
            if (localDuplicates.includes(route)) {
                return; // already processed
            }
            const localDupes = newRoutes.filter(r => r.routefrom === route.routefrom
                && r.routeto === route.routeto && route.index !== r.index);
            if (localDupes.length > 0) {
                localDuplicates.push(route, ...localDupes);
                return;
            }
            // else check for server overwrite
            const serverRoute = serverRoutes.find(r => r.facilityfrom === route.routefrom && r.facilityto === route.routeto);
            if (serverRoute) {
                if (serverRoute.routeid !== route.routeid) {
                    serverOverwrites.push({ serverRoute, localRoute: route });
                    update.remove.push({ ...serverRoute });
                    update.add.push(route);
                } else {
                    serverMatches.push(route);
                }
                return;
            }
            // else, we're a clean new route
            update.add.push(route);
        });
        return { running: false, status: { localDuplicates, serverOverwrites, serverMatches }, update };
    } catch (error) {
        console.log(error);
        const message = "Unable to verify routes! Please go back and try again.";
        return { running: false, error: message };
    }
}

function facilityName(id, sites) {
    return sites.data[id]?.name || `Facility ${id}`;
}
