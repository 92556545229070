
import React from 'react';

import Leaflet from 'leaflet';

import { useSessionContext } from '../session-context';

/**
 * hook for handling route shape loading
 * @param {number[]} routeIdList this list of ids to return shapes for
 * @returns 
 */
export default function useRouteShapes(routeIdList) {
    const session = useSessionContext();
    const [loading, setLoading] = React.useState(false);
    // TODO: should we periodically empty out the loaded cache?
    const [loadedGeo, setLoadedGeo] = React.useState({}); // id => geojson
    const [selectedGeo, setSelectedGeo] = React.useState({}); // id => geojson

    // when selection changes, see if we need to load geojson
    React.useEffect(() => {
        const ids = (routeIdList || []).filter(id => !loadedGeo[id]);
        if (ids.length > 0) {
            // console.log("Loading", ids);
            loadGeoJson(session, ids, setLoading, setLoadedGeo);
        }
    }, [session, routeIdList, loadedGeo]);

    // when selection or set of loaded geojson changes, update selectedgeo
    React.useEffect(() => {
        const selectedAndLoaded = {};
        (routeIdList || []).forEach(id => {
            const geojson = loadedGeo[id];
            if (geojson) {
                selectedAndLoaded[id] = geojson;
            }
        });
        setSelectedGeo(selectedAndLoaded);
    }, [session, routeIdList, loadedGeo]);

    return { loading, geoJsonFeatures: selectedGeo };
}

/**
 * 
 * @param {import('../session-context').Session} session 
 * @param {(number|string)[]} ids 
 * @param {function} setLoading 
 * @param {function} setLoadedGeo 
 */
async function loadGeoJson(session, ids, setLoading, setLoadedGeo) {
    try {
        setLoading(true);
        const loaded = {};
        
        // batch in 7s
        const toLoad = [...ids];
        while (toLoad.length > 0) {
            const ids = toLoad.splice(0, 7);
            await loadBatch(session, ids, loaded);
        }
        setLoadedGeo(prev => ({ ...prev, ...loaded }));
    } catch (error) {
        console.log(error);
    } finally {
        setLoading(false);
    }
}

async function loadBatch(session, ids, loaded) {
    await Promise.all(ids.map(async id => {
        try {
            const resp = await session.getJson('/api/v1/routes/shapes/' + id);
            // throws on invalid
            loaded[id] = Leaflet.geoJSON(resp);
        } catch (error) {
            // console.log("Unable to load shape id", id, error);
            loaded[id] = "Unable to load shape " + id;
        }
    }));
}
