
import React from 'react';

import { useSessionContext } from '../../session-context';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Modal from 'react-bootstrap/Modal';

const FORM_ID = "user-projection-create-modal-form";

const DATA_DEFAULTS = { name: "", notes: "" };

export default function CreateModal({ createNew, setCreateNew, myProjections, doRefresh }) {
    const session = useSessionContext();
    const [data, setData] = React.useState(DATA_DEFAULTS);

    React.useEffect(() => {
        const update = { ...DATA_DEFAULTS };
        if (createNew?.name) {
            update.name = createNew.name + " copy";
        }
        if (createNew?.notes) {
            update.notes = createNew.notes;
        }
        setData(update);
    }, [createNew]);

    const isDupe = !!myProjections?.find(p => p.name === data.name);

    const onSubmit = (event) => {
        event.preventDefault();
        doSubmit(session, data, createNew, setCreateNew, doRefresh);
    };

    return (<Modal animation={false} show={!!createNew} onHide={() => setCreateNew(false)}>
        <Modal.Header closeButton>
            <Modal.Title>Create New Projection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {createNew.name ? <p>Copy of {createNew.name}</p> : null}
            <CreateBody {...{ onSubmit, data, setData, isDupe }} />
        </Modal.Body>
        <Modal.Footer>
            <Button disabled={isDupe} variant="success" type="submit" form={FORM_ID}>Save</Button>
            <Button className="ms-2" variant="danger" type="button" onClick={() => setCreateNew(false)}>Cancel</Button>
        </Modal.Footer>
    </Modal>);
}

function CreateBody({ onSubmit, data, setData, isDupe }) {
    const onChangeName = e => setData(old => ({ ...old, name: e.target.value }));
    const onChangeNotes = e => setData(old => ({ ...old, notes: e.target.value }));

    return (<Form id={FORM_ID} onSubmit={onSubmit}>
        <FormGroup controlId="formName" className="mb-3">
            <Form.Label>Projection name</Form.Label>
            <Form.Control type="text" required value={data.name} onChange={onChangeName} />
            { isDupe ? <Form.Text className='text-danger'>
                You already have a profile named `{data.name}`!
            </Form.Text> : null }
        </FormGroup>
        <FormGroup controlId="formNotes" className="mb-3">
            <Form.Label>Notes</Form.Label>
            <Form.Control as="textarea" value={data.notes} rows="4" onChange={onChangeNotes} />
        </FormGroup>
    </Form>);
}

async function doSubmit(session, { name, notes }, createNew, setCreateNew, doRefresh) {
    const postData = { name, notes };
    // check for copy - either of user or projection
    // user projections have ids, while base projections only have names
    if (typeof createNew?.id === 'string') {
        postData.copyOf = createNew.id;
    } else if (typeof createNew?.name === 'string') {
        postData.source = createNew.name;
    }
    try {
        const r = await session.postJson("/user-edits/user-projections", postData);
        if (!r.ok) {
            throw new Error(`Invalid response ${r.status} ${r.statusText}`);
        }
        setCreateNew(false);
        doRefresh();
    } catch (error) {
        console.log("Error creating profile", error);
        window.alert("Unable to create new!");
    }
}
