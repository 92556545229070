
import React from 'react';

import DataTable from 'react-data-table-component';

import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";

import { ListFilter, WildcardFilter } from "./Filter";

// TODO: right approach?
const theme = "light"; // or dark...
const customStyles = {
    table: { style: { minHeight: "300px" }},
    // px-2
    headCells: { style: {
        paddingRight: "0.5rem",
        paddingLeft: "0.5rem",
    }},
    cells: { style: {
        paddingRight: "0.5rem",
        paddingLeft: "0.5rem",
    }},
};

/**
 * 
 * @param {object} props 
 * @param {object[]} props.data
 * @param {number?} props.totalRows specify to enable server-side pagination
 * @param {object} props.tableProps react-data-table-component props
 * @param {import('react-data-table-component').TableColumn[]} props.tableProps.columns
 * @param {function?} props.onPageStateChange optional function to listen to page state change
 * @returns 
 */
export default function PaginatedTable({ data, totalRows, tableProps, onPageStateChange }) {
    const [pageState, setPageState] = React.useState({ page: 1, perPage: 25 });

    React.useEffect(() => {
        if (pageState && typeof onPageStateChange === 'function') {
            onPageStateChange(pageState);
        }
    }, [pageState, onPageStateChange]);

    const tblProps = {
        // overwritable
        dense: true, 
        noDataComponent: "There are no records to display",
        theme: theme,
        ...tableProps,

        // not overwritable
        data,
        progressPending: false,
        persistTableHead: true,

        pagination: true,
        paginationServer: false, // may be overridden below
        paginationPerPage: pageState.perPage,
        paginationRowsPerPageOptions: [10, 25, 50, 100],
        // paginationComponentOptions: { className: "bg-dark" },
        onChangePage: (page, _totalRows) => setPageState(ps => ({ ...ps, page })),
        // XXX: not sure why, perPage is sometimes null...
        onChangeRowsPerPage: (perPage, page) => setPageState({ page, perPage }),

        sortServer: true,
    };
    if (typeof totalRows === 'number') {
        tblProps.paginationServer = true;
        tblProps.paginationTotalRows = totalRows;
    }

    return <DataTable {...tblProps} customStyles={customStyles} />;
}

// TODO: numeric filter? date filter?
/**
 * 
 * @param {object} params 
 * @param {string} params.column
 * @param {string?} params.sortColumn - optional alternate name to use for sort queries
 * @param {function} params.onFilter Make sure this is a react callback!
 * @returns 
 */
export function Header({ text, column, sortColumn, sortable, sort, onSort, idList, displayFn, wildcard, onFilter, maxFilterHeight }) {

    const onChange = React.useCallback(val => onFilter(column, val), [column, onFilter]);
    // if we have an id list, show filter buttons
    // else show a btn with 0 opacity to preserve format
    let right = null;
    if (idList) {
        right = <ListFilter idList={idList} maxHeight={maxFilterHeight} displayFn={displayFn} onChange={onChange} />;
    } else if (wildcard) {
        right = <WildcardFilter onChange={onChange} />;
    } else {
        right = <Button size="sm" className="px-0" disabled style={{ opacity: 0, flexGrow: 1 }}>|</Button>;
    }

    let left = null;
    if (sortable) {
        const sortColName = sortColumn || column;
        const direction = sort?.column === sortColName ? sort.direction : null;
        left = <Sortable text={text} direction={direction} onSort={direction => onSort(sortColName, direction)} />
    } else {
        left = text;
    }

    return (<div className="d-flex align-items-center justify-content-between w-100" style={{ whiteSpace: "nowrap" }}>
        {left} {right}
    </div>);
}

const ASC = "ASC";
const DESC = "DESC";

function Sortable({ text, direction, onSort }) {
    const [hover, setHover] = React.useState(false);

    const onClick = _e => {
        onSort(direction === ASC ? DESC : direction === DESC ? null : ASC);
    }

    const icon = direction === DESC ? faAngleDown : faAngleUp;
    const opacity = hover ? 0.5 : (direction ? 1 : 0);

    const textStyle = { cursor: 'pointer', overflow: 'hidden', textOverflow: 'ellipsis' };
    const iconStyle = { cursor: 'pointer', opacity, flexGrow: 0, flexShrink: 0 };
    const commonProps = {
        onClick, title: text + " - Click to sort",
        onMouseEnter: () => setHover(true), onMouseLeave: () => setHover(false)
    };
    return <>
        <div style={textStyle} {...commonProps}>{text}</div>
        <FontAwesomeIcon icon={icon} className="mx-1" style={iconStyle} {...commonProps} />
    </>;
}
