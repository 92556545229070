
import React from 'react';

import { useSessionContext } from "../session-context";

import { HashRouter, Routes, Route, Navigate, NavLink, useLocation } from 'react-router-dom';

// react-bootstrap should not leverage { } importing to save on the amount of code
// that has to be loaded
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import {
    ArchivedResults, Comparison, ClientReleases, Documents, Login, Results,
    SharedResults, Status, UserEdits, UserEditProfile,
    UserProjections, UserProjectionsEdit, UserPackages, Submission,
} from '.';
import ComparisonDetail from './Comparison/ComparisonDetail';
import AdminUsers from './AdminUsers';
import UserRoutes from './UserRoutes';
import UserRouteProfiles from './UserRouteProfiles';
import UserRouteProfileEdit from './UserRouteProfileEdit';
import AdminProcRoutes from './AdminProcRoutes';
import AdminDeletedScenarios from './AdminDeletedScenarios';

// some old page -> new page mappings
const REDIRECTS = {
    "/user-edits": "/user-data/edits",
    "/user-packages": "/user-data/packages",
    "/releases": "/downloads/releases",
    "/documents": "/downloads/documents"
}

const RESULT_DROP_OPTIONS = {
    title: "Results", id: "results-dropdown", prefix: "/results", links: [
        { to: "", name: "My Results" },
        { to: "/shared", name: "Shared With Me" },
        { to: "/comparison", name: "Comparison" },
        { to: "/archive", name: "Archive" },
    ]
};

const USER_DATA_DROP_OPTIONS = {
    title: "User Data", id: "data-dropdown", prefix: "/user-data", links: [
        { to: "/routes", name: "Routes" },
        { to: "/route-profiles", name: "Route Profiles" },
        { to: "/edits", name: "User Edits" },
        { to: "/packages", name: "User Packages" },
        { to: "/projections", name: "Fuel Projections" },
    ]
};
const DOWNLOAD_DROP_OPTIONS = {
    title: "Downloads", id: "downloads-dropdown", prefix: "/downloads", links: [
        { to: "/releases", name: "Client Releases" },
        { to: "/documents", name: "Documents" }
    ]
};
const ADMIN_DROP_OPTIONS = {
    title: "Admin", id: "admin-dropdown", prefix: "/admin", links: [
        { to: "/users", name: "Users" },
        { to: "/deleted", name: "Deleted Scenarios" },
        { to: "/process-routes", name: "Pre-process Routes" },
    ]
};

export default function Navigation({ setSession }) {
    const [modalApi, setModalApi] = React.useState('');

    // XXX: the navbar collapse causes a strict mode warning due to animations
    // https://github.com/react-bootstrap/react-bootstrap/issues/5075
    // https://github.com/react-bootstrap/react-bootstrap/issues/3518
    return (<HashRouter>
        <NavWrapper>
            <NavLinks />
            <Nav>
                <ProfileDropdown setSession={setSession} setApiKey={setModalApi} />
                <ModalApi value={modalApi} setValue={setModalApi} />
            </Nav>
        </NavWrapper>
        <Routes>
            <Route path='/login' element={<Login setSession={setSession} /> } />
            <Route path='/results' exact element={<RequireAuth><Results /></RequireAuth>} />
            <Route path='/results/shared' exact element={<RequireAuth><SharedResults /></RequireAuth>} />
            <Route path='/results/archive' exact element={<RequireAuth><ArchivedResults /></RequireAuth>} />
            <Route path='/results/comparison' exact element={<RequireAuth><Comparison /></RequireAuth>} />
            <Route path='/results/comparison/:id' exact element={<RequireAuth><ComparisonDetail /></RequireAuth>} />
            <Route path='/user-data/edits' element={<RequireAuth><UserEdits /></RequireAuth>} />
            <Route path='/user-data/edits/:profileId' element={<RequireAuth><UserEditProfile /></RequireAuth>} />
            <Route path='/user-data/packages' exact element={<RequireAuth><UserPackages /></RequireAuth>} />
            <Route path='/user-data/routes' exact element={<RequireAuth><UserRoutes /></RequireAuth>} />
            <Route path='/user-data/route-profiles' exact element={<RequireAuth><UserRouteProfiles /></RequireAuth>} />
            <Route path='/user-data/route-profiles/:profileId' element={<RequireAuth><UserRouteProfileEdit /></RequireAuth>} />
            <Route path='/user-data/projections' element={<RequireAuth><UserProjections /></RequireAuth>} />
            <Route path='/user-data/projections/:profile' element={<RequireAuth><UserProjectionsEdit /></RequireAuth>} />
            <Route path='/downloads/releases' exact element={<RequireAuth><ClientReleases /></RequireAuth>} />
            <Route path='/downloads/documents' exact element={<RequireAuth><Documents /></RequireAuth>} />
            <Route path='/submission' exact element={<RequireAuth><Submission /></RequireAuth>} />
            <Route path='/admin/users' exact element={<RequireAuth admin={true}><AdminUsers /></RequireAuth>} />
            <Route path='/admin/deleted' exact element={<RequireAuth admin={true}><AdminDeletedScenarios /></RequireAuth>} />
            <Route path='/admin/process-routes' exact element={<RequireAuth admin={true}><AdminProcRoutes /></RequireAuth>} />

            { Object.entries(REDIRECTS).map(([from, to]) =>
                <Route key={from} path={from} exact element={<Navigate to={to} />}/>) }
            <Route path='/*' element={<Navigate to='/' />} />

            <Route path='/' element={<RequireAuth><Status /></RequireAuth>} />
        </Routes>
    </HashRouter>);
}

function NavWrapper({ children }) {
    return <Navbar bg="grey" expand="lg" variant="light">
        <Container fluid>
            <Navbar.Brand className="fw-bold">NGSAM</Navbar.Brand>
            <Navbar.Toggle aria-controls="main-navbar" />
            <Navbar.Collapse id="main-navbar">
                {children}
            </Navbar.Collapse>
        </Container>
    </Navbar>;
}

function NavLinks() {
    const session = useSessionContext();
    
    const classFn = ({ isActive }) => "nav-link" + (isActive ? " active" : "");
    const userIsAdmin = session?.user?.admin === true;

    if (!session) {
        return <Nav className="me-auto" />;
    }
    
    return <Nav className="me-auto">
        <NavLink end to="/" className={classFn}>Server Status</NavLink>
        <NavLink end to="/submission" className={classFn}>Submit Scenario</NavLink>
        <CustomNavDropdown { ...RESULT_DROP_OPTIONS } />
        <CustomNavDropdown { ...USER_DATA_DROP_OPTIONS } />
        <CustomNavDropdown { ...DOWNLOAD_DROP_OPTIONS } />
        { userIsAdmin ? <CustomNavDropdown { ...ADMIN_DROP_OPTIONS } /> : null }
    </Nav>;
}

// for routing, if the user is not set, redirect to login. Else, check for admin routes.
const RequireAuth = ({ children, admin }) => {
    const session = useSessionContext();
    const location = useLocation();
    if (session) {
        if (!admin || session?.user?.admin === true) {
            return children;
        } // else, we're signed in, but not an admin
        return <Navigate to="/" />;
    }
    return <Navigate to='/login' state={{from: location}} />;
};

// XXX: the NavDropdown doesn't properly close, so we have to create a function to close it on navigation
// this seems to be an issue of combining react-bootstrap and react-router:
// the react router prevents default behavior on nav, which unfortunately catches the close toggle
function CustomNavDropdown({ id, title, links, prefix }) {
    const location = useLocation();
    const closeFn = () => document.getElementById(id).click();
    const classFn = ({ isActive }) => "dropdown-item" + (isActive ? " active" : "");

    return (<NavDropdown id={id} title={title} active={location.pathname.startsWith(prefix)}>
        {links.map(link =>
            <NavLink end key={prefix + link.to} to={prefix + link.to} className={classFn} onClick={closeFn}>
                {link.name}
            </NavLink>
        )}
    </NavDropdown>);
}

function ProfileDropdown({ setSession, setApiKey }) {
    const session = useSessionContext();
    if (!session) {
        return null;
    }
    
    return <Dropdown as={Nav.Item}>
        <Dropdown.Toggle as={Nav.Link}>
            <FontAwesomeIcon icon={faUser} className="me-1" />
            {session.user.name}
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
            <Dropdown.Item onClick={() => setApiKey(session.sessionString)}>Show API Key</Dropdown.Item>
            <Dropdown.Item onClick={() => setSession(null)}>Logout</Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>;
}

function ModalApi({ value, setValue }) {
    return (<Modal centered animation={false} show={!!value} onHide={() => setValue('')}>
        <Modal.Header closeButton>
            <Modal.Title>Session API Key</Modal.Title>
        </Modal.Header>
        <Modal.Body><p style={{ wordBreak: 'break-all' }}>{value}</p></Modal.Body>
    </Modal>);
}
