
import React from 'react';

import LoadingSpinner from '../LoadingSpinner';

import PaginatedTable from './PaginatedTable';

import { useSessionContext } from '../../session-context';

/**
 * 
 * @param {object} props 
 * @param {string} props.dataUrl
 * @param {string?} props.filter optional filter for data fetch
 * @param {{ column: string, direction: string }?} props.sort optional sort for data fetch
 * @param {boolean?} props.refresh trigger for reloading data
 * @param {object} props.tableProps react-data-table-component props
 * @param {import('react-data-table-component').TableColumn[]} props.tableProps.columns
 * @param {function?} props.onTotalRowsChange optional function to listen to total rows prop
 * @returns 
 */
export default function PaginatedServerTable({ dataUrl, filter, sort, refresh, tableProps, onTotalRowsChange }) {
    const session = useSessionContext();
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [totalRows, setTotalRows] = React.useState(0);
    const [pageState, setPageState] = React.useState({ page: 1, perPage: 25 });

    React.useEffect(() => {
        setLoading(true);
        fetchData(session, dataUrl, filter, pageState, sort).then(({ data, total }) => {
            setData(data);
            setTotalRows(total);
        }).catch(error => {
            console.log("Error fetching table data", error);
            // window.alert("Error fetching data!");
        }).finally(() => setLoading(false));
    }, [session, dataUrl, filter, pageState, sort, refresh]);

    React.useEffect(() => {
        if (typeof onTotalRowsChange === 'function') {
            onTotalRowsChange(totalRows);
        }
    }, [totalRows, onTotalRowsChange]);

    return <>
        { loading ? <LoadingSpinner /> : null }
        <PaginatedTable data={data} totalRows={totalRows} tableProps={tableProps} onPageStateChange={setPageState} />
    </>;
}

/**
 * @param {import('../../session-context').Session} session 
 * @param {string} baseUrl 
 * @param {string?} filter 
 * @param {{ page: number, perPage: number }} pagination 
 * @param {{ column: string, direction: string}?} sort 
 * @returns 
 */
async function fetchData(session, baseUrl, filter, { page, perPage }, sort) {
    const offset = (page - 1) * perPage;
    let url = baseUrl  + `?offset=${offset}&limit=${perPage}`;
    if (sort) {
        url += `&sort=${sort.column}&direction=${sort.direction}`;
        if (sort.order) {
            url += `&order=${JSON.stringify(sort.order)}`;
        }
    }
    if (filter) {
        // console.log("filter", filter);
        url += `&filter=${filter}`;
    }
    return session.getJson(url);
}
