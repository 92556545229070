
import React from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';

export default function ForgotModal({show, setShow, type, arg, message, dest}) {
    const [value, setValue] = React.useState('');

    const submit = e => {
        e.preventDefault();
        if (!value) {
            return;
        }
        fetch(dest, {
            method: "POST",
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify({[arg]: value})
        }).then(response => {
            setValue("");
            setShow(false);
            if (!response.ok) {
                console.log("Invalid response", response);
                window.alert("Unable to submit request. Please contact an administrator.");
                return;
            }
            window.alert("Request submitted. You will receive an email shortly.");
        });
    };

    return (<Modal centered animation={false} show={show} onHide={() => setShow(false)}>
        <Modal.Body>
            <p>{message}</p>
            <Form onSubmit={submit}><InputGroup>
                <Form.Control type={type} placeholder={arg} value={value}
                    required onChange={e => setValue(e.target.value)} />
                <Button variant="primary" type="submit">Submit</Button>
            </InputGroup></Form>
        </Modal.Body>
    </Modal>);
}