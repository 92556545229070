
export default function downloadFile(fileName, uriObject) {
    var a = document.createElement("a");
    // if `a` element has `download` property
    if ("download" in a) {
        a.href = uriObject;
        a.download = fileName;
        document.body.appendChild(a);
        console.log("downloading", fileName);
        a.click();
        document.body.removeChild(a);
    } else { // use `window.open()` if `download` not defined at `a` element
        window.open(uriObject);
    }
}
