
import React from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { ArrayTable } from '../../util/Table';

// two views - assembly or shutdown date
export const VIEW_ASSEMBLY = 'assembly';
export const VIEW_SHUTDOWN = 'reactor-shutdown';

export const ASSEMBLY_HEADERS = [
    { name: "ID", _parsing: { isInt: true } },
    { name: "Quantity", _parsing: { isInt: true } },
    { name: "Reactor", _parsing: { isInt: true } },
    { name: "DischargeDate", minWidth: "150px", _parsing: { isDate: true } },
    { name: "AssemblyTypeID", minWidth: "175px", _parsing: { isInt: true } },
    { name: "MTHM", _parsing: { isFloat: true } },
    { name: "Enrichment", minWidth: "125px", _parsing: { isFloat: true } },
    { name: "Burnup", _parsing: { isFloat: true } },
    { name: "Pool", _parsing: { isInt: true } },
    { name: "AssemblyType", minWidth: "150px", _parsing: { options: [ "PWR", "BWR" ]} },
];
const SHUTDOWN_HEADERS = [ "Reactor", "LastDischargeDate" ];

export default function DataTable({ data, view, tableOpts }) {

    const tableProps = {
        ...(tableOpts || {}),
        pagination: true, paginationPerPage: 50, paginationRowsPerPageOptions: [25, 50, 100, 200],
        fixedHeader: true,
    };
    const headers = view === VIEW_SHUTDOWN ? SHUTDOWN_HEADERS : ASSEMBLY_HEADERS;

    return <Row><Col>
        <ArrayTable header={headers} content={data} sortable tableProps={tableProps} />
    </Col></Row>;
}
