
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import LoadingSpinner from '../LoadingSpinner';
import { useSessionContext } from '../../session-context';

import { DownloadDropdownItem } from '../../util/DownloadLink';

import DataTable, { VIEW_ASSEMBLY, VIEW_SHUTDOWN } from './DataTable';
import ImportModal from './ImportModal';

// user- or base-
const USER_PREFIX = "user-";
const NAV_TARGET = "/user-data/projections";

export default function UserProjectionsEdit() {
    const session = useSessionContext();
    const { profile: profileTag } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [profile, setProfile] = React.useState(null);
    const [view, setView] = React.useState(VIEW_ASSEMBLY);
    const [data, setData] = React.useState([]);
    const [upload, setUpload] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);

    React.useEffect(() => {
        const cancelFlag = { cancelled: false };
        setUpload(false);
        setData([]);
        loadProfile(session, profileTag, view, cancelFlag, setLoading, setProfile, setData, navigate);
        return () => { cancelFlag.cancelled = true };
    }, [session, profileTag, view, refresh, navigate]);

    const title = buildTitle(profile);

    const editable = view === VIEW_ASSEMBLY && profile?.username === session.user.name;

    const doRefresh = () => setRefresh(v => !v);

    return <Container fluid>
        { loading ? <LoadingSpinner /> : null }
        <Row>
            <Col><h3 className='text-center'>{title}</h3></Col>
        </Row>
        <Row className='justify-content-center'>
            <Col xs="3" lg="2" className="mb-2 d-flex align-items-center justify-content-end fw-bold">View:</Col>
            <Col xs="6" lg="3" className="mb-2"><ViewDropdown view={view} setView={setView} /></Col>
            <Col xs="3" lg="2"><ActionDropdown {...{ profile, view, editable, setUpload }} /></Col>
        </Row>
        { profile ? <DataTable profile={profile} data={data} view={view} /> : null }
        { upload ? <ImportModal profileId={profile?.id} data={data} onClose={() => setUpload(false)} onSave={doRefresh} /> : null }
    </Container>;
}

function ActionDropdown({ profile, view, editable, setUpload, }) {
    if (!profile) {
        return null;
    }
    let hrefBase;
    if (profile.id) {
        hrefBase = "/user-edits/user-projections/" + profile.id + "/" + view;
    } else {
        hrefBase = "/user-edits/fuel-projections/" + profile.name + "/" + view;
    }
    const fileBase = profile.name+ "-" + view;

    return <Dropdown>
        <Dropdown.Toggle size="sm">
            Actions
        </Dropdown.Toggle>
        <Dropdown.Menu popperConfig={{ strategy: "fixed" }} renderOnMount={true}>
            <DownloadDropdownItem href={hrefBase + "?format=json"} download={fileBase + ".json"}>
                Download JSON
            </DownloadDropdownItem>
            <DownloadDropdownItem href={hrefBase + "?format=csv"} download={fileBase + ".csv"}>
                Download CSV
            </DownloadDropdownItem>
            { editable ? (<>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => setUpload(true)}>Upload edits</Dropdown.Item> 
            </>): null}
        </Dropdown.Menu>
    </Dropdown>
}

function ViewDropdown({ view, setView }) {
    return <Form.Select size="sm" value={view} onChange={e => setView(e.target.value)}>
        <option value={VIEW_ASSEMBLY}>Assembly Data</option>
        <option value={VIEW_SHUTDOWN}>Reactor Shutdown Data</option>
    </Form.Select>;
}

function buildTitle(profile) {
    if (!profile) {
        return "Fuel Projection";
    } else if (profile.id) {
        return "User Fuel Projection: " + profile.name;
    } else {
        return "Base Fuel Projection: " + profile.name;
    }
}

async function loadProfile(session, profileTag, view, cancelFlag, setLoading, setProfile, setData, navigate) {
    try {
        setLoading(true);
        const isBase = !profileTag.startsWith(USER_PREFIX);
        const profileId = profileTag.slice(5); // if base, this is the name
        let profile = null;
        let data = null;
        if (isBase) {
            // only need to load data for base
            const result = await session.getJson('/user-edits/fuel-projections/' + profileId + "/" + view);
            if (!Array.isArray(result?.data)) {
                throw new Error("Invalid base data name: " + profileId);
            }
            profile = { name: profileId };
            data = result.data;
        } else {
            const [ profileResult, dataResult ] = await Promise.all([
                session.getJson('/user-edits/user-projections/' + profileId),
                session.getJson('/user-edits/user-projections/' + profileId + "/" + view)
            ]);
            if (!profileResult?.data || !Array.isArray(dataResult?.data)) {
                throw new Error("Couldn't load profile: " + profileId);
            }
            profile = profileResult.data;
            data = dataResult.data;
        }
        if (!cancelFlag.cancelled) {
            setProfile(profile);
            setData(data);
        }
    } catch (error) {
        console.log(error);
        if (!cancelFlag.cancelled) {
            window.alert("Unable to load profile!");
            navigate(NAV_TARGET);
        }
    } finally {
        if (!cancelFlag.cancelled) {
            setLoading(false);
        }
    }
}
