
import React from 'react';

import Modal from 'react-bootstrap/Modal';

export default function MessageModal({ modalMessage, setModalMessage }) {
    
    return (<Modal scrollable={true} animation={false} show={!!modalMessage.title} onHide={() => setModalMessage({})}>
        <Modal.Header closeButton>
            <Modal.Title>{modalMessage.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage.body}</Modal.Body>
    </Modal>);
}
