
import React from 'react';

import Container from 'react-bootstrap/Container';

import { faSquarePlus } from '@fortawesome/free-solid-svg-icons';

import ActionIcon from '../../util/ActionIcon';
import { useSessionContext } from '../../session-context';

import LoadingSpinner from '../LoadingSpinner';

import ProfileTable from './ProfileTable';
import AddModal from './AddModal';
import ShareModal from './ShareModal';
import permissions from './permissions';
import LockModal from './LockModal';

export default function UserRouteProfiles() {
    const session = useSessionContext();
    const [profiles, setProfiles] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [add, setAdd] = React.useState(false); // true or profile to clone
    const [toShare, setToShare] = React.useState(null);
    const [toLock, setToLock] = React.useState(null);
    const [refresh, setRefresh] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        loadData(session).then(setProfiles).catch(error => {
            console.log(error);
        }).finally(() => setLoading(false));
    }, [session, refresh]);

    const actions = React.useMemo(() => ({
        onClone: setAdd,
        onLock: setToLock,
        onShare: setToShare
    }), []);

    const onSave = () => {
        setRefresh(v => !v);
        setAdd(false);
    };

    return <Container fluid="lg" className='pb-4'>
        <div className="mb-2 d-flex align-items-center">
            <span className='h3 mb-0 me-2'>Route Profiles</span>
            <ActionIcon icon={faSquarePlus} size="lg" title="Add Profile" action={() => setAdd(true)}
                className="text-success p-0 shadow-none" />
        </div>
        <div className="mb-2">
            { loading ? <LoadingSpinner /> : null }
            <ProfileTable profiles={profiles} actions={actions} />
        </div>
        <AddModal active={add} onClose={() => setAdd(false)} onSave={onSave} />
        <ShareModal profile={toShare} setProfile={setToShare} />
        <LockModal profile={toLock} setProfile={setToLock} onSave={onSave} />
    </Container>;
}

async function loadData(session) {
    const profileList = await session.getJson('/api/v1/routes/profiles');
    return profileList.map(profile => {
        const isCreator = permissions.isCreator(session, profile);
        // XXX: this is more "can edit"
        const isAuthor = isCreator || permissions.isAuthor(session, profile);
        return { ...profile, isCreator, isAuthor };
    });
}
