
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import Button from 'react-bootstrap/Button';

import Table from '../../util/Table';
import moment from 'moment';

import { DATE_FMT } from '../../util/scenarioFunctions';

// base columns
const BASE_COLUMNS = [
    { name: "Status", grow: 0.5, sortable: true, selector: row => row.state },
    { name: "Date", sortable: true, selector: row => row.run_date, format: row => moment(row.run_date).format(DATE_FMT) },
    { name: "Base Scenario", grow: 1.5, sortable: true, selector: row => row.baseScenario, wrap: true },
    { name: "Base Run", sortable: true, selector: row => row.baseRun, format: row => row.baseRun.format(DATE_FMT) },
    { name: "Base User", sortable: true, selector: row => row.baseUser },
    { name: "Target Scenario", grow: 1.5, sortable: true, selector: row => row.targetScenario, wrap: true },
    { name: "Target Run", sortable: true, selector: row => row.targetRun, format: row => row.targetRun.format(DATE_FMT) },
    { name: "Target User", sortable: true, selector: row => row.targetUser },
    { name: "View", grow: 0.5, selector: row => row.id, format: row => <LaunchButton target={row} /> },
];

export default ComparisonTable;

function ComparisonTable({ comparisons, loading, deleteComparison }) {

    // add delete button
    const columns = [ ...BASE_COLUMNS, 
        { name: "Delete", grow: 0.5, selector: row => row.id,
            format: row => <DeleteButton onClick={() => deleteComparison(row.id)} /> }
    ];

    return <Table columns={columns} data={comparisons} pagination progressPending={loading} />
}

function LaunchButton({ target }) {
    if (target.state === "FAILED") {
        return null; // nothing to view
    }
    return  (<Link to={"./" + target.id} className="p-0 ms-2 btn btn-link btn-sm shadow-none" title="View comparison">
        <FontAwesomeIcon icon={faEye} size="lg"></FontAwesomeIcon>
    </Link>);
}

function DeleteButton({ onClick }) {
    return  <Button size="sm" variant="link" onClick={onClick} className="p-0 ms-2 shadow-none" title="Delete comparison">
        <FontAwesomeIcon icon={faTrash} size="lg"></FontAwesomeIcon>
    </Button>;
}
