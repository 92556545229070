
import React from 'react';

import { useSessionContext } from '../../session-context';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { COL_ID } from './ProfileTable';

export default function LockModal({ profile, setProfile, onSave }) {
    const session = useSessionContext();

    const onSubmit = () => {
        doSubmit(session, profile[COL_ID]).then(() => {
            setProfile(null);
            onSave();
        }).catch(error => {
            console.log(error);
            window.alert("Unable to lock profile!");
        });
    };
    
    return (<Modal size="lg" animation={false} show={!!profile} onHide={() => setProfile(null)}>
        <Modal.Header closeButton>
            <Modal.Title>Lock Profile {profile?.[COL_ID]}: {profile?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>
                Are you sure you want to lock Profile {profile?.[COL_ID]}: {profile?.name}?
                Once locked, the routes in the profile cannot be changed, and the profile cannot be unlocked.
                The profile can still be shared and cloned.
            </p>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={onSubmit}>Lock</Button>
            <Button className="ms-2" variant="secondary" onClick={() => setProfile(null)}>Cancel</Button>
        </Modal.Footer>
    </Modal>);
}

async function doSubmit(session, id) {
    const res = await session.postJson('/api/v1/routes/profiles/' + id, { locked: true });
    if (res.status > 299) {
        throw new Error(`Invalid response: ${res.status} ${res.statusText}`);
    }
}
