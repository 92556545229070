
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";

import AddRecordForm from "./addRecordForm";
import { Action } from './dataReducer';
import { DELETED_ROW_CLASS } from './headerCell';
import CsvImportForm from './csvImportForm';
import exportEditsToCsv from './exportEditsToCsv';

const pageSizes = [10, 25, 50, 100];
export const fontSizes = [
    { name: "xs", value: "0.65rem" },
    { name: "sm", value: "0.85rem" },
    { name: "md", value: "1rem" },
    { name: "lg", value: "1.15rem" },
    { name: "xl", value: "1.25rem" },
];

export default function ActionPanel({ editable, data, dataDispatch, columns, table, saveAction, setModalMessage, fontSize, setFontSize }) {
    const selectedItems = data.display.filter(rowIdx => data.rows[rowIdx]._selected);
    const numSelected = selectedItems.length;

    const setPageSize = size => dataDispatch({type: Action.PAGE_SIZE, payload: size});
    const deleteSelected = () => dataDispatch({type: Action.DELETE, payload: selectedItems});
    const setShowDeleted = val => dataDispatch({type: Action.SHOW_DELETED, payload: val});
    const setSelected = selectionUpdate => dataDispatch({type: Action.SELECT, payload: selectionUpdate});
    const clearEdits = () => dataDispatch({type: Action.CLEAR_EDITS_BATCH, payload: selectedItems});
    const clearAll = () => {
        if (window.confirm("Are you sure you want to clear all edits on this table?")) {
            dataDispatch({type: Action.CLEAR_EDITS_BATCH, payload: Object.keys(data.rows)});
        }
    };

    const addRecord = () => setModalMessage({
        title: "Add record",
        body: (<AddRecordForm data={data} dataDispatch={dataDispatch} table={table}
            columns={columns} setModalMessage={setModalMessage} />)
    });
    const importCsv = () => setModalMessage({
        title: "Import CSV",
        body: (<CsvImportForm data={data} dataDispatch={dataDispatch} table={table}
            columns={columns} setModalMessage={setModalMessage} />)
    });

    const exportEdits = () => exportEditsToCsv(table.name + "-edits.csv", columns, data.rows, true);
    const exportAll = () => exportEditsToCsv(table.name + "-full.csv", columns, data.rows);

    return (<>
        <Dropdown className="d-inline-block me-2">
            <Dropdown.Toggle size="sm" variant="secondary">Actions</Dropdown.Toggle>
            <Dropdown.Menu>
                { !editable ? null : (<>
                    <Dropdown.Item onClick={addRecord}>Add record</Dropdown.Item>
                    <Dropdown.Divider />
                    <SelectionItem data={data} numSelected={numSelected} setSelected={setSelected} />
                    <DeleteSelectedItems numSelected={numSelected} deleteSelected={deleteSelected} />
                    <ClearEditsItem numSelected={numSelected} clearEdits={clearEdits} />
                    <Dropdown.Item onClick={clearAll}>Clear all edits</Dropdown.Item>
                </>)}
                <ShowDeletedItem showDeleted={data.showDeleted} setShowDeleted={setShowDeleted}/>
                <Dropdown.Divider />
                <Dropdown.Item onClick={exportEdits}>Export edited rows to CSV</Dropdown.Item>
                <Dropdown.Item onClick={exportAll}>Export all rows to CSV</Dropdown.Item>
                { !editable ? null : <Dropdown.Item onClick={importCsv}>Import edits from CSV</Dropdown.Item>}
            </Dropdown.Menu>
        </Dropdown>
        <Dropdown className="d-inline-block me-2">
            <Dropdown.Toggle size="sm" variant="secondary">Page size: {data.pageSize} </Dropdown.Toggle>
            <Dropdown.Menu>
                { pageSizes.map(size =>
                    <PageSizeItem key={size} size={size} selected={size === data.pageSize} changeFunction={setPageSize} />)}
            </Dropdown.Menu>
        </Dropdown>
        <Dropdown className="d-inline-block me-2">
            <Dropdown.Toggle size="sm" variant="secondary">Font size: {fontSize.name} </Dropdown.Toggle>
            <Dropdown.Menu>
                { fontSizes.map(size =>
                    <PageSizeItem key={size.name} size={size.name} selected={size.name === fontSize.name}
                        changeFunction={name => setFontSize(fontSizes.find(sz => sz.name === name))} />)}
            </Dropdown.Menu>
        </Dropdown>
        { !editable ? null : <Button size="sm" title="Save your changes" disabled={!data.hasChanges} variant="success"
                onClick={saveAction}>Save</Button> }
    </>);
}

function DeleteSelectedItems({ numSelected, deleteSelected }) {
    return (<Dropdown.Item className={numSelected > 0 ? "" : "disabled"} onClick={deleteSelected}>
        Delete selected items
    </Dropdown.Item>);
}

function ShowDeletedItem({ showDeleted, setShowDeleted }) {
    // generate label with custom text
    const val = showDeleted ? "Hide" : "Show";
    const label = <>{val} <span className={DELETED_ROW_CLASS}>deleted</span> rows</>;
    return <Dropdown.Item onClick={e => setShowDeleted(!showDeleted)}>{label}</Dropdown.Item>;
}

function SelectionItem({ data, numSelected, setSelected }) {
    const numAvailable = data.display.length;
    const deselect = () => setSelected({ selected: false, indices: data.display });
    const select = () => setSelected({ selected: true, indices: data.display });
    return (<>
        { numSelected < 1 ? null : <Dropdown.Item onClick={deselect}>De-select all</Dropdown.Item> }
        { numSelected >= numAvailable ? null : <Dropdown.Item onClick={select}>Select all</Dropdown.Item> }
    </>);
}

function ClearEditsItem({numSelected, clearEdits}) {
    return (<Dropdown.Item onClick={clearEdits} className={numSelected > 0 ? "" : "disabled"}>
        Clear edits on selected rows
    </Dropdown.Item>);
}

function PageSizeItem({ size, selected, changeFunction }) {
    const onClick = selected ? () => {} : () => changeFunction(size);
    return <Dropdown.Item onClick={onClick}>{size} {selected ? "\u2713" : ""}</Dropdown.Item>;
}
