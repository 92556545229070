
import React from 'react';

import { useSessionContext } from '../../session-context';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import ActionIcon from '../../util/ActionIcon';
import Table from '../../util/Table';
import ThermalCell from './thermalCell';

const BASE_COLUMNS= [
    { name: "Name", selector: pkg => pkg.model_id, sortable: true },
    { name: "Type", grow: 0.25, selector: pkg => pkg.reactor_type, sortable: true },
    { name: "Storage Orientation", selector: pkg => pkg.orientation, sortable: true }
];

export default function UserPackages() {
    const session = useSessionContext();
    const [packages, setPackages] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [modalInfo, setModalInfo] = React.useState(null);

    React.useEffect(() => {
        setPackages([]);
        setLoading(true);
        session.getJson('/database/user/packages').then(data => {
            setPackages(data.packages || []);
            setLoading(false);
        }).catch(error => {
            console.log("Error loading packages!", error);
            setPackages([]);
            setLoading(false);
        });
    }, [session]);

    // build the table columns
    const columns = [
        { name: "Info", grow: 0.25, selector: pkg => <InfoCell pkg={pkg} setModalInfo={setModalInfo}/> },
        ...BASE_COLUMNS,
        {name: "Thermal Maps", selector: pkg => <ThermalCell pkg={pkg} setModalInfo={setModalInfo} />}
    ];

    return (<>
        <div className="px-3">
            <Container className="text-center mb-3">
                <Row><Col><h3>User-defined Packages</h3></Col></Row>
                <Row><Col><Table columns={columns} data={packages} progressPending={loading} /></Col></Row>
            </Container>
        </div>
        <InfoModal data={modalInfo} setData={setModalInfo}/>
    </>);
}

function InfoCell({pkg, setModalInfo}) {
    const title = pkg.model_id ? "Package Info" : "Overpack Info";
    const body = (<>
        <p className="mb-0"><strong>Name:</strong> {pkg.model_id || pkg.name}</p>
        <p className="mb-0"><strong>Created:</strong> {moment(pkg.create_date).format("YYYY-MM-DD")}</p>
        <p className="mb-0"><strong>User:</strong> {pkg.user_id}</p>
        <p className="mb-0">{pkg.description}</p>
    </>);
    return <ActionIcon icon={faInfoCircle} title="Show user info" action={() => setModalInfo({title, body})} />;
}

function InfoModal({data, setData}) {
    const {title, body} = (data || {});

    return (<Modal animation={false} show={!!data} onHide={() => setData(null)}>
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
    </Modal>);
}