
import React from 'react';

import { useSessionContext } from '../session-context';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

import DownloadLink from '../util/DownloadLink';
import Table from '../util/Table';

export default function ClientReleases() {
    const session = useSessionContext();
    const [loading, setLoading] = React.useState(true);
    const [builds, setBuilds] = React.useState([]);
    const [nightly, setNightly] = React.useState(null);

    React.useEffect(() => {
        setLoading(true);
        session.getJson('/downloads/build').then(({builds, nightly}) => {
            setBuilds(builds || []);
            setNightly(nightly);
        }).catch(error => {
            console.log("Error loading builds!", error);
            setBuilds([]);
            setNightly(null);
        }).finally(() => setLoading(false));
    }, [session]);

    return (<Container className="text-center">
        <Row><Col><h3>Client Releases</h3></Col></Row>
        { loading ? <Spinner animation="border" role="status" /> : <ReleaseList builds={builds} nightly={nightly} /> }
    </Container>);
}

function ReleaseList({ builds, nightly }) {
    if (!builds || builds.length < 1) {
        return <p>No builds found</p>;
    }

    const columns = [
        { name: "Type", selector: row => row.type },
        { name: "Download", grow: 2, cell: row => <DownloadCell data={row.data} directory={row.directory} /> },
    ];
    const data = [
        { type: 'Latest Release', data: builds[0], directory: "stable" },
        { type: 'Nightly Build (Experimental)', data: nightly, directory: "archive" },
        { type: 'Archived Releases', data: builds.slice(1), directory: "stable" },
    ];

    return (<Row><Col>
        <Table columns={columns} data={data} />
    </Col></Row>);
}

function DownloadCell({ data, directory }) {
    if (typeof data === 'string') {
        return <Download file={data} directory={directory} />
    }
    return <DownloadSelector data={data} directory={directory} />;
}

function DownloadSelector({ data, directory }) {
    const [selected, setSelected] = React.useState('');

    const linkClass = selected ? '' : 'disabled';
    return <div className='my-1 d-flex align-items-center'>
        <Form.Select size='sm' className='me-2' value={selected} onChange={e => setSelected(e.target.value)}>
            <option value=''>Select</option>
            {data.map(file => <option key={file} value={file}>{file}</option>)}
        </Form.Select>
        <DownloadLink href={'/downloads/build/' + directory + '/' + selected} className={linkClass}
            title={"Download " + selected} download={selected}>
                <FontAwesomeIcon icon={faDownload} size='lg' />
        </DownloadLink>
    </div>;
}

function Download({ file, directory }) {
    if (!file) {
        return null;
    }

    return (<div className="py-1">
        <DownloadLink href={'/downloads/build/' + directory + '/' + file} title={"Download " + file} download={file}>
            {file}
        </DownloadLink>
    </div>);
}
