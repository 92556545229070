
import { faFilePen } from '@fortawesome/free-solid-svg-icons';
import ActionIcon from '../../util/ActionIcon';
import Table from '../../util/Table';

// base columns (no session needed)
const BASE_COLUMNS = [
    { name: "Username", sortable: true, selector: row => row.name, wrap: true },
    { name: "Email", sortable: true, selector: row => row.email, wrap: true },
    { name: "Active?", sortable: true, selector: row => row.active ? "Yes" : "No", wrap: true },
    { name: "Admin?", sortable: true, selector: row => row.admin ? "Yes" : "No", wrap: true },
    { name: "Govt User?", sortable: true, selector: row => row.gov ? "Yes" : "No", wrap: true },
];

export default function UserTable({ users, setEditUser }) {

    const columns = BASE_COLUMNS.slice();
    columns.push({ name: "Edit", ignoreRowClick: true, grow: 0.5, selector: row => row.id, center: true,
        format: row => <ActionIcon icon={faFilePen} action={() => setEditUser(row.id)} /> });

    return <Table columns={columns} data={users} pagination />
}
