
import React from 'react';

import { useSessionContext } from '../../session-context';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';

import LoadingSpinner from '../LoadingSpinner';
import useSites from '../../util/useSites';

import MODES from './modes';

const MODE_ARRAY = Object.values(MODES);

export default function AddModal({ open, onClose }) {
    const [loading, setLoading] = React.useState(false);
    
    return <Modal size='xl' animation={false} show={open} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title>Add new route</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <AddBody {...{ open, onClose, loading, setLoading }} />
        </Modal.Body>
        <Modal.Footer>
            <Button type="submit" form="user-route-add-form" variant="primary" disabled={loading}>Submit</Button>
            <Button className="ms-2" variant="danger" type="button" onClick={onClose} disabled={loading}>Cancel</Button>
        </Modal.Footer>
    </Modal>
}

const UPLOAD_TYPE_KML_CSVS = "kml-csvs";
const UPLOAD_TYPE_START_JSON = "start-json";

const MIME_TYPE_KML = ".kml,application/vnd.google-earth.kml+xml";
const MIME_TYPE_CSV = "text/csv";
const MIME_TYPE_JSON = "application/json";

function AddBody({ open, onClose, loading, setLoading }) {
    const session = useSessionContext();
    const [ sites ] = useSites(open);
    const [fileType, setFileType] = React.useState(UPLOAD_TYPE_KML_CSVS);
    const [input, setInput] = React.useState({ fromSite: '', toSite: '' });
    const [fileState, setFileState] = React.useState({ startFile: null, kmlFile: null, csvFiles: null });
    const [warning, setWarning] = React.useState(null);

    const onSubmit = e => {
        e.preventDefault();
        setWarning(null);
        // console.log(input, fileState);
        // TODO: further validation
        if (input.fromSite === input.toSite) {
            setWarning("You must select different sites for the endpoints!");
            return;
        }
        const data = new FormData();
        data.append('from', input.fromSite);
        data.append('to', input.toSite);
        if (fileState.startFile) {
            // TODO:
        } else {
            if (!fileState.kmlFile) {
                setWarning("Please choose a KML file!");
                return;
            }
            data.append("kmlFile", fileState.kmlFile);
            let numRailRoutes = 0;
            fileState.csvFiles.forEach((record, index) => {
                if (record.mode === MODES.RAIL.id) {
                    ++numRailRoutes;
                }
                data.append('csvFile' + index, record.file);
                data.append('csvMode' + index, record.mode);
            });
            if (numRailRoutes !== 1) {
                setWarning("There must be a single rail route!");
                return;
            }
        }

        setLoading(true);
        session.postFormData('/api/v1/routes', data).then(() => {
            onClose();
        }).catch(error => {
            setWarning("Error submitting route!");
            console.log(error);
        }).finally(() => setLoading(false));
    };

    // file upload is either a json file from start
    // or 1 kml file, and 1 or more csv files

    return <>
        { loading ? <LoadingSpinner /> : null }
        <Form id="user-route-add-form" onSubmit={onSubmit}>
            { warning ? <p className='text-danger'>{warning}</p> : null }
            <Form.Group className='mb-3'>
                <Form.Label>From Site</Form.Label>
                <SiteSelect sites={sites} input={input} setInput={setInput} attr="fromSite" />
            </Form.Group>
            <Form.Group className='mb-3'>
                <Form.Label>To Site</Form.Label>
                <SiteSelect sites={sites} input={input} setInput={setInput} attr="toSite" />
            </Form.Group>
            <Form.Group className='mb-3'>
                <Form.Label>Upload type</Form.Label>
                <Form.Select value={fileType} onChange={e => setFileType(e.target.value)}>
                    <option value={UPLOAD_TYPE_KML_CSVS}>KML and CSV files</option>
                    {/* <option value={UPLOAD_TYPE_START_JSON}>JSON file from START</option> */}
                </Form.Select>
            </Form.Group>
            {fileType === UPLOAD_TYPE_KML_CSVS ?
                <KmlCsvInput {...{fileState, setFileState}}  /> : <JsonInput {...{fileState, setFileState}} />}
        </Form>
    </>;
}

function KmlCsvInput({ setFileState }) {
    const [modes, setModes] = React.useState([{ mode: MODES.RAIL.id, file: null }]);

    React.useEffect(() => {
        setFileState(prev => ({ ...prev, csvFiles: modes }));
    }, [modes, setFileState]);

    const addMode = () => setModes(prev => [...prev, { mode: MODES.RAIL.id, file: null }]);

    return (<>
        <p>
            Upload a single KML file for the route, then the CSV file or files for the mode traversal.
            There may only be a single rail route, and there may be zero, one, or many intermodal routes.
        </p>
        <Form.Group className='mb-3'>
            <Form.Label>KML File</Form.Label>
            <Form.Control type="file" accept={MIME_TYPE_KML} required
                onChange={e => setFileState(prev => ({ ...prev, kmlFile: e.target.files[0], startFile: null }))} />
        </Form.Group>
        {modes.map((_mode, index) => <ModeInput key={index} index={index} modes={modes} setModes={setModes} />)}
        <Button variant="outline-success" onClick={addMode}>Add Mode</Button>
    </>);
}

function ModeInput({ index, modes, setModes }) {
    const mode = modes[index];
    const updateMode = (key, value) => setModes(prev => {
        const next = [ ...prev ];
        const prevMode = next[index];
        next[index] = { ...prevMode, [key]: value };
        return next;
    });

    const modeChange = e => {
        const newModeId = parseInt(e.target.value);
        const newMode = MODE_ARRAY.find(m => m.id === newModeId);
        if (newMode) {
            updateMode('mode', newModeId);
        }
    };
    const fileChange = e => updateMode('file', e.target.files[0]);
    const deleteClick = () => setModes(prev => prev.filter((_v, i) => i !== index));

    return <InputGroup className="mb-3">
        <InputGroup.Text>Mode {index + 1}</InputGroup.Text>
        <Form.Select value={mode.mode} onChange={modeChange}>
            {MODE_ARRAY.map(m => <option key={m.id} value={m.id}>{m.name}</option>)}
        </Form.Select>
        <Form.Control type="file" accept={MIME_TYPE_CSV} required onChange={fileChange} />
        <Button variant="outline-danger" disabled={modes.length < 2} onClick={deleteClick}>X</Button>
    </InputGroup>;
}


function JsonInput({ fileState, setFileState }) {

    // TODO: instead of just setting, read the file, & give options for mode order

    return <Form.Group className='mb-3'>
        <Form.Control type="file" accept={MIME_TYPE_JSON} onChange={e => setFileState({ startFile: e.target.files[0] })} />
    </Form.Group>;
}

function SiteSelect({ sites, input, setInput, attr }) {
    return <Form.Select value={input[attr]} required
         onChange={e => setInput(i => ({ ...i, [attr]: inputNumber(e.target.value)}))}>
            <option value="">Select...</option>
            {sites.ids.map(id => <option key={id} value={id}>{sites.data[id]?.name}</option>)}
    </Form.Select>;
}

function inputNumber(value) {
    return value === '' ? '' : parseInt(value);
}
