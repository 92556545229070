
import React from 'react';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';

import { useSessionContext } from '../../session-context';

import { DATE_FMT, resultDirToMoment } from '../../util/scenarioFunctions';
import ScenarioTable from './ScenarioTable';

export default function AdminDeletedScenarios() {
    const session = useSessionContext();
    const [scenarios, setSecenarios] = React.useState([]);
    const [refresh, setRefresh] = React.useState(false);
    const [toDelete, setToDelete] = React.useState(null);

    React.useEffect(() => {
        loadData(session).then(setSecenarios).catch(error => {
            console.log(error);
            setSecenarios([]);
        });
    }, [session, refresh]);

    const submit = () => {
        const data = Array.isArray(toDelete) ? toDelete : [toDelete];
        session.postJson('/results/deleted', data).catch(error => {
            console.log(error);
            window.alert("Error submitting request!");
        }).finally(() => setRefresh(r => !r));
        setToDelete(null);
    };

    let confirmationText = null;
    if (Array.isArray(toDelete)) {
        confirmationText = `Are you sure you want to delete ${scenarios.length} scenarios?`
    } else if (toDelete) {
        confirmationText = `Are you sure you want to delete scenario ${toDelete.scenario} - ${toDelete.targetFormat}?`
    }

    return <Container fluid>
        <Row><Col className='text-center'>
            <h3>Deleted Scenarios</h3>
            <p>These are scenarios have been marked for deletion</p>
        </Col></Row>
        <Row><Col>
            <ScenarioTable scenarios={scenarios} onDelete={setToDelete} />
        </Col></Row>
        <Row><Col>
            <Button variant='danger' onClick={() => setToDelete(scenarios)} disabled={scenarios.length < 2}>
                Delete all
            </Button>
        </Col></Row>
        <Modal centered animation={false} show={!!toDelete} onHide={() => setToDelete(null)}>
            <Modal.Header>Confirmation</Modal.Header>
            <Modal.Body>
                {confirmationText} This cannot be undone.
            </Modal.Body>
            <Modal.Footer>
                <Button variant='danger' onClick={submit}>Confirm</Button>
                <Button variant='secondary' onClick={() => setToDelete(null)}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    </Container>;
}

async function loadData(session) {
    const data = await session.getJson('/results/deleted');
    const scenarios = [];
    data.forEach(row => row.files.forEach(target => scenarios.push({ 
        user: row.user, name: row.name, target,
        targetFormat: resultDirToMoment(target)?.format(DATE_FMT)
    })));

    return scenarios;
}
