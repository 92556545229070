
import React from 'react';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import path from 'path-browserify';

import { downloadZip } from 'client-zip';
import md5hasher from 'md5-wasm';

import scenarioFilter from  './scenario-file-filter';
import { useSessionContext } from '../../session-context';

// release version regex
const RELEASE_REGEX = /^V\d+\.\d+\.\d+\.\d+$/;

export default function Submission() {
    const [toUpload, setToUpload] = React.useState(null);
    const [processing, setProcessing] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [versions, setVersions] = React.useState([]);
    const session = useSessionContext();

    React.useEffect(() => {
        session.getJson('/queue/versions').then(versions => {
            setVersions(parseVersions(versions));
        }).catch(error => console.log(error));
    }, [session]);

    const onUpload = e => processUpload(e, setToUpload, setProcessing, setError);

    let display = null;
    if (processing) {
        display = <div>
            <Spinner animation="border" role="status" />
            <p>Processing...</p>
        </div>;
    } else if (error) {
        display = <div>
            <p>Error: {error}</p>
            <Button onClick={() => { setError(null); setToUpload(null); }}>Reset</Button>
        </div>;
    } else if (toUpload) {
        display = <UploadTrigger toUpload={toUpload} versions={versions} setProcessing={setProcessing} setToUpload={setToUpload} />;
    } else {
        display = <label className="btn btn-primary">
            <input ref={inputOnRef} type='file' className='d-none' multiple onChange={onUpload} />
            Select NGSAM scenario
        </label>;
    }

    return <Container fluid className="text-center">
        <Row><Col>
            <h3>Submit Scenario</h3>
        </Col></Row>
        <Row><Col>
            <p>Submit an NGSAM scenario to run on the server.</p>
            { display }
        </Col></Row>
    </Container>;
}

function UploadTrigger({ toUpload, versions, setProcessing, setToUpload }) {
    const session = useSessionContext();
    // TODO: option to select version
    const [version, setVersion] = React.useState();

    React.useEffect(() => {
        setVersion(versions[0]);
    }, [versions]);

    const onClick = () => {
        setProcessing(true);
        const options = {
            method: "POST",
            body: toUpload.zipBuffer,
            headers: {
                Authorization: "Basic " + session.sessionString,
                'Content-Type': 'application/zip',
            },
        };
        const path = `/queue/submit/${toUpload.name}?checksum=${toUpload.md5Hash}&version=${version}`;
        fetch(path, options).then(res => {
            if (res.status === 200) {
                window.alert("Scenario submitted!");
                setToUpload(null);
            } else {
                console.log("Error on scenario submit:", res.status, res.statusText);
                window.alert("Error submitting scenario!");
            }
        }).finally(() => setProcessing(false));
    }

    return <div className="d-flex align-items-center justify-content-center">
        <select className="form-select d-inline w-auto me-2" value={version} onChange={e => setVersion(e.target.value)}>
            {versions.map(v => <option key={v}>{v}</option>)}
        </select>
        <Button onClick={onClick} className="me-2">Upload</Button> Scenario: { toUpload.name }
    </div>
}

function parseVersions(versions) {
    if (Array.isArray(versions)) {
        const major = [], minor = [];
        versions.forEach(v => (RELEASE_REGEX.test(v) ? major : minor).push(v));
        return [ ...major.sort().reverse(), ...minor.sort() ];
    }
    return [];
}

async function processUpload(event, setToUpload, setProcessing, setError) {
    setProcessing(true);
    setToUpload(null);
    setError(null);
    try {
        if (event?.target?.files?.length > 0) {
            const result = await processScenario(event.target.files);
            setToUpload(result);
        }
    } catch (error) {
        setError("Unable to process upload: " + error.message);
    }
    setProcessing(false);
}

async function processScenario(fileList) {
    const scenarioName = rootDir(fileList[0].webkitRelativePath);
    if (!scenarioName) {
        throw new Error("Could not determine scenario name!");
    }
    // console.log(scenarioName);
    
    /** @type {File[]} */
    const scenarioFiles = [];
    for (let i = 0; i < fileList.length; ++i) {
        const file = fileList[i];
        if (scenarioFilter(scenarioName, file)) {
            scenarioFiles.push(file);
        }
    }
    // console.log(scenarioFiles);
    if (!scenarioFiles.find(f => f.name.startsWith("simcom"))) {
        throw new Error("Directory does not appear to be an NGSAM scenario!");
    }

    // map to zip contents
    const zipFiles = scenarioFiles.map(f => ({
        name: f.webkitRelativePath, lastModified: f.lastModified, input: f,
    }));

    const zipBuffer = await downloadZip(zipFiles).arrayBuffer();
    // generate hash from the blob
    const md5Hash = await md5hasher(zipBuffer);
    // console.log(md5Hash);

    return { name: scenarioName, zipBuffer, md5Hash };
}

function rootDir(inputPath) {
    if (!inputPath) {
        return null;
    }
    let root = path.dirname(inputPath);
    let newRoot = path.dirname(root);
    while (newRoot !== '.') {
        root = newRoot;
        newRoot = path.dirname(root);
    }
    // console.log(inputPath, root);
    return root;
}

function inputOnRef(element) {
    if (element) {
        element.webkitdirectory = "true";
        element.directory = "true";
    }
}
