
import path from 'path-browserify';

// copied from anl.nfolds.scenario.ScenarioFile
const FILES = [
    "AllocationPriority.xlsx",
    "constraints.csv",
    "ISFPackageCreated.csv",
    "ISFPackageIncoming.csv",
    "packageUsage.csv",
    "quickEdit.cfg",
    "random.seed",
    "reactorMobileRepackaging.csv",
    "simParameters.json",
    "transportationBareFuelCaskHours.csv",
    "transportationBareFuelCasks.csv",
    "transportationOverpackHours.csv",
    "transportationOverpacks.csv",
    "transportationPackageMaintenance.csv",
    "vehicleHours.csv",
    "vehicles.csv",
    "wpcPackageTypes.csv",
    "routeprofile.txt",
];

// directories
const DIRS = [
    { name: "agentState", allowSub: false, ending: ".json" },
    { name: "userEdits", allowSub: false, ending: ".json" },
    { name: "fuelProjection", allowSub: false, ending: ".json" },
    { name: "routeData", allowSub: true, ending: ".csv" },
];

/**
 * 
 * @param {string} scenarioName 
 * @param {File} file 
 */
export default function filter(scenarioName, file) {
    if (!file?.webkitRelativePath) {
        return false;
    }
    // all top-level xml & json, plus files & dirs
    if (file.name.endsWith(".xml") || file.name.endsWith(".json")) {
        if (file.webkitRelativePath === path.join(scenarioName, file.name)) {
            return true;
        }
    }
    if (FILES.indexOf(file.name) >= 0 && file.webkitRelativePath === path.join(scenarioName, file.name)) {
        return true;
    }
    for (let subDir of DIRS) {
        const subName = path.join(scenarioName, subDir.name);
        if (file.name.endsWith(subDir.ending) && file.webkitRelativePath.startsWith(subName)) {
            if (subDir.allowSub || file.webkitRelativePath === path.join(subName, file.name)) {
                return true;
            }
        }
    }

    return false;
}