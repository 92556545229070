import React from 'react';

import { useSessionContext } from '../../session-context';
import StorageDisplay from '../StorageDisplay';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import ArchiveTable from './archiveTable';
import { loadArchived } from '../../util/result-data';

export default function SharedResults() {
    const session = useSessionContext();
    const [scenarios, setScenarios] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        setLoading(true);
        loadArchived(session).then(setScenarios).catch(error => {
            console.log("Error loading shared scenarios!", error);
            setScenarios([]);
        }).finally(() => setLoading(false));
    }, [session]);

    return (<>
        <StorageDisplay />
        <div className="px-3">
            <Container fluid className="text-center mb-3">
                <Row><Col><h3>Archived results</h3></Col></Row>
                <Row><Col><ArchiveTable scenarios={scenarios} loading={loading} /></Col></Row>
            </Container>
        </div>
    </>);
}
