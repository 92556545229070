
import React from 'react';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { useSessionContext } from '../../session-context';
import AddModal from './AddModal';
import EditModal from './EditModal';
import UserTable from './UserTable';

export default function AdminUsers() {
    const session = useSessionContext();
    const [users, setUsers] = React.useState([]);
    const [refresh, setRefresh] = React.useState(false);
    const [addUser, setAddUser] = React.useState(false);
    const [editUser, setEditUser] = React.useState(null);

    React.useEffect(() => {
        session.getJson('/authenticate/user').then(setUsers).catch(error => {
            console.log(error);
            setUsers([]);
        });
    }, [session, refresh]);

    const onSave = () => {
        setEditUser(null);
        setAddUser(false);
        setRefresh(r => !r);
    };

    return <Container fluid>
        <Row><Col>
            <h3 className='text-center'>User Management</h3>
        </Col></Row>
        <Row><Col>
            <UserTable users={users} setEditUser={id => setEditUser(users.find(u => u.id === id))} />
        </Col></Row>
        <Row><Col>
            <Button onClick={() => setAddUser(true)}>Add User</Button>
        </Col></Row>

        <AddModal open={addUser} onClose={() => setAddUser(false)} onSave={onSave} />
        <EditModal user={editUser} clearUser={() => setEditUser(null)} onSave={onSave} />
    </Container>;
}
