
import { useSessionContext } from '../../session-context';

import Table from '../../util/Table';
import { DownloadDropdown, SharedActionDropdown } from '../Results/actions';

import { DATE_FMT } from '../../util/scenarioFunctions';

// base columns (no session needed)
const BASE_COLUMNS = [
    { name: "Scenario", sortable: true, grow: 2, selector: row => row.scenario, format: scenarioName, wrap: true },
    { name: "Run Date", sortable: true, selector: scen => scen.runDate ? scen.runDate.format(DATE_FMT) : scen.result_dir},
    { name: "User", sortable: true, selector: scen => scen.user?.name },
];

function scenarioName(scenario) {
    return scenario.gov ? (<><span className="text-danger fw-bold">OUO</span> {scenario.scenario}</>) : scenario.scenario;
}

export default function SharedTable({ scenarios, loading, setToCompare }) {
    const session = useSessionContext();

    const columns = BASE_COLUMNS.slice();
    columns.push({ name: "Download", ignoreRowClick: true, allowOverflow: true, selector: row => row.scenario,
        format: scen => <DownloadDropdown session={session} scenario={scen} /> });
    columns.push({ name: "Actions", ignoreRowClick: true, allowOverflow: true, selector: row => row.scenario,
        format: scenario => <SharedActionDropdown {...{ scenario, setToCompare}} /> });
    
    return <Table columns={columns} data={scenarios} progressPending={loading} pagination />
}
