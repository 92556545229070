
import React from 'react';

import { useSessionContext } from '../../session-context';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import { COL_ID } from './ProfileTable';
import LoadingSpinner from '../LoadingSpinner';

/**
 * 
 * @param {object} params
 * @param {boolean|{ [COL_ID]: number, name: string, description: string }} params.active
 * @returns 
 */
export default function AddModal({ active, onClose, onSave }) {
    const session = useSessionContext();
    const [input, setInput] = React.useState({ name: "", description: "" });
    const [loading, setLoading] = React.useState(false);
    const [cloneRoutes, setCloneRoutes] = React.useState([]);

    // active is either true/false or source profile
    React.useEffect(() => {
        if (active?.name) {
            setInput({
                name: `${active.name} clone`,
                description: active.description || `Clone of ${active.id} - ${active.name}`,
            });
            setCloneRoutes([]);
            setLoading(true);
            loadRoutes(session, active[COL_ID]).then(setCloneRoutes).catch(error => {
                // TODO
            }).finally(() => setLoading(false));
        } else {
            setCloneRoutes([]);
            setLoading(false);
            setInput({ name: '', description: '' });
        }
    }, [active, session]);

    const title = active?.name ? `Create clone of ${active.name}` : 'Create Profile';
    const onSubmit = e => {
        e.preventDefault();
        const data = { ...input };

        setLoading(true);
        session.postJson('/api/v1/routes/profiles', data).then(r => r.json()).then(newProfile => {
            if (cloneRoutes.length > 0) {
                const routesData = { add: cloneRoutes };
                return session.postJson(`/api/v1/routes/profiles/${newProfile[COL_ID]}/routes`, routesData);
            }
        }).then(() => {
            onSave();
        }).catch(error => {
            console.log(error);
            window.alert("Unable to submit!");
        }).finally(() => setLoading(false));
    };

    const submitDisabled = loading || (active?.name && cloneRoutes.length < 1);

    return <Modal size='xl' animation={false} show={active} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            { loading ? <LoadingSpinner /> : null }
            <Form id="route-profile-add-form" onSubmit={onSubmit}>
                <Form.Group className='mb-3'>
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" required value={input.name}
                        onChange={e => setInput(i => ({...i, name: e.target.value}))} />
                </Form.Group>
                <Form.Group className='mb-3'>
                    <Form.Label>Description</Form.Label>
                    <Form.Control type="text" value={input.description}
                        onChange={e => setInput(i => ({...i, description: e.target.value}))} />
                </Form.Group>
                { active?.name ? <CloneText numRoutes={cloneRoutes.length} /> : null }
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button type="submit" form="route-profile-add-form" variant="primary" disabled={submitDisabled}>Submit</Button>
            <Button className="ms-2" variant="danger" type="button" onClick={onClose}>Cancel</Button>
        </Modal.Footer>
    </Modal>
}

function CloneText({ numRoutes }) {
    if (numRoutes < 1) {
        return <p className="text-danger">No routes in the source profile!</p>;
    }

    return <p>{numRoutes} route{numRoutes === 1 ? '' : 's'} will be cloned</p>;
}

async function loadRoutes(session, profileId) {
    const url = `/api/v1/routes/profiles/${profileId}/routes`;
    return session.getJson(url);
}
