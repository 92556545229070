
import React from 'react';

export default function LabelSwitch({ disabled, elementId, checked, onChange, textTrue, textFalse }) {
    return <>
        <div className="form-check form-switch d-inline-block me-2 ps-0 mb-0">
            <input className="form-check-input d-none" type="checkbox" role="switch" disabled={disabled} />
            <label className="form-check-label" htmlFor={elementId}>{textFalse}</label>
        </div>
        <div className="form-check form-switch d-inline-block mb-0">
            <input className="form-check-input" type="checkbox" role="switch" id={elementId}
                checked={checked} onChange={onChange} disabled={disabled} />
            <label className="form-check-label" htmlFor={elementId}>{textTrue}</label>
        </div>
    </>;
}
