
import React from 'react';

import { useSessionContext } from '../../session-context';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';

import useUsers from '../../util/useUsers';

import { COL_ID } from './ProfileTable';

export default function ShareModal({ profile, setProfile }) {
    const session = useSessionContext();
    const [sharing, setSharing] = React.useState({});
    const [ users ] = useUsers({ excludeSelf: true });

    React.useEffect(() => {
        setSharing({});
        if (!profile) {
            return;
        }
        loadData(session, profile[COL_ID]).then(setSharing).catch(error => {
            console.log("error loading sharing options", error);
            window.alert("Unable to load existing sharing!");
        });
    }, [session, profile]);

    const onSubmit = e => {
        e.preventDefault();
        doSubmit(session, profile[COL_ID], sharing).then(() => {
            setProfile(null);
        }).catch(error => {
            console.log(error);
            window.alert("Unable to save sharing!");
        });
    };
    
    return (<Modal size="lg" animation={false} show={!!profile} onHide={() => setProfile(null)}>
        <Modal.Header closeButton>
            <Modal.Title>Manage profile sharing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p><strong>Profile: </strong>{profile?.name}</p>
            <Form id="route-profile-share-form" onSubmit={onSubmit}>
                <UserInput users={users} sharing={sharing} setSharing={setSharing} />
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" type="submit" form="route-profile-share-form">Save</Button>
            <Button className="ms-2" variant="danger" type="button" onClick={() => setProfile(null)}>Cancel</Button>
        </Modal.Footer>
    </Modal>);
}

function UserInput({ users, sharing, setSharing, }) {

    const setForUser = (id, value) => setSharing(prev => {
        if (value) {
            return { ...prev, [id]: value };
        } else {
            const copy = { ...prev };
            delete copy[id];
            return copy;
        }
    });

    return users.ids.map(id => <Form.Group as={Row} key={id} className="mb-2">
        <Form.Label column sm="3" className='fw-bold'>{users.data[id].name}</Form.Label>
        <Col sm="9">
            <Form.Select size="sm" value={sharing[id] || ''} onChange={e => setForUser(id, e.target.value)}>
                <option value="">No access</option>
                <option value="r">Viewer</option>
                <option value="rw">Editor</option>
            </Form.Select>
        </Col>
    </Form.Group>);
}

async function loadData(session, id) {
    const records = await session.getJson('/api/v1/routes/profiles/' + id + "/sharing");
    const userMap = {};
    records.forEach(r => userMap[r.runuserid] = r.access);
    return userMap;
}

async function doSubmit(session, id, sharing) {
    const update = []
    Object.keys(sharing).forEach(userId => update.push({ userId, access: sharing[userId] }));
    const res = await session.postJson('/api/v1/routes/profiles/' + id + "/sharing", update);
    if (res.status > 299) {
        throw new Error(`Invalid response: ${res.status} ${res.statusText}`);
    }
}
