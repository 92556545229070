
export const FT_SIZE_KEY = 'ngsam-ue-font-size';
export const PG_SIZE_KEY = 'ngsam-ue-page-size';

export function getValue(key, dataType) {
    const strVal = localStorage.getItem(key);
    if (!strVal) {
        return null;
    }
    let parsed = null;
    try {
        parsed = JSON.parse(strVal);
    } catch (error) {
        console.log("Unable to parse json from", strVal);
        return null;
    }
    if (typeof parsed === dataType) {
        return parsed;
    } else {
        console.log("parsed value did not match data type", dataType, parsed);
        return null;
    }
}

export function storeValue(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}
