import React from 'react';

import { useSessionContext } from '../../session-context';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import { useNavigate } from 'react-router-dom';

import Select from '../../util/CustomSelect';

import { loadResults, loadSharedResults } from '../../util/result-data';

import { DATE_FMT } from '../../util/scenarioFunctions';

export default function NewComparisonModal({ show, setShow, seed }) {
    
    return <Modal animation={false} size="lg" show={!!show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
            <Modal.Title>Scenario comparison</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>Initialize a new comparison by selecting a base and target scenario.
                The target scenario will be compared against the base for differences in results.</p>
            <Body show={show} setShow={setShow} seed={seed} />
        </Modal.Body>
    </Modal>
}

function Body({ show, setShow, seed}) {
    const session = useSessionContext();
    const [loading, setLoading] = React.useState(true);
    const [scenarios, setScenarios] = React.useState([]);
    const [filtered, setFiltered] = React.useState([]);
    const [filter, setFilter] = React.useState('');

    const [baseId, setBaseId] = React.useState(-1);
    const [targetId, setTargetId] = React.useState(-1);

    const navigate = useNavigate();

    React.useEffect(() => loadScenarios(session, setScenarios, setLoading), [session, show]);
    React.useEffect(() => {
        setBaseId(-1);
        setTargetId(-1);
    }, [scenarios, show]);

    // detect seed input
    React.useEffect(() => checkSeed(scenarios, seed, setBaseId), [scenarios, seed]);

    React.useEffect(() => {
        if (filter) {
            setFiltered(scenarios.filter(s => {
                if (s.id === baseId || s.id === targetId) {
                    return true;
                }
                const lcFilter = filter.toLowerCase();
                return s.scenario.toLowerCase().indexOf(lcFilter) >= 0 ||
                    s.runDate.format(DATE_FMT).toLowerCase().indexOf(lcFilter) >= 0 ||
                    s.user.toLowerCase().indexOf(lcFilter) >= 0;
            }));
        } else {
            setFiltered(scenarios);
        }
    }, [scenarios, filter, baseId, targetId]);

    if (loading) {
        return <div>
            <Spinner animation="border" role="status" />
            <p>Loading scenarios...</p>
        </div>;
    }

    const submitDisabled = baseId < 0 || targetId < 0 || baseId === targetId;
    const targetSubText = baseId >= 0 && baseId === targetId ? "Please select different result sets" : '\u00A0';

    const options = filtered.map(s => ({ key: s.id, value: `${s.scenario} - ${s.runDate.format(DATE_FMT)} - ${s.user}`}));

    return <Form onSubmit={e => doSubmit(e, session, baseId, targetId, scenarios, navigate)}>
        <Form.Group controlId="formFilter" className="mb-3">
            <Form.Label className="fw-bold">Result filter</Form.Label>
            <InputGroup>
                <Form.Control type="text" placeholder='type to filter selection options'
                    value={filter} onChange={e => setFilter(e.target.value)} />
                <HelpButton />
            </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBaseSelect" className="mb-3">
            <Form.Label className="fw-bold">Base results</Form.Label>
            <Select options={options} value={baseId} dropMaxHeight="200px" onChange={newValue => setBaseId(newValue)} />
        </Form.Group>
        <Form.Group controlId="formTargetSelect" className="mb-3">
            <Form.Label className="fw-bold">Target results</Form.Label>
            <Select options={options} value={targetId} dropMaxHeight="200px" onChange={newValue => setTargetId(newValue)} />
            <Form.Text className="text-danger">{targetSubText}</Form.Text>
        </Form.Group>
        
        <Button variant="primary" type="submit" className="me-2" disabled={submitDisabled}>Submit</Button>
        <Button variant="danger" type="button" onClick={() => setShow(false)}>Cancel</Button>
    </Form>
}

function HelpButton() {
    const popover = <Popover>
        <Popover.Body>
            Enter text to filter available selections.
            This can be used to filter by scenario name, like 'USFleet'
            or run date, like '2021-12' to find scenarios run in December 2021.
        </Popover.Body>
    </Popover>;

    return <OverlayTrigger placement="left" overlay={popover}>
        <Button variant='secondary' className="shadow-none">
            <FontAwesomeIcon icon={faQuestionCircle} size="lg" />
        </Button>
    </OverlayTrigger>;
}

function doSubmit(e, session, baseId, targetId, scenarios, navigate) {
    e.preventDefault();
    const baseObj = scenarios.find(s => s.id === baseId);
    const targetObj = scenarios.find(s => s.id === targetId);
    if (!baseObj || !targetObj) {
        console.log("Couldn't find scenarios for", baseId, targetId);
        return alert("Unable to submit comparison!");
    }
    // create post data
    const base = [ baseObj.user, baseObj.scenario, baseObj.resultDir ];
    const target = [ targetObj.user, targetObj.scenario, targetObj.resultDir ];
    // response will be a new record - redirect to comparison page
    session.postJson('/results/compare', { base, target })
        .then(res => res.json()).then(newRecord => navigate("/results/comparison/" + newRecord.id)
    ).catch(error => {
        console.log(error);
        alert("Error submitting comparison!");
    });
}

function loadScenarios(session, setScenarios, setLoading) {
    setScenarios([]);
    setLoading(true);
    Promise.all([ loadResults(session), loadSharedResults(session) ]).then(([ scenarios, shared ]) => {
        // combine into { scenario, user, resultDir, and runDate }
        const result = scenarios.map(scen => {
            const { resultDir, scenario, runDate } = scen;
            return { resultDir, scenario, runDate, user: session.user.name };
        });
        result.push(...shared.map(scen => {
            const { result_dir: resultDir, scenario, runDate } = scen;
            return { resultDir, scenario, runDate, user: scen.user.name };
        }));
        // assign ids based on index for select inputs
        result.forEach((r, i) => r.id = i);
        setScenarios(result);
        setLoading(false);
    }).catch(error => {
        console.log(error);
        setScenarios([]);
        setLoading(false);
    })
}

function checkSeed(scenarios, seed, setBaseId) {
    if (scenarios.length > 0 && seed) {
        const found = scenarios.find(
            s => s.resultDir === (seed.resultDir || seed.result_dir) && s.scenario === seed.scenario);
        if (found) {
            setBaseId(found.id);
        }
    }
}
