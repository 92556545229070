
/**
 * Sorts the input array and returns it
 * @param {object[]} array 
 * @param {{ column: string | function, direction: "ASC"|"DESC"}?} sort 
 * @return {object[]} array sorted
 */
export default function genericSort(array, sort) {
    if (!sort) {
        return array;
    }
    const mod = sort.direction === 'DESC' ? -1 : 1;
    const accessor = typeof sort.column === 'function' ? sort.column : (row => row[sort.column]);
    const sortFn = (r1, r2) => mod * valueCompare(accessor(r1), accessor(r2));
    return array.sort(sortFn);

}

function valueCompare(v1, v2) {
    if (nullOrEmptyStr(v1) && nullOrEmptyStr(v2)) {
        return 0;
    } else if (nullOrEmptyStr(v1)) {
        return 1; // nulls last
    } else if (nullOrEmptyStr(v2)) {
        return -1; // nulls last
    } else if (typeof v1 === 'number' && typeof v2 === 'number') {
        return v1 - v2;
    } else if (v1 < v2) {
        return -1;
    } else if (v1 > v2) {
        return 1;
    }
    return 0;
}

function nullOrEmptyStr(val) {
    return val == null || val === "";
}
