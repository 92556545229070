import React from "react";

import { Header } from "../Table/PaginatedTable";
import PaginatedServerTable from "../Table/PaginatedServerTable";
import useFilter, { buildFilterString } from "../../util/useFilter";
import useUsers from "../../util/useUsers";
import useSites from "../../util/useSites";

export default function ProfileRouteTable({ profileId, setSelected, refresh }) {
    const [filter, updateFilter] = useFilter();
    const [filterStr, setFilterStr] = React.useState(null);
    const [sort, setSort] = React.useState(null);
    const [clearSelectedRows, setClearSelectedRows] = React.useState(false);
    const [ users ] = useUsers({ includeInactive: true });
    const [ sites ] = useSites();

    React.useEffect(() => {
        setFilterStr(buildFilterString(filter));
        setClearSelectedRows(v => !v);
    }, [filter]);

    React.useEffect(() => {
        setSelected({});
    }, [clearSelectedRows, setSelected, refresh]);

    const onSort = (column, direction) => {
        setSort(direction ? { column, direction } : null);
        setClearSelectedRows(v => !v);
    };

    // build filter headings
    const columns = buildHeaders(sort, onSort, updateFilter, users, sites);

    const tableProps = {
        columns,
        fixedHeader: true,
        fixedHeaderScrollHeight: "300px",
        selectableRows: true,
        clearSelectedRows,
        onSelectedRowsChange: setSelected,
    };

    const url = `/api/v1/routes/profiles/${profileId}/routes`;
    return (<PaginatedServerTable dataUrl={url} filter={filterStr} sort={sort} refresh={refresh} tableProps={tableProps} />);
}

/**
 * @param {{ column: string, direction: string }?} sort
 * @param {function} onSort 
 * @param {function} onFilter 
 * @param {{ ids: [], data: {} }} users
 * @param {{ ids: [], data: {} }} sites
 * @returns 
 */
function buildHeaders(sort, onSort, onFilter, users, sites) {
    
    const opts = { sortable: true, sort, onSort, onFilter };

    const fromHead = <Header text="From" column="facilityfrom" {...opts}
        idList={sites.ids} displayFn={id => sites.data[id]?.name} />;
    const toHead = <Header text="To" column="facilityto" {...opts}
        idList={sites.ids} displayFn={id => sites.data[id]?.name} />;
    const routeHead = <Header text="Source Route" column="routeid" {...opts} />;

    const columns = [
        { name: fromHead, selector: row => row.facilityfrom, format: row => optFacility(row.facilityfrom, sites) },
        { name: toHead, selector: row => row.facilityto, format: row => optFacility(row.facilityto, sites) },
        { name: routeHead, selector: row => row.routeid, format: row => formatSource(row, sites) },
    ];

    return columns;
}

function optFacility(facilityId, sites) {
    return sites.data[facilityId]?.name || facilityId;
}

function formatSource({ routeid, route }, sites) {
    const from = optFacility(route.facilityfrom, sites);
    const to = optFacility(route.facilityto, sites);
    return `Route ${routeid}: ${from} to ${to}`;
}
