
import { CompareNumbersNullsLast, CompareStringsBlanksLast } from '../../util/Comparators';
import { getValue, storeValue, PG_SIZE_KEY } from '../../util/localStorageJson';
import { matches } from './filter';

export const Action = {
    INIT: "INIT",
    ADD_RECORD: "ADD_RECORD",
    SET_VALUE: "SET_VALUE",
    APPLY_BATCH: "APPLY_BATCH",
    CLEAR_EDITS_BATCH: "CLEAR_EDITS_BATCH",
    DELETE: "DELETE",
    SELECT: "SELECT",
    SET_FILTER: "SET_FILTER",
    SHOW_DELETED: "SHOW_DELETED",
    SORT: "SORT",
    PAGE_SIZE: "PAGE_SIZE",
    PAGE_RECORD: "PAGE_RECORD",
    SET_SAVED: "SET_SAVED",
};

export function initialize(rows) {
    const sorted = getSorted({ rows, sorted: Object.keys(rows) });
    const pageSize = getValue(PG_SIZE_KEY, 'number') || 50;
    return {
        rows,
        sorter: null,
        sorted,
        showDeleted: true,
        filter: {},
        filtered: sorted.slice(),
        pageSize,
        pageRecord: 0,
        display: sorted.slice(0, 50),
        // TODO: more elegant solution? If user reverts value to what it was, this won't pick it up
        hasChanges: false,
    };
}

export default function dataReducer(state, action) {
    // console.log(action);
    switch (action.type) {
        case Action.INIT:
            return initialize(action.payload);
        case Action.ADD_RECORD:
            return addRecord(state, action.payload);
        case Action.SET_VALUE:
            return setValue(state, action.payload);
        case Action.APPLY_BATCH:
            return applyBatch(state, action.payload);
        case Action.CLEAR_EDITS_BATCH:
            return clearEditsBatch(state, action.payload);
        case Action.DELETE:
            return deleteRows(state, action.payload);
        case Action.SELECT:
            return updateSelection(state, action.payload);
        case Action.SORT:
            return applySort(state, action.payload);
        case Action.SHOW_DELETED:
            return applyShowDeleted(state, action.payload);
        case Action.SET_FILTER:
            return applyFilter(state, action.payload);
        case Action.PAGE_SIZE:
            return applyPageSize(state, action.payload);
        case Action.PAGE_RECORD:
            return applyPageRecord(state, action.payload);
        case Action.SET_SAVED:
            return { ...state, hasChanges: false };
        default:
            throw new Error("Unknown action: " + action.type);
    }
}

function setValue(state, {rowIdx, column, value}) {
    const copy = { ...state, rows: { ...state.rows }, hasChanges: true };
    const copyRow = { ...copy.rows[rowIdx] };
    // for added rows, just set the value
    if (copyRow._addedRow) {
        copyRow[column] = value;
    } else { // set or clear edit
        if (copyRow[column] === value) {
            delete copyRow["_u_" + column];
        } else {
            copyRow["_u_" + column] = value;
        }
    }
    copy.rows[rowIdx] = copyRow;
    return copy;
}

// this might be expensive
function applyBatch(state, { keysDeleted, rowsAdded, rowEdits }) {
    let copy = state;
    if (keysDeleted.length > 0) {
        copy = deleteRows(copy, keysDeleted);
    }
    Object.keys(rowsAdded).forEach(key => {
        copy = addRecord(copy, rowsAdded[key]);
    });
    Object.keys(rowEdits).forEach(key => {
        const edits = rowEdits[key];
        Object.keys(edits).forEach(column => {
            copy = setValue(copy, { rowIdx: key, column: column, value: edits[column] });
        });
    });
    copy.sorted = getSorted(copy);
    copy.filtered = getFiltered(copy);
    copy.display = getDisplay(copy);
    return copy;
}

function clearEditsBatch(state, indices) {
    const copy = { ...state, rows: { ...state.rows } };
    let refresh = false; // track if we had to delete or add records
    indices.forEach(rowIdx => {
        const row = copy.rows[rowIdx];
        if (row) {
            if (row._addedRow) {
                copy.hasChanges = true;
                delete copy.rows[rowIdx];
                refresh = true;
                return;
            }

            const rowProps = Object.keys(row).filter(key => key.startsWith("_u_"));
            if (rowProps.length > 0) {
                copy.hasChanges = true;
                copy.rows[rowIdx] = {...row};
                rowProps.forEach(key => delete copy.rows[rowIdx][key]);
            }
            if (row._deleted) {
                refresh = true;
                copy.hasChanges = true
                copy.rows[rowIdx] = {...row};
                delete copy.rows[rowIdx]._deleted;
            }
        }
    });
    if (refresh) { // reset page display because we now have different keys
        copy.sorted = Object.keys(copy.rows);
        copy.sorted = getSorted(copy);
        copy.filtered = getFiltered(copy);
        copy.display = getDisplay(copy);
    }

    return copy;
}

function addRecord(state, newRecord) {
    const copy = { ...state, rows: { ...state.rows }, hasChanges: true };
    copy.rows[newRecord._primaryKey] = newRecord;
    // insert the record key at the top of sorted (filter and pagination may still hide record...)
    copy.sorted = [ ...copy.sorted ];
    copy.sorted.unshift(newRecord._primaryKey);
    copy.filtered = getFiltered(copy);
    copy.display = getDisplay(copy);
    return copy;
}

function applySort(state, sorter) {
    const copy = { ...state, sorter };
    copy.sorted = getSorted(copy);
    copy.filtered = getFiltered(copy);
    copy.display = getDisplay(copy);
    return copy;
}

function getSorted({ rows, sorter, sorted }) {
    if (!sorter) {
        return Object.keys(rows);
    }
    const keys = [ ...sorted ]; // copy existing to preserve order
    if (sorter.rowFunction) { // custom row sorter instead of just column name/type
        return keys.sort(rowSorter(key => rows[key], sorter.rowFunction));
    }

    let sortFunction;
    switch (sorter.type) {
        // checkbox is a tinyint(1, 0), so let number sort handle it
        case 'checkbox':
        case 'number':
            sortFunction = CompareNumbersNullsLast;
            break;
        // for now, 'datetime-local' and 'date' are sortable string formats
        // so just let string sort handle it
        case 'datetime-local':
        case 'date':
        case 'text':
        default:
            sortFunction = CompareStringsBlanksLast;
    }

    const comparing = key => getRowValue(rows[key], sorter.name);
    return keys.sort(rowSorter(comparing, sortFunction, sorter.reverse));
}

function rowSorter(comparing, sortFunction, reverse) {
    return (k1, k2) => (reverse ? -1 : 1) * sortFunction(comparing(k1), comparing(k2));
}

function applyFilter(state, { column, filter }) {
    const copy = { ...state, filter: { ...state.filter } };
    if (!filter) {
        delete copy.filter[column];
    } else if (copy.filter[column]) {
        copy.filter[column] = { ...copy.filter[column], ...filter};
    } else {
        copy.filter[column] = filter;
    }
    copy.filtered = getFiltered(copy);
    if (copy.pageRecord >= copy.filtered.length) {
        copy.pageRecord = 0;
    }
    copy.display = getDisplay(copy);
    return copy;
}

function applyShowDeleted(state, showDeleted) {
    const copy = { ...state, showDeleted: !!showDeleted };
    copy.filtered = getFiltered(copy);
    copy.display = getDisplay(copy);
    return copy;
}

function getFiltered({ rows, sorted, filter, showDeleted}) {
    const filterCols = Object.keys(filter);
    return sorted.filter(key => {
        const row = rows[key];
        if (row._deleted && !showDeleted) {
            return false;
        }
        if (filterCols.length < 1) {
            return true;
        }
        return filterCols.reduce(
            (aggregate, column) => aggregate && matches(getRowValue(row, column), filter[column].op, filter[column].value),
            true
        );
    });
}

function applyPageSize(state, pageSize) {
    const copy = { ...state, pageSize };
    storeValue(PG_SIZE_KEY, pageSize);
    if (copy.filtered.length < pageSize) {
        copy.pageRecord = 0;
    }
    copy.display = getDisplay(copy);
    return copy;
}

function applyPageRecord(state, pageRecord) {
    const copy = { ...state, pageRecord };
    copy.display = getDisplay(copy);
    return copy;
}

function getDisplay({ filtered, pageSize, pageRecord }) {
    return filtered.slice(pageRecord, pageRecord + pageSize);
}

function updateSelection(state, { selected, indices }) {
    const copy = { ...state, rows: { ...state.rows } };
    const selectVal = !!selected; // coerce boolean
    // if no indices are given, apply to all rows
    if (!indices) {
        indices = Object.keys(copy.rows);
    }
    indices.forEach(rowIdx => {
        const row = copy.rows[rowIdx];
        if (row && row._selected !== selectVal) {
            copy.rows[rowIdx] = { ...row, _selected: selectVal };
        }
    });
    return copy;
}

function deleteRows(state, indices) {
    const copy = { ...state, rows: { ...state.rows } };
    const removedKeys = [];
    indices.forEach(rowIdx => {
        const row = copy.rows[rowIdx];
        if (!row) {
            return;
        }
        if (row._addedRow) {
            copy.hasChanges = true;
            delete copy.rows[rowIdx];
            removedKeys.push(rowIdx);
        } else if (!row._deleted) {
            copy.hasChanges = true;
            copy.rows[rowIdx] = {...row, _deleted: true};
        }
    });
    // remove actual from sorted
    if (removedKeys.length > 0) {
        copy.sorted = [ ...copy.sorted ];
        removedKeys.forEach(rKey => copy.sorted.splice(copy.sorted.indexOf(rKey), 1));
    }

    copy.filtered = getFiltered(copy);
    copy.display = getDisplay(copy);
    return copy;
}

export function getRowValue(row, colName) {
    const optColName = '_u_' + colName;
    if (Object.keys(row).indexOf(optColName) >= 0) {
        return row[optColName];
    }
    return row[colName];

}
