import React from 'react';

import { Navigate, useLocation } from 'react-router-dom';
import { buildFromSessionString, useSessionContext } from '../../session-context';

import { Buffer } from 'buffer';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ForgotModal from './forgotModal';

export default function Login({ setSession }) {
    const session = useSessionContext();

    const [error, setError] = React.useState(null);
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    // modal displays
    const [forgotUser, setForgotUser] = React.useState(false);
    const [forgotPass, setForgotPass] = React.useState(false);

    const location = useLocation();

    if (session?.user?.name) { // already signed in, redirect
        const { from } = location.state || { from: { pathname: "/" }};
        return <Navigate to={from} />;
    }

    return (<Container>
        <Form className="bg-main p-2" onSubmit={e => doLogin(e, username, password, setSession, setError)}>
            <Row>
                <Col className="text-center"><h4>NGSAM Login</h4></Col>
            </Row>
            <Row>
                <Col>{getGovWarning()}</Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="username">
                        <Form.Label>Username</Form.Label>
                        <Form.Control type="text" required={true} placeholder="username"
                            value={username} onChange={e => setUsername(e.target.value)}></Form.Control>
                        <Form.Text className="text-muted">
                            <Button className="p-0 shadow-none" variant='link' size='sm'
                                type="button" tabIndex="-1" onClick={() => setForgotUser(true)}>
                                Forgot username?
                            </Button>
                        </Form.Text>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" required={true} placeholder="******"
                            value={password} onChange={e => setPassword(e.target.value)}></Form.Control>
                        <Form.Text className="text-muted">
                            <Button className="p-0 shadow-none" variant='link' size='sm'
                                type="button" tabIndex="-1" onClick={() => setForgotPass(true)}>
                                Forgot password?
                            </Button>
                        </Form.Text>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button variant="primary" type="submit">Submit</Button>
                    <span className="text-danger mx-2">{error}</span>
                </Col>
            </Row>
        </Form>
    <ForgotModal show={forgotUser} setShow={setForgotUser} type="email" arg="email"
        message="Enter your email to retrieve your username" dest="/authenticate/forgot"></ForgotModal>
    <ForgotModal show={forgotPass} setShow={setForgotPass} type="text" arg="username"
        message="Enter your username to reset your password. You will be emailed with instructions on how to reset your password."
        dest="/authenticate/reset"></ForgotModal>
    </Container>);
}

async function doLogin(event, username, password, setSession, setError) {
    event.preventDefault();
    try {
        const Authorization = "Basic " + Buffer.from(username + ":" + password).toString('base64');
        const response = await fetch("/authenticate/login", {
            method: 'POST', cache: 'no-cache', body: "", headers: { Authorization }
        });
        if (!response.ok) {
            console.log("Invalid response", response.status, response.statusText);
            throw new Error("Invalid username and password");
        }
        const data = await response.json();
        const token = data.session;
        // console.log("Got user token", token);
        const session = buildFromSessionString(token, () => setSession(null));
        setSession(session);
    } catch (error) {
        console.log(error);
        setError("Login failed: " + error.message);
    }
}

function getGovWarning() {
    return (
        <div className="bg-grey p-2">
            <b>Notice To Users:</b>
            <p>This is a Federal computer system and is the property of the United States Government.
                It is for authorized use only. Users (authorized or unauthorized) have no explicit or implicit expectation of privacy.</p>
            <p>Any or all uses of this system and all files on this system may be intercepted, monitored,
            recorded, copied, audited, inspected, and disclosed to authorized site, Department of Energy,
            and law enforcement personnel, as well as authorized officials of other agencies, both domestic and foreign.
            By using this system, the user consents to such interception, monitoring, recording, copying, auditing,
                inspection, and disclosure at the discretion of authorized site or Department of Energy personnel.</p>
            <p>Unauthorized or improper use of this system may result in administrative disciplinary action and civil and criminal penalties.
            By continuing to use this system you indicate your awareness of and consent to these terms and conditions of use.
                LOG OFF IMMEDIATELY if you do not agree to the conditions stated in this warning.</p>
        </div>
    )
}
