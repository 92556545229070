
const AUTHOR_ACCESS = "rw";

// utility for profile permissions
export function canEdit(session, profile) {
    return isCreator(session, profile) || isAuthor(session, profile);
}

export function isAuthor(session, profile) {
    if (Array.isArray(profile?.routeprofilepermissions)) {
        return profile.routeprofilepermissions.find(p => p.access === AUTHOR_ACCESS && p.runuserid === session?.user?.id);
    }
    return false;
}

export function isCreator(session, profile) {
    return profile?.ownerid === session?.user?.id;
}

const permissions = { canEdit, isAuthor, isCreator };
export default permissions;
