
import React from 'react';

import { useSessionContext } from '../../session-context';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function DeleteModal({ profileId, toDelete, onClose, onSave }) {
    const session = useSessionContext();
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        setError(null);
    }, [toDelete]);

    const submit = () => {
        setError(null);
        const data = {
            remove: toDelete.map(({ facilityfrom, facilityto, priority }) => ({ facilityfrom, facilityto, priority }))
        };
        const url = `/api/v1/routes/profiles/${profileId}/routes`;
        session.postJson(url, data).then(res => {
            if (res.status > 399) {
                throw new Error("Invalid response code " + res.status);
            }
            onSave();
            onClose();
        }).catch(error => {
            console.log(error);
            setError("Error sending request!");
        });
    };

    const text = toDelete.length > 1 ? `${toDelete.length} records` : '1 record';
    return <Modal animation={false} show={toDelete.length > 0} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title>Confirm Removal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>Are you sure you want to remove {text} from this profile?</p>
            { error ? <p className="text-danger">{error}</p> : null }
        </Modal.Body>
        <Modal.Footer>
            <Button variant="danger" onClick={submit}>Confirm</Button>
            <Button className="ms-2" variant="secondary" onClick={onClose}>Cancel</Button>
        </Modal.Footer>
    </Modal>
}
