
import moment from 'moment';

// utility function for converting from SQL date string to <input> date string and back

export const MYSQL_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSS[Z]"; // 1991-01-01T00:00:00.000Z
export const INPUT_DATETIME_FORMAT = "YYYY-MM-DDTHH:mm";
export const INPUT_DATE_FORMAT = "YYYY-MM-DD";

export function convertDateTimeMysqlToInput(dateString, optStrict) {
    return convert(dateString, MYSQL_FORMAT, INPUT_DATETIME_FORMAT, optStrict);
}

export function convertDateTimeInputToMysql(dateString, optStrict) {
    return convert(dateString, INPUT_DATETIME_FORMAT, MYSQL_FORMAT, optStrict);
}

export function convertDateMysqlToInput(dateString, optStrict) {
    return convert(dateString, MYSQL_FORMAT, INPUT_DATE_FORMAT, optStrict);
}

export function convertDateInputToMysql(dateString, optStrict) {
    return convert(dateString, INPUT_DATE_FORMAT, MYSQL_FORMAT, optStrict);
}

function convert(dateString, srcFmt, dstFmt, optStrict) {
    if (!dateString) {
        return null;
    }

    const date = moment.utc(dateString, srcFmt, !!optStrict);
    if (!date.isValid()) {
        throw new Error("Invalid '" + srcFmt + "' date string: " + dateString);
    }
    return date.format(dstFmt);
}

