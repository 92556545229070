
import React from 'react';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { useSessionContext } from '../../session-context';
import LoadingSpinner from '../LoadingSpinner';
import useSites from '../../util/useSites';

export default function AdminProcRoutes() {
    const session = useSessionContext();
    const [loading, setLoading] = React.useState(false);
    const [targetRoutes, setTargetRoutes] = React.useState([]);
    const [refresh, setRefresh] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        session.getJson('/api/v1/routes/preprocess').then(setTargetRoutes).catch(error => {
            window.alert("Error loading routes!");
            setTargetRoutes([]);
        }).finally(() => setLoading(false));
    }, [session, refresh]);

    const submit = async routeIds => {
        try {
            setLoading(true);
            const resp = await session.postJson('/api/v1/routes/preprocess', { routeIds });
            if (resp.status > 399) {
                throw new Error("Invalid response: " + resp.status);
            }
            window.alert("Routes submitted. It may take the server awhile to process.");
            setRefresh(v => !v);
        } catch (error) {
            window.alert("Error submitting update!");
            setRefresh(v => !v);
        }
    };

    const runRoute = id => submit([id]);
    const runAll = () => submit(targetRoutes.map(r => r.routeid));

    return <Container>
        { loading ? <LoadingSpinner /> : null }
        <Row><Col>
            <h3 className='text-center'>Route Pre-processing</h3>
        </Col></Row>
        <Row><Col>
            <RouteDisplay targetRoutes={targetRoutes} runRoute={runRoute} runAll={runAll} />
        </Col></Row>
    </Container>;
}

function RouteDisplay({ targetRoutes, runRoute, runAll }) {
    const [ sites ] = useSites();
    if (targetRoutes.length < 1) {
        return <p>There are no routes that need to be pre-processed.</p>
    }

    const btnStyle = { minWidth: "75px" };

    return (<>
        <div className='mb-2'>
            <Button size="sm" onClick={runAll} className="me-2" style={btnStyle}>Run all</Button>
            The following routes need to be pre-processed:
        </div>
        {targetRoutes.map(route => <div key={route.routeid} className='mb-2'>
            <Button size="sm" onClick={() => runRoute(route.routeid)} className="me-2" style={btnStyle}>Run</Button>
            Route {route.routeid}: {optFacility(route.facilityfrom, sites)} to {optFacility(route.facilityto, sites)}
        </div>)}
    </>);
}

function optFacility(facilityId, sites) {
    return sites.data[facilityId]?.name || facilityId;
}
