
import React from 'react';

import Container from 'react-bootstrap/Container';

import { faSquarePlus } from '@fortawesome/free-solid-svg-icons';

import ActionIcon from '../../util/ActionIcon';
import useRouteShapes from '../../util/useRouteShapes';

import Map from '../Map';
import RouteTable from './RouteTable';
import AddModal from './AddModal';
import LinksModal from './LinksModal';

export default function UserRoutes() {
    const [selected, setSelected] = React.useState({});
    const [doAdd, setDoAdd] = React.useState(false);
    const [showLinks, setShowLinks] = React.useState(null);
    // memorize selection
    const selectedIds = React.useMemo(() => (selected?.selectedRows || []).map(r => r.routeid), [ selected ]);
    const { loading, geoJsonFeatures } = useRouteShapes(selectedIds);

    return <Container fluid="lg" className='pb-4'>
        <div className="mb-2 d-flex align-items-center">
            <span className='h3 mb-0 me-2'>Routes</span>
            <ActionIcon icon={faSquarePlus} size="lg" title="Add Route" action={() => setDoAdd(true)}
                className="text-success p-0 shadow-none" />
        </div>
        <div className="mb-2">
            <RouteTable setSelected={setSelected} setShowLinks={setShowLinks} refresh={doAdd} />
        </div>
        <Map height="600px" geoJsonFeatures={geoJsonFeatures} loading={loading} />
        <AddModal open={doAdd} onClose={() => setDoAdd(false)} />
        <LinksModal routeid={showLinks} onClose={() => setShowLinks(null)} />
    </Container>;
}
