import React from 'react';

import { SessionContext, buildFromSessionString } from './session-context';
import Navigation from './components/Navigation';

import './App.css';

const LS_SESSION_KEY = "ngsam-session";

export default function App() {
    const [session, setSession] = React.useState(null);

    React.useEffect(() => {
        // onload, check for session
        const storedSession = localStorage.getItem(LS_SESSION_KEY);
        if (storedSession) {
            try {
                const built = buildFromSessionString(storedSession, () => setSession(null));
                setSession(built);
            } catch (error) {
                console.log("Unable to build session", error);
            }
        }
    }, []);

    // when session updates, save in local storage
    React.useEffect(() => {
        if (session) {
            localStorage.setItem(LS_SESSION_KEY, session?.sessionString);
            // run keep-alive ping every minute
            const interval = setInterval(() => session.keepAlive(), 60 * 1000);
            return () => clearInterval(interval);
        } else {
            localStorage.removeItem(LS_SESSION_KEY);
        }
    }, [session]);

    return <SessionContext.Provider value={session}>
        <Navigation setSession={setSession} />
    </SessionContext.Provider>;
}
