import React from 'react';

import { useSessionContext } from '../session-context';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Row from 'react-bootstrap/Row';

export default function StorageDisplay() {
    const session = useSessionContext();
    const [capacity, setCapacity] = React.useState(null);

    React.useEffect(() => {
        session.getJson('/results/capacity').then(data => {
            setCapacity(data.capacity);
        }).catch(error => {
            console.log(error);
        });
    }, [session])

    const cap = capacity || { total: 0 };

    // build the progress bar
    let percFull, percText, color;
    if (cap.total > 0 && cap.available > 0 && cap.total > cap.available) {
        percFull = parseInt((1 - cap.available / cap.total) * 100, 10);
        percText = percFull + "%";
        color = percFull > 90 ? "danger" : (percFull > 60 ? "warning" : "success");
    } else { // build grey ? bar
        percFull = 0;
        percText = "???";
        color = "info";
    }
    return (<Container fluid className="mb-2"><Row className="justify-content-end"><Col md="6">
        <p className="mb-0">Server storage use:</p>
        <ProgressBar now={percFull} label={percText} variant={color}></ProgressBar>
    </Col></Row></Container>);
}
