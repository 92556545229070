
import React from 'react';

import { useSessionContext } from '../../session-context';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Dropdown from "react-bootstrap/Dropdown";
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';

import moment from 'moment';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList, faEdit, faEye } from '@fortawesome/free-solid-svg-icons';

import ActionIcon from '../../util/ActionIcon';
import Table from '../../util/Table';

import ShareModal from './ShareModal';
import CreateModal from './CreateModal';
import { Link } from 'react-router-dom';
import SummaryModal from './SummaryModal';
import { DownloadDropdownItem } from '../../util/DownloadLink';

import { DATE_FMT } from '../../util/scenarioFunctions';

export default function ProjectionTable() {
    const session = useSessionContext();
    const [projections, setProjections] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [filteredProjections, setFilteredProjections] = React.useState([]);
    const [showUser, setShowUser] = React.useState("");

    const [refresh, setRefresh] = React.useState(false);
    const [createNew, setCreateNew] = React.useState(false);
    const [shareProjection, setShareProjection] = React.useState(null);
    const [summaryProjection, setSummaryProjection] = React.useState(null);

    // load user projections
    React.useEffect(() => {
        setLoading(true);
        loadData(session, setProjections).finally(() => setLoading(false));
    }, [session, refresh]);

    React.useEffect(() => {
        let filtered = projections;
        if (showUser) filtered = filtered.filter(p => p.username === showUser);
        setFilteredProjections(filtered);
    }, [projections, showUser]);

    const doRefresh = () => setRefresh(v => !v);

    const dropdownArgs = { session, setShareProjection, doRefresh, setCreateNew };

    const myProjections = projections.filter(p => p.mine);

    // filter config
    const userOpts = {};
    projections.forEach(({ username }) => userOpts[username] = username);
    const userDropArgs = { options: userOpts, value: showUser, setValue: setShowUser };

    const onNotesUpdate = (projectionId, newNotes) => {
        session.postJson('/user-edits/user-projections/' + projectionId, { notes: newNotes }).then(r => {
            if (!r.ok) {
                throw new Error(`Invalid response: ${r.status} ${r.statusText}`);
            }
            doRefresh();
            setSummaryProjection(old => ({ ...old, notes: newNotes }));
        }).catch(error => {
            console.log(error);
            window.alert("Error saving notes: " + error.message);
        });
    };

    const cells = [
        { name: "Name", grow: 1.5, sortable: true, selector: p => p.name },
        { name: "Owner", sortable: true, selector: p => p.username },
        { name: "Created", sortable: true, selector: p => p.created, format: formatCreated },
        {
            name: "Summary", grow: 0.5,
            selector: p => <SummaryButton projection={p} setSummaryProjection={setSummaryProjection} />
        },
        {
            name: "Edit/View", grow: 0.5,
            selector: p => <LaunchButton projection={p} />
        },
        { name: "Actions", grow: 0.5, allowOverflow: true, selector: p => <ActionDropdown projection={p} {...dropdownArgs} /> }
    ];

    return (<>
        <Container fluid="xl" className="text-center">
            <Row><Col><h3>User Fuel Projections</h3></Col></Row>
            <Row>
                <Col xs="4" lg="2" className="mb-2 d-flex align-items-center justify-content-end fw-bold">User:</Col>
                <Col xs="8" lg="4" className="mb-2"><FilterDropdown {...userDropArgs} /></Col>
            </Row>
            <Table columns={cells} data={filteredProjections} noDataComponent="No projections found" progressPending={loading} />
            <Row><Col>
                <Button className="mt-2" type="button" onClick={() => setCreateNew(true)}>Create New</Button>
            </Col></Row>
        </Container>
        <CreateModal createNew={createNew} setCreateNew={setCreateNew} myProjections={myProjections} doRefresh={doRefresh} />
        <ShareModal projection={shareProjection} setProjection={setShareProjection} />
        <SummaryModal projection={summaryProjection} setProjection={setSummaryProjection} onNotesUpdate={onNotesUpdate} />
    </>);
}

function FilterDropdown({ options, value, setValue }) {
    return <Form.Select size="sm" value={value} onChange={e => setValue(e.target.value)}>
        <option value="">Show all</option>
        {Object.keys(options).map(id => <option key={id} value={id}>{options[id]}</option>)}
    </Form.Select>;
}

function LaunchButton({ projection }) {
    const to = projection.id ? `user-${projection.id}` : `base-${projection.name}`;
    return (<Link to={"./" + to} className="p-0 ms-2 btn btn-link btn-sm shadow-none" title={projection.mine ? "Edit" : "View"}>
        <FontAwesomeIcon icon={projection.mine ? faEdit : faEye} size="lg"></FontAwesomeIcon>
    </Link>);
}

function ActionDropdown({ projection, session, setShareProjection, doRefresh, setCreateNew }) {
    let hrefBase;
    if (projection.id) {
        hrefBase = "/user-edits/user-projections/" + projection.id + "/assembly";
    } else {
        hrefBase = "/user-edits/fuel-projections/" + projection.name + "/assembly";
    }
    const fileBase = projection.name + "-assembly";

    return (<Dropdown>
        <Dropdown.Toggle className="p-0 shadow-none" size="sm" variant="link" title="Click here to see other projection actions">
            Actions
        </Dropdown.Toggle>
        <Dropdown.Menu popperConfig={{ strategy: "fixed" }} renderOnMount={true}>
            <DownloadDropdownItem href={hrefBase + "?format=json"} download={fileBase + ".json"}>
                Download JSON
            </DownloadDropdownItem>
            <DownloadDropdownItem href={hrefBase + "?format=csv"} download={fileBase + ".csv"}>
                Download CSV
            </DownloadDropdownItem>
            <Dropdown.Item onClick={() => setCreateNew(projection)}>Create copy</Dropdown.Item>
            {!projection.mine ? null : (<>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => setShareProjection(projection)}>Edit sharing</Dropdown.Item>
                <Dropdown.Item onClick={() => deleteProjection(session, projection, doRefresh)}>Delete</Dropdown.Item>
            </>)}
        </Dropdown.Menu>
    </Dropdown>);
}

async function loadData(session, setProjections) {
    try {
        const [userList, baseList] = await Promise.all([
            session.getJson('/user-edits/user-projections'),
            session.getJson('/user-edits/fuel-projections'),
        ]);
        // identify which projections I own so that they show up first, then sort by user
        const data = [];
        userList?.data?.forEach(p => {
            p.mine = (p.username === session.user.name);
            data.push(p);
        });
        baseList?.data?.forEach(name => data.push({
            name, username: "{base projection}", created: null, mine: false
        }));
        data.forEach(p => p.mine = (p.username === session.user.name));
        data.sort(projectionSort);
        setProjections(data);
    } catch (error) {
        console.log(error);
        setProjections([]);
    }
}

function formatCreated(row) {
    return row.created ? moment(row.created).format(DATE_FMT) : '--';
}

function deleteProjection(session, projection, doRefresh) {
    if (!window.confirm("Are you sure you want to delete " + projection.name + "? This cannot be undone!")) {
        return;
    }
    session.delete('/user-edits/user-projections/' + projection.id).then(doRefresh).catch(error => {
        console.log("error deleting", error);
        window.alert("Unable to delete projection!");
    });
}

function SummaryButton({ projection, setSummaryProjection }) {
    return (<ActionIcon icon={faClipboardList} className="ms-2 p-0 shadow-none"
        title="View edits summary" action={e => setSummaryProjection(projection)} />);
}

function projectionSort(p1, p2) {
    const val = userSort(p1, p2);
    if (val !== 0) {
        return val;
    }
    return p1.name.localeCompare(p2.name);
}

function userSort(p1, p2) {
    if (p1.mine && p2.mine) {
        return 0;
    } else if (p1.mine) {
        return -1;
    } else if (p2.mine) {
        return 1;
    }
    return p1.username.localeCompare(p2.username);
}
