
import React from 'react';

import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-bootstrap/Modal';
import LoadingSpinner from '../LoadingSpinner';

import { useSessionContext } from '../../session-context';
import useFips from '../../util/useFips';
import useRailroads from '../../util/useRailroads';

import PaginatedTable from "../Table/PaginatedTable";

import MODES from './modes';

const MODE_ARRAY = Object.values(MODES);

export default function LinksModal({ routeid, onClose }) {
    const session = useSessionContext();
    const [loading, setLoading] = React.useState(false);
    const [links, setLinks] = React.useState([]);

    React.useEffect(() => {
        if (routeid == null) {
            setLinks([]);
            return;
        }
        setLoading(true);
        session.getJson('/api/v1/routes/links/' + routeid).then(links => setLinks(groupLinks(links))).catch(error => {
            window.alert("Error loading links!");
        }).finally(() => setLoading(false));
    }, [session, routeid])
    
    return (<Modal size="xl" animation={false} show={routeid !== null} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title>Links for Route {routeid}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            { loading ? <LoadingSpinner /> : null }
            <LinkDisplay links={links} />
        </Modal.Body>
    </Modal>);
}

function LinkDisplay({ links }) {
    const [ fips ] = useFips();
    const [ railroads ] = useRailroads();
    if (links.length < 1) {
        return <p>No links loaded</p>;
    }
    return <Accordion>
        {links.map((linkSet, index) => <LinkAccordionItem key={index} {...{ linkSet, index, fips, railroads }} />)}
    </Accordion>
}

function LinkAccordionItem({ linkSet, index, fips, railroads }) {
    const modeid = linkSet[0].travelmode;
    const mode = MODE_ARRAY.find(m => m.id === modeid);
    const attrKey = mode?.attributeKey;
    const totalLength = parseInt(linkSet.reduce((v, link) => {
        if (link[attrKey]?.length > 0) {
            return v + link[attrKey].length;
        }
        return v;
    }, 0) * 100) / 100;

    return <Accordion.Item key={index} eventKey={index.toString()}>
        <Accordion.Header>{mode?.name || "Unknown"} Leg - {linkSet.length} links - {totalLength} miles</Accordion.Header>
        <Accordion.Body>
            <LinkSetTable {...{ linkSet, mode, fips, railroads }} />
        </Accordion.Body>
    </Accordion.Item>;
}

function LinkSetTable({ linkSet, mode, fips, railroads }) {
    const attrKey = mode?.attributeKey;

    const columns = [
        { name: "Link", grow: 0.5, selector: (_r, index) => index },
        { name: "Length (miles)", selector: row => row[attrKey]?.length || "" },
        { name: "FIPS", selector: row => row.fips },
        { name: "State", grow: 0.5, selector: row => fips.data[row.fips?.slice(0, 2)]?.code || "" }
    ];
    if (mode === MODES.RAIL) {
        const rrSelect = r => r.permissions.map(p => railroads.data[p.railroadid]?.railroad_id || "??").join(", ");
        columns.push({ name: "Permissions", selector: rrSelect });
        columns.push({ name: "Track Class", selector: row => row[attrKey]?.fratrackclass });
    }

    const tableProps = {
        columns,
        fixedHeader: true,
        fixedHeaderScrollHeight: "300px",
    };

    return <PaginatedTable tableProps={tableProps} data={linkSet} />
}

function groupLinks(links) {
    // links are in order, group by mode
    const groups = [];
    let current = null;
    for (const link of links) {
        if (current == null || current[0].travelmode !== link.travelmode) {
            current = [ link ];
            groups.push(current);
        } else {
            current.push(link);
        }
    }
    return groups;
}
