
import React from 'react';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import ActionIcon from '../../util/ActionIcon';

export default function TableWarnings({ uniqueViolations, table, setModalMessage }) {
    const keyNames = Object.keys(uniqueViolations);
    if (keyNames.length < 1) {
        return <ActionIcon className="opacity-0" disabled={true} icon={faExclamationTriangle} action={() => {}} />;
    }
    const title = "Warning: duplicate unique keys detected! Click here for more information.";
    const action = () => setModalMessage({
        title: "Unique key warning",
        body: buildBody(uniqueViolations, keyNames, table._primaryKey)
    });

    return (<ActionIcon className="text-danger shadow-none" title={title} icon={faExclamationTriangle} action={action} />);
}

function buildBody(uniqueViolations, keyNames, primaryKey) {
    return keyNames.map(keyName => (<div key={keyName}>
        The key <strong>{keyName}</strong> had multiple records with the same values.
        <ul>
            {Object.entries(uniqueViolations[keyName]).map(([keyValue, violation]) =>
                <Entry key={keyValue} keyValue={keyValue} violation={violation} primaryKey={primaryKey} />)}
        </ul>
    </div>));
}

function Entry({ keyValue, violation, primaryKey }) {
    const keyString = parseKeyString(violation.key, keyValue);
    return (<li>
        Key: "{keyString}" was repeated for the records:
        <ul>{violation.records.map(record => <li key={record}>{parseKeyString(primaryKey, record)}</li>)}</ul>
    </li>);
}

function parseKeyString(key, keyValue) {
    const values = JSON.parse(keyValue);
    let built = "";
    for (var i = 0; i < key.length; ++i) {
        built += (key[i] + " = " + values[i] + " ");
    }

    return built.slice(0, -1);
}
