
import { jsonToCSV } from 'react-papaparse';

import downloadFile from "../../util/downloadFile";
import { rowHasEdits } from "./data";
import { getRowValue } from "./dataReducer";

export const DELETED_COL = "deleted?";

export default function exportEditsToCsv(fileName, columns, rows, editsOnly) {
    // build array of arrays including a deleted? column
    const colNames = columns.map(col => col.name);
    const lines = [ colNames.concat(DELETED_COL) ];
    // now iterate through records, outputing any edited, added, or deleted
    Object.keys(rows).forEach(key => {
        const row = rows[key];
        if (!editsOnly || row._addedRow || row._deleted || rowHasEdits(row)) {
            const values = colNames.map(col => nullConvert(getRowValue(row, col)));
            values.push(!!row._deleted);
            lines.push(values);
        }
    });
    // eventually we may need to stream to a blob instead of just stringifying
    // as of 2021-03-16, the longest file is < 500k characters, which is well within
    // acceptable string length
    const output = "data:text/csv;charset=utf-8," + jsonToCSV(lines, { newline: "\n" });
    console.log("generated csv length", output.length);
    downloadFile(fileName, encodeURI(output));
}

function nullConvert(val) {
    if (val === null || val === undefined) {
        return "null";
    }
    return val;
}
