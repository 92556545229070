import React from 'react';

import { useSessionContext } from '../../session-context';
import StorageDisplay from '../StorageDisplay';
import Table from '../../util/Table';

import parseData from './data';

import moment from 'moment';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import { DATE_FMT } from '../../util/scenarioFunctions';

const REFRESH_RATE = 30 * 1000;

// date columns
const DATE_COLS = ['submit_time', 'start_time', 'completion_time'];
const DATE_UTC_COLS = ['simulation_time'];

// table column definition
const SCENARIO_COL = { ...column("Scenario", "directory_trimmed", 1.5), wrap: true };
const NODE_COL = { name: "Node", grow: 0.5, sortable: true, selector: s => s?.server_id };
const runningHeaders = [
    NODE_COL,
    column("Version", "version"),
    column("User", "username"),
    SCENARIO_COL,
    column("Submitted", "submit_time"),
    column("Started", "start_time"),
    column("Sim Date", "simulation_time")
];

const queuedHeadersBase = [
    column("User", "username"),
    SCENARIO_COL,
    column("Version", "version"),
    column("Submitted", "submit_time"),
    column("Priority", "priority"),
    // must push a custom cell column for user-cancellable scenarios
];

const errorHeaders = [
    NODE_COL,
    column("User", "username"),
    SCENARIO_COL,
    column("Submitted", "submit_time"),
    column("Started", "start_time"),
    column("Notes", "notes"),
];

const completedHeaders = [
    NODE_COL,
    column("User", "username"),
    SCENARIO_COL,
    column("Submitted", "submit_time"),
    column("Started", "start_time"),
    column("Completed", "completion_time"),
];

export default function Status() {
    const session = useSessionContext();
    const [data, setData] = React.useState({});
    const [refreshToggle, setRefreshToggle] = React.useState(false);
    // only show loading on first load, not refreshes
    const [firstLoad, setFirstLoad] = React.useState(true);

    React.useEffect(() => {
        // periodically load data
        const load = async () => {
            try {
                const [queueData, nodes] = await Promise.all([
                    // only show past 3 months
                    session.getJson('/queue?after=' + moment().subtract(3, "months").format("YYYY-MM-DD")),
                    session.getJson('/queue/nodes'),
                ]);
                setData(parseData(queueData, nodes))
            } catch (error) {
                console.log(error);
            }
            setFirstLoad(false);
        };
        load();
        const interval = setInterval(load, REFRESH_RATE);
        // return the function to clear the interval on finish
        return () => clearInterval(interval);
    }, [session, refreshToggle]);

    // copy queued columns, and add cancel column
    const queuedHeaders = queuedHeadersBase.slice();
    queuedHeaders.push({ name: "Cancel", ignoreRowClick: true, selector: s => s.directory_trimmed,
        format: s => <CancelLink scenario={s} session={session} setRefreshToggle={setRefreshToggle} />
    });

    return (<>
        <StorageDisplay />
        <div className="px-3">
            <Container fluid className="text-center">
                { firstLoad ? <Spinner animation="border" role="status" /> :
                    <Tables data={data} queuedHeaders={queuedHeaders} /> }
            </Container>
        </div>
    </>);
}

function Tables({ data, queuedHeaders }) {
    return <>
        <Row><Col><h3>Running</h3></Col></Row>
        <Row className=" mb-3"><Col>
            <Table columns={runningHeaders} data={data.running || []} noDataComponent="No scenarios running" />
        </Col></Row>

        <Row><Col><h3>Queued</h3></Col></Row>
        <Row className=" mb-3"><Col>
            <Table columns={queuedHeaders} data={data.queued || []} noDataComponent="No scenarios queued" />
        </Col></Row>

        {(!data.error || data.error.length < 1) ? null : <>
            <Row><Col><h3>Errors</h3></Col></Row>
            <Row className=" mb-3"><Col>
                <Table columns={errorHeaders} data={data.error} />
            </Col></Row>
        </>}

        <Row><Col><h3>Complete</h3></Col></Row>
        <Row className=" mb-3"><Col>
            <Table columns={completedHeaders} data={data.completed || []}
                noDataComponent="No completed scenarios" pagination/>
        </Col></Row>
    </>;
}

function column(name, key, grow) {
    return { name: name, selector: scenario => format(scenario, key), sortable: true, grow };
}

function format(obj, attr) {
    if (!obj[attr] && obj[attr] !== 0) {
        return "";
    }
    if (DATE_COLS.indexOf(attr) > -1) {
        return moment(obj[attr]).format(DATE_FMT);
    } else if (DATE_UTC_COLS.indexOf(attr) > -1) {
        return moment.utc(obj[attr]).format(DATE_FMT);
    }
    return obj[attr].toString();
}

function CancelLink({ scenario, session, setRefreshToggle }) {
    if (scenario.username !== session.user.name) {
        return null;
    }
    return (<Button variant='link' className="p-0 shadow-none" style={{textDecoration: "none"}} size='sm'
        title={"Cancel " + scenario.filename} onClick={e => {
        e.preventDefault();
        if (window.confirm("Are you sure you want to cancel " + scenario.filename + "?")) {
            session.postJson("/queue/cancel/" + scenario.id)
                .catch(error => console.log("Error", error))
                .finally(() => setRefreshToggle(toggle => !toggle));
        }
    }}>Cancel</Button>);
}
