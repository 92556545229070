
import Leaflet from 'leaflet';

const MessageControl = Leaflet.Control.extend({

    initialize: function(options) {
        this.container = document.createElement('div');
        this.container.className = "map-message-control";
        Leaflet.setOptions(this, options);
    },

    setMessages: function(messages) {
        /** @type {HTMLDivElement} */
        const container = this.container;
        if (!messages || messages.length < 1) {
            container.innerText = '';
        } else {
            container.innerText = messages.join('\n');
        }
    },

    onAdd: function(map) {
        return this.container;
    }

});

export default MessageControl;
