
import Dropdown from "react-bootstrap/Dropdown";

import { useSessionContext } from "../session-context";

export default function DownloadLink(props) {
    const session = useSessionContext();
    if (!session) {
        throw new Error("Missing required session!");
    }
    // auth with a cookie since we can't set headers on download links
    const setCookie = () => {
        document.cookie = 'ngsam-download-auth=' + session.sessionString + '; max-age=60';
    };

    return <a {...props} onClick={setCookie}>{props.children}</a>;
}

export function DownloadDropdownItem(props) {
    const session = useSessionContext();
    if (!session) {
        throw new Error("Missing required session!");
    }
    // auth with a cookie since we can't set headers on download links
    const setCookie = () => {
        document.cookie = 'ngsam-download-auth=' + session.sessionString + '; max-age=60';
    };

    return <Dropdown.Item {...props} onClick={setCookie}>{props.children}</Dropdown.Item>;
}
