
import React from 'react';

import { useSessionContext } from '../../session-context';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

const BASE_USER = { email: '', active: false, admin: false, gov: false };

export default function EditModal({ user, clearUser, onSave }) {
    const [data, setData] = React.useState(BASE_USER);

    React.useEffect(() => {
        setData(user ? { email: user.email, active: user.active, admin: user.admin, gov: user.gov } : BASE_USER);
    }, [user]);
    
    return <Modal animation={false} show={!!user} onHide={clearUser}>
        <Modal.Header closeButton>
            <Modal.Title>Manage user: {user?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <EditBody user={user} data={data} setData={setData} onSave={onSave}/>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" type="submit" form="edit-user-form">Save</Button>
            <Button variant="danger" type="button" onClick={clearUser}>Cancel</Button>
        </Modal.Footer>
    </Modal>;
}

function EditBody({ user, data, setData, onSave }) {
    const session = useSessionContext();
    return <Form id="edit-user-form" onSubmit={e => doSubmit(e, session, user, data, onSave)}>
        <Form.Group className="mb-2">
            <Form.Label>Username</Form.Label>
            <Form.Control type="email" value={user.name} disabled={true} />
        </Form.Group>
        <Form.Group className="mb-2">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" value={data.email} required onChange={e => setData(old => ({ ...old, email: e.target.value }))} />
        </Form.Group>
        <Form.Group className="mb-2 user-select-none" controlId="edit-user-active-check">
            <Form.Check type="checkbox" label="Active" checked={data.active}
                onChange={e => setData(old => ({ ...old, active: e.target.checked }))} />
        </Form.Group>
        <Form.Group className="mb-2 user-select-none" controlId="edit-user-admin-check">
            <Form.Check type="checkbox" label="Admin" checked={data.admin}
                onChange={e => setData(old => ({ ...old, admin: e.target.checked }))} />
        </Form.Group>
        <Form.Group className="mb-2 user-select-none" controlId="edit-user-gov-check">
            <Form.Check type="checkbox" label="Govt User" checked={data.gov}
                onChange={e => setData(old => ({ ...old, gov: e.target.checked }))} />
        </Form.Group>
    </Form>;
}

function doSubmit(e, session, user, data, onSave) {
    e.preventDefault();
    const update = {};
    if (data.email && data.email !== user.email) {
        update.email = data.email;
    }
    [ 'active', 'admin', 'gov' ].forEach(key => {
        if (typeof data[key] === 'boolean' && data[key] !== user[key]) {
            update[key] = data[key];
        }
    });
    if (Object.keys(update).length < 1) {
        window.alert("No changes to save!");
        return;
    }

    // save
    session.postJson('/authenticate/user/' + user.id, update).then(onSave).catch(error => {
        console.log("Error editing user", error);
        window.alert("Error saving user!");
    });
}
