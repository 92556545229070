
import {
    faCaretDown, faCaretUp, faInfoCircle,
    faExclamationCircle, faMinusCircle, faPlusCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CompareNumbersNonNull, } from '../../util/Comparators';
import { rowHasEdits } from './data';
import { Action } from './dataReducer';
import ColumnInput, { getCellValue } from './columnInput';

const STATUS_NAME = "_status_";
// default iteration is edited, added, deleted
const STATUS_ORDER_FNS = [ rowHasEdits, r => !!r._addedRow, r => !!r._deleted ];
const STATUS_ORDER_COLORS = [ "text-success", "text-primary", "text-danger" ];
export const DELETED_ROW_CLASS = " text-danger font-italic";

// the cells in sticky table function oddly
// they will respect a width input, up to a certain point but no further
// however, they will always respect minWidth
// This also means we would have to custom write draggable handles to update
// the min width, which seems excessive, since users can leverage excel instead
const DEF_MIN_WIDTH = 100;

export default function HeaderCell(column, editable, sorter, dataDispatch) {
    let onClick, sortIcon;
    const setSorter = sorter => dataDispatch({type: Action.SORT, payload: sorter});
    if (sorter && sorter.name === column.name) {
        onClick = () => setSorter(sorter.reverse ? null : {...sorter, reverse: true});
        sortIcon = <FontAwesomeIcon icon={sorter.reverse ? faCaretDown : faCaretUp} />;
    } else {
        onClick = () => setSorter({name: column.name, type: column._input.type, reverse: false});
        sortIcon = <FontAwesomeIcon icon={faCaretUp} style={{opacity: 0}} />;
    }
    const headStyle = { minWidth: DEF_MIN_WIDTH, cursor: "pointer" };

    return {
        onClick, title: "Click to sort by " + column.name,
        getHeader: () => <span style={headStyle} className="user-select-none d-inline-block w-100">
            {column.name} {sortIcon}
        </span>,
        getValue: (row, rowIdx) => getCell(column, row, rowIdx, editable, dataDispatch),
    };
}

function getCell(column, row, rowIdx, editable, dataDispatch) {
    const [value, edited] = getCellValue(row, column);
    const cellProps = { className: "w-100 d-inline-block" };
    if (edited) {
        cellProps.className += " edited";
        cellProps.title = "Original value: " + row[column.name];
    }
    let className = "w-100 border-0 px-1 py-0 text-overflow-ellipsis";
    if (column._isPrimaryKey || !editable || row._deleted) {
        if (row._deleted) {
            className += DELETED_ROW_CLASS;
        }
        return <span {...cellProps}>
            <ColumnInput column={column} value={value} className={className} disabled />
        </span>;
    }
    const onChange = changeVal => dataDispatch({type: Action.SET_VALUE, payload: {rowIdx, column: column.name, value: changeVal}});
    return <span {...cellProps}>
        <ColumnInput column={column} value={value} onChange={onChange} className={className} />
    </span>;

}

export function StatusHeaderCell(sorter, dataDispatch) {
    let onClick, sortIcon;
    let textColor = "";
    const setSorter = sorter => dataDispatch({type: Action.SORT, payload: sorter});
    if (sorter && sorter.name === STATUS_NAME) {
        const newCount = sorter.iterCount + 1;
        if (newCount === STATUS_ORDER_FNS.length) {
            onClick = () => setSorter(null);
        } else { // rotate the order functions so we get a different status first
            const orderFns = STATUS_ORDER_FNS.slice(newCount, STATUS_ORDER_FNS.length)
                .concat(STATUS_ORDER_FNS.slice(0, newCount));
            onClick = () => setSorter({name: STATUS_NAME, rowFunction: rowSorter(orderFns), iterCount: newCount });
        }
        textColor = STATUS_ORDER_COLORS[newCount - 1];
        sortIcon = <FontAwesomeIcon icon={faCaretUp} />;
    } else {
        onClick = () => setSorter({name: STATUS_NAME, rowFunction: rowSorter(STATUS_ORDER_FNS), iterCount: 0 });
        sortIcon = <FontAwesomeIcon icon={faCaretUp} style={{opacity: 0}} />;
    }
    const className = "user-select-none " + textColor;
    return {
        onClick, title: "Click to sort by status",
        getHeader: () => <span className={className} style={{ cursor: "pointer" }}>
            <FontAwesomeIcon icon={faInfoCircle} /> {sortIcon}
        </span>,
        getValue: getStatus,
    };
}

function getStatus(row) {
    if (row._addedRow) {
        return <FontAwesomeIcon icon={faPlusCircle} className="text-primary" title="Added row" />;
    } else if (row._deleted) {
        return <FontAwesomeIcon icon={faMinusCircle} className="text-danger" title="Deleted row"  />;
    } else if (rowHasEdits(row)) {
        return <FontAwesomeIcon icon={faExclamationCircle} className="text-success" title="Edited row"  />;
    }

    return null;
}

// create a row sorter that caches row values so they don't have to be computed multiple times
function rowSorter(rowFunctionOrder) {
    const rowCache = {};
    const comparing = (row) => rowCache[row._primaryKey] || (rowCache[row._primaryKey] = rowVal(row, rowFunctionOrder));
    return (r1, r2) => CompareNumbersNonNull(comparing(r1), comparing(r2));
}

function rowVal(row, functionOrder) {
    for (var i = 0; i < functionOrder.length; ++i) {
        if (functionOrder[i](row)) {
            return i;
        }
    }
    return functionOrder.length;
}
