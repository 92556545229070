
import React from 'react';
import { useSessionContext } from '../session-context';

// from routes provider
const RR_PATH = "/api/v1/routes/railroad";

/**
 * Fips context, loaded from routes manager - requires session
 * @returns
 */
export default function useRailroads(refresh) {
    const session = useSessionContext();
    const [railroads, setRailroads] = React.useState({ ids: [], data: {} });
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        setError(null);
        loadData(session).then(setRailroads).catch(error => {
            console.log(error);
            setRailroads({ ids: [], data: {} });
            setError(error);
        });
    }, [session, refresh]);

    return [ railroads, error ];
}

async function loadData(session) {
    const rows = await session.getJson(RR_PATH);
    // sort ids by name
    const ids = rows.sort(nameCompare).map(f => f.id);
    const data = {};
    rows.forEach(f => data[f.id] = f);
    return { ids, data };
}

/**
 * @param {{ fips: railroad_id }} u1 
 * @param {{ fips: railroad_id }} u2 
 * @returns 
 */
function nameCompare(u1, u2) {
    return u1.railroad_id.localeCompare(u2.railroad_id, undefined, { sensitivity: 'base' });
}
