
import React from 'react';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

import { Link, useNavigate, useParams } from 'react-router-dom';

import { useSessionContext } from '../../session-context';
import useRouteShapes from '../../util/useRouteShapes';

import Map from '../Map';

import ProfileRouteTable from './ProfileRouteTable';
import permissions from '../UserRouteProfiles/permissions';
import DeleteModal from './DeleteModal';
import AddModal from './AddModal';

export default function UserRouteProfileEdit() {
    const { profileId } = useParams();
    const session = useSessionContext();
    const navigate = useNavigate();
    const [profile, setProfile] = React.useState(null);
    const [selected, setSelected] = React.useState({});
    const [refresh, setRefresh] = React.useState(false);
    // memorize selection
    const selectedIds = React.useMemo(() => (selected?.selectedRows || []).map(r => r.routeid), [ selected ]);
    const { loading, geoJsonFeatures } = useRouteShapes(selectedIds);
    // edits
    const [doAdd, setDoAdd] = React.useState(false);
    const [toDelete, setToDelete] = React.useState([]);

    React.useEffect(() => {
        loadData(session, profileId).then(setProfile).catch(error => {
            console.log(error);
            // TODO: notify user?
            navigate('..', { relative: "path" });
        });
    }, [profileId, session, navigate]);

    const doRefresh = () => setRefresh(r => !r);
    const canEdit = !profile?.locked && permissions.canEdit(session, profile);

    const onDelClick = () => setToDelete(selected?.selectedRows || []);

    return <Container fluid="lg" className='pb-4'>
        <div className="mb-2">
            <span className='h3 mb-0'>
                <Link to=".." relative='path' title="Return to profile table" className='me-2'>&lt;-</Link>
                {canEdit ? "Editing" : "Viewing"} Profile { profile?.name || profileId }
            </span>
        </div>
        { canEdit ? <div className="mb-2">
            <Button size="sm" variant='outline-success' className="me-2" onClick={() => setDoAdd(true)}>Add</Button>
            <Button size="sm" variant='outline-danger' disabled={selectedIds.length < 1} onClick={onDelClick}>Delete selected</Button>
        </div> : null }
        <div className="mb-2">
            <ProfileRouteTable profileId={profileId} setSelected={setSelected} refresh={refresh} />
        </div>
        <Map height="600px" geoJsonFeatures={geoJsonFeatures} loading={loading} />
        <AddModal profileId={profileId} active={doAdd} onClose={() => setDoAdd(false)} onSave={doRefresh} />
        <DeleteModal profileId={profileId} toDelete={toDelete} onClose={() => setToDelete([])} onSave={doRefresh} />
    </Container>;
}

async function loadData(session, profileId) {
    return session.getJson('/api/v1/routes/profiles/' + profileId);
}
