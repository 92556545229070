
import { useSessionContext } from '../../session-context';

import ActionIcon from '../../util/ActionIcon';
import { faUsers } from '@fortawesome/free-solid-svg-icons';

import Table from '../../util/Table';
import { ActionDropdown, DownloadDropdown } from './actions';

import { DATE_FMT } from '../../util/scenarioFunctions';

// base columns (no session needed)
const BASE_COLUMNS = [
    { name: "Scenario", sortable: true, grow: 2, selector: row => row.scenario, format: scenarioName, wrap: true },
    { name: "Run Date", sortable: true, selector: scen => scen.runDate ? scen.runDate.format(DATE_FMT) : scen.resultDir},
    { name: "Result DB?", sortable: true, selector: scen => scen.hasDatabase ? "Yes" : "No" },
    { name: "Size", sortable: true, grow: 0.75, selector: scen => scen.sizeBytes, format: scen => scen.size }
];

export default function ScenariosTable({ scenarios, loading, reload, setShareModalState, setToCompare }) {
    const session = useSessionContext();

    const columns = BASE_COLUMNS.slice();
    columns.push({ name: "Share", ignoreRowClick: true, grow: 0.5, selector: row => row.scenario, center: true,
        format: scen => <ShareButton session={session} scenario={scen} setState={setShareModalState} /> });
    columns.push({ name: "Download", ignoreRowClick: true, allowOverflow: true, selector: row => row.scenario,
        format: scen => <DownloadDropdown session={session} scenario={scen} /> });
    columns.push({ name: "Actions", ignoreRowClick: true, allowOverflow: true, selector: row => row.scenario,
        format: scenario => <ActionDropdown {...{ session, scenario, reload, setToCompare}} /> });

    return <Table columns={columns} data={scenarios} progressPending={loading} pagination />
}

function scenarioName(scenario) {
    return scenario.gov ? (<><span className="text-danger fw-bold">OUO</span> {scenario.scenario}</>) : scenario.scenario;
}

function ShareButton({session, scenario, setState}) {
    return (<ActionIcon icon={faUsers} title="Manage sharing" action={e => {
        e.preventDefault();
        // load the current state of this scenario, and available users to share with
        Promise.all([
            session.getJson("/authenticate/user"),
            session.getJson("/scenarios/shared/" + encodeURIComponent(scenario.scenario) + "/" + scenario.resultDir).then(({ sharing }) => sharing)
        ]).then(([users, sharing]) => {
            if (!users || users.length < 1) {
                throw new Error("Unable to load users/sharing for scenario!");
            }
            if (scenario.gov) {
                users = users.filter(user => user.gov);
            }
            users.sort((u1, u2) => {
                if (u1.gov !== u2.gov) {
                    return u1.gov ? 1 : -1;
                }
                return u1.name.localeCompare(u2.name);
            });

            setState({ users, sharing, scenario });
        }).catch(error => {
            console.log("error loading share menu", error);
        });
    }} />);
}
