
import React from 'react';

import { jsonToCSV } from 'react-papaparse';

import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';

import downloadFile from "../../util/downloadFile";

import { useSessionContext } from '../../session-context';

import DataTable, { ASSEMBLY_HEADERS } from './DataTable';
import { process, parseFile } from './import-data';
import LabelSwitch from '../LabelSwitch';
import LoadingSpinner from '../LoadingSpinner';

const COLUMN_NAMES = ASSEMBLY_HEADERS.map(a => a.name.toUpperCase());

const STATUS_DEFAULT = { processing: false, data: null, error: null };

export default function ImportModal({ profileId, data, onClose, onSave }) {
    const session = useSessionContext();
    // append vs replace
    const [append, setAppend] = React.useState(false);
    const [file, setFile] = React.useState(null);
    const [fileLines, setFileLines] = React.useState(null);
    const [status, setStatus] = React.useState(STATUS_DEFAULT);
    const ref = React.useRef();

    React.useEffect(() => {
        let active = true;
        setStatus(STATUS_DEFAULT);
        setFileLines(null);
        if (file) {
            setStatus({ processing: true });
            // process the file async
            parseFile(file).then(lines => active && setFileLines(lines))
                .catch(error => active && setStatus({ error }));
        } else {
            if (ref.current) {
                ref.current.value = "";
            }
        }
        return () => active = false;
    }, [file]);

    React.useEffect(() => {
        let active = true;
        if (fileLines) {
            setStatus({ processing: true });
            // add a manual delay here since there's not technically any promises
            setTimeout(() => process(fileLines, append, data).then(data => active && setStatus({ data }))
                .catch(error => active && setStatus({ error })), 100);
        }
        return () => active = false;
    }, [fileLines, append, data]);

    const onSubmit = () => doSubmit(session, profileId, status, setStatus, onSave, onClose);

    const noSubmit = !status.data ||
        (status.data.added.length < 1 && status.data.changed.length < 1 && status.data.removed.length < 1);

    return (<>
        { status?.loading ? <LoadingSpinner /> : null }
        <Modal animation={false} show={true} onHide={onClose} dialogClassName='modal-xxl' scrollable >
            <Modal.Header closeButton>
                <Modal.Title>Upload Projection Edits</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Choose a CSV file to import. The CSV must have column names that match the table columns.
                    You may want to download the source data from the actions menu as a starting point.
                </p>
                <Button type="button" variant="link" title="Download template" className="p-0 shadow-none mb-3"
                    onClick={downloadTemplate}>
                    Click here to download a template for import
                </Button>
                <div className="mb-3">
                    <p className="mb-0">Do you want your data to replace the current data, or add on to it?</p>
                    <LabelSwitch elementId="projection-import-switch" checked={append} onChange={() => setAppend(v => !v)}
                    textTrue="Append" textFalse="Replace" />
                </div>
                <div className="mb-3">
                    <InputGroup>
                        <Form.Control ref={ref} type="file" accept="text/csv" onChange={e => setFile(e.target.files[0])} />
                        <Button disabled={!file} variant='outline-danger' title="Clear file" onClick={() => setFile(null)}>
                            <FontAwesomeIcon icon={faX} />
                        </Button>
                    </InputGroup>
                    <Form.Text>If you make changes to the chosen file, you must clear it and re-select it</Form.Text>
                </div>
                <StatusBody status={status} />
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={noSubmit} variant="success" type="button" onClick={onSubmit}>Submit</Button>
                <Button className="ms-2" variant="danger" type="button" onClick={onClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    </>);
}

function StatusBody({ status }) {
    if (status.error) {
        return <p className='text-danger'>
            Error processing file! {status.error.isFileError ? status.error.message : "Unexpected error"}
        </p>;
    } else if (status.processing) {
        return <Spinner animation="border" role="status" />
    } else if (!status.data) {
        return null;
    }

    const items = Object.entries(status.data).map(([name, array], idx) => <StatusItem key={name} { ...{name, array, idx} } />);

    return (<>
        <p>Changes (click to expand):</p>
        <Accordion alwaysOpen={true}>
            { items }
        </Accordion>
    </>);
}

function StatusItem({ name, idx, array }) {
    if (array.length < 1) {
        return <Card>
            <Card.Header className="p-3">{array.length} {name}</Card.Header>
        </Card>
    }
    return <Accordion.Item eventKey={"" + idx}>
        <Accordion.Header>{array.length} {name}</Accordion.Header>
        <Accordion.Body>
            <DataTable data={array} view="assembly" tableOpts={{ fixedHeaderScrollHeight: "300px" }} />
        </Accordion.Body>
    </Accordion.Item>
}

function downloadTemplate() {
    const lines = [ COLUMN_NAMES ];
    const output = "data:text/csv;charset=utf-8," + jsonToCSV(lines, { newline: "\n" });
    downloadFile("assembly-table-template", encodeURI(output));
}

/**
 * 
 * @param {*} session 
 * @param {string} profileId 
 * @param {{ data: { added: any[][], removed: any[][], changed: any[][] }}} status 
 * @param {React.Dispatch<React.SetStateAction>} setStatus 
 * @param {function} onSave 
 * @param {function} onClose 
 */
async function doSubmit(session, profileId, status, setStatus, onSave, onClose) {
    try {
        setStatus(old => ({ ...old, loading: true }));
        // for deletes, we just need id
        // for changes, send the stored new object
        const postData = {
            added: status.data.added,
            removed: status.data.removed.map(v => v[0]),
            changed: status.data.changed.map(v => ({ id: v[0], data: v.at(-1) })),
        };

        const url = "/user-edits/user-projections/" + profileId + "/assembly";
        /** @type {Response} */
        const r = await session.postJson(url, postData);
        if (!r.ok) {
            throw new Error(`Invalid response ${r.status} ${r.statusText}`);
        }
        // 2 possible responses - ok or accepted
        if (r.status === 202) {
            const transaction = await r.text();
            console.log("waiting on transaction", transaction);
            await waitForSuccess(session, url + "/" + transaction);
        }

        onSave();
        onClose();
    } catch (error) {
        setStatus(STATUS_DEFAULT);
        console.log("Error posting update", error);
        window.alert("Error posting update!");
    }
}

async function waitForSuccess(session, url) {
    // poor mans websocket. wait up to 10 minutes (40 x 15)
    for (let i = 0; i < 40; ++i) {
        await new Promise(r => setTimeout(r, 15000));
        const response = await session.getJson(url);
        if (!response) {
            continue;
        } else if (response.success === true) {
            return true;
        } else {
            throw new Error("Data update failed!");
        }
    }
    throw new Error("Timed out waiting for response!");
}
