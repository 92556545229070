
import React from 'react';

import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';

import Map from '../Map';
import { useSessionContext } from '../../session-context';

import useRouteShapes from '../../util/useRouteShapes';

import AddModalBaseSelect from './AddModalBaseSelect';
import AddModalNewRoutes from './AddModalNewRoutes';
import AddModalVerification from './AddModalVerification';
import useSites from '../../util/useSites';

// 3 step process:
// choose routes to add
// optional update from/to, including duplicating with new from/to
// view new routes (& check against dupes), & submit

export default function AddModal({ profileId, active, onClose, onSave }) {
    const session = useSessionContext();
    const [stage, setStage] = React.useState(0);
    const [newRoutes, setNewRoutes] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [verification, setVerification] = React.useState({ running: false, status: null, update: null });
    const [submitStatus, setSubmitStatus] = React.useState({ running: false, error: null });
    // for display
    const [displayIds, setDisplayIds] = React.useState([]);
    const { loading: loadingMap, geoJsonFeatures } = useRouteShapes(displayIds);
    const [ sites ] = useSites();

    // on active change, reset state
    React.useEffect(() => {
        setStage(0);
        setSelected([]);
        setNewRoutes([]);
        setVerification({ running: false });
        setSubmitStatus({ running: false });
    }, [active]);

    React.useEffect(() => {
        setNewRoutes(prev => updateNewRoutes(prev, selected));
    }, [selected]);

    const backClick = () => {
        if (submitStatus.error) {
            setSubmitStatus({ running: false });
        } else {
            setStage(i => i > 0 ? i - 1 : i);
            setDisplayIds([]);
        }
    };
    const nextClick = () => {
        setStage(i => i < 2 ? i + 1 : i);
        setDisplayIds([]);
    };
    const saveClick = () => {
        setSubmitStatus({ running: true });
        submit(session, profileId, verification.update).then(() => {
            onClose();
            onSave();
        }).catch(error => {
            setSubmitStatus({ running: false, error: 'Error submitting update!' });
        })
    };

    const backDisabled = (stage < 1) || submitStatus.running;
    const nextDisabled = (stage < 1 && selected.length < 1) || (stage < 2 && newRoutes.length < 1);
    const saveDisabled = shouldDisableSave(verification) || submitStatus.running || submitStatus.error;
    const nextTitle = getNextTitle(stage, nextDisabled);

    let element = null;
    let instructions = null;
    let mapDisplay = true;
    if (submitStatus.running) {
        instructions = "Submitting...";
        element = <Spinner animation="border" role="status" size="lg" className="m-4">
            <span className="visually-hidden">Loading...</span>
        </Spinner>;
        mapDisplay = false;
    } else if (submitStatus.error) {
        instructions = "Unable to submit update! Please go back and try again.";
        mapDisplay = false;
    } else if (stage < 1) {
        instructions = "Select base routes to use for adding routes to the profile. Each base route can be used one or more times on the next page.";
        element = <AddModalBaseSelect {...{sites, selected, setSelected, setDisplayIds}} />;
    } else if (stage === 1) {
        instructions = 'For the selected routes, you may update the endpoints for use in this profile.';
        element = <AddModalNewRoutes {...{sites, newRoutes, setNewRoutes, setDisplayIds}} />;
    } else {
        instructions = verification.running ? "Running verification" : "Review updates";
        element = <AddModalVerification {...{profileId, sites, newRoutes, verification, setVerification}} />
        mapDisplay = false;
    }

    return (<Modal dialogClassName='modal-xxl' animation={false} show={active} onHide={onClose}>
        <Modal.Header className="p-2" closeButton>
            <Modal.Title className="me-2">Add Routes</Modal.Title>
            <Button size="sm" variant='secondary' className="me-2" onClick={backClick} disabled={backDisabled}>Back</Button>
            { stage < 2 ? <span title={nextTitle}>
                <Button size="sm" variant='secondary' onClick={nextClick} disabled={nextDisabled}>Next</Button>
            </span> : <Button size="sm" variant="success" onClick={saveClick} disabled={saveDisabled}>Submit</Button> }
        </Modal.Header>
        <Modal.Body className="p-2">
            <p>{instructions}</p>
            {element}
            { mapDisplay ? <Map height="300px" geoJsonFeatures={geoJsonFeatures} loading={loadingMap} /> : null }
        </Modal.Body>
    </Modal>);
}

/**
 * 
 * @param {import('../../session-context').Session} session 
 * @param {number} profileId
 * @param {{ add: [], remove: [] }} update 
 */
async function submit(session, profileId, update) {
    // format update correctly. Currently everything is priority 1
    const data = { ...update };
    data.add = data.add.map(({ routeid, routefrom, routeto }) => ({
        routeid, facilityfrom: routefrom, facilityto: routeto, priority: 1,
    }));
    const resp = await session.postJson(`/api/v1/routes/profiles/${profileId}/routes`, data);
    if (resp.status > 399) {
        throw new Error("Invalid response from server " + resp.status);
    }
}

function shouldDisableSave(verification) {
    return verification.running || !(verification.update?.add?.length > 0) || verification.error
        || verification.status?.localDuplicates?.length > 0;
}

function getNextTitle(stage, isDisabled) {
    // get the title text for the next button based on stage and status
    if (stage < 1) {
        return isDisabled ? "Select routes to continue" : "Advance with selected routes";
    }
    return isDisabled ? "No routes specified" : "Advance to confirmation";
}

/**
 * 
 * @param {{ index: number, routeid: number, facilityfrom: number, facilityto: number, routefrom: number, routeto: number }[]} prev 
 * @param {{ routeid: number, facilityfrom: number, facilityto: number }[]} selected 
 * @returns 
 */
function updateNewRoutes(prev, selected) {
    // effect for when selection changes
    // remove routes no longer selected iff they haven't been modded
    let next = prev.filter(r => {
        return selected.find(s => s.routeid === r.routeid) || r.facilityfrom !== r.routefrom || r.facilityto !== r.routeto;
    });
    // add any new routes, assigning arbirary index
    let index = next.map(r => r.index).reduce((i1, i2) => Math.max(i1, i2), 0) + 1;
    selected.forEach(s => {
        if (!next.find(r => s.routeid === r.routeid)) {
            const { routeid, facilityfrom, facilityto } = s; 
            next.push({ index: index++, routeid, facilityfrom, facilityto, routefrom: facilityfrom, routeto: facilityto });
        }
    })

    return next;
}
