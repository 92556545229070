
import React from 'react';

import { useSessionContext } from '../session-context';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

import DownloadLink from '../util/DownloadLink';
import Table from '../util/Table';
import { compareVersions } from 'compare-versions';

const VERSION_REGEX = /\d+\.\d+\.\d+\.\d+/;

const RELEASE_END = "ReleaseNotes.pdf";
const OPERATIONAL_END = "OperationalNotes.pdf";

export default function Documents() {
    const session = useSessionContext();
    const [loading, setLoading] = React.useState(true);
    const [notes, setNotes] = React.useState({ release: [], operational: [] });
    const [docs, setDocs] = React.useState([]);

    React.useEffect(() => {
        setLoading(true);
        loadData(session).then(({ notes, docs }) => {
            setNotes(notes);
            setDocs(docs);
        }).catch(error => {
            console.log("Error loading dcouments!", error);
            setDocs([]);
        }).finally(() => setLoading(false));
    }, [session]);

    return (<Container className="text-center">
        <Row><Col><h3>Documents</h3></Col></Row>
        { loading ? <Spinner animation="border" role="status" /> : <DocumentList docs={docs} notes={notes} /> }
    </Container>);
}

/**
 * 
 * @param {object} options
 * @param {string[]} options.docs
 * @param {{ release: string[], operational: string[] }} options.notes
 * @returns 
 */
function DocumentList({ docs, notes }) {
    const columns = [
        { name: "", cell: row => <DownloadCell data={row.data} type={row.type} /> },
    ];
    const data = [];
    if (notes.release.length > 0) {
        data.push({ type: "Release Notes", data: notes.release })
    }
    if (notes.operational.length > 0) {
        data.push({ type: "Operational Notes", data: notes.operational })
    }
    docs.forEach(doc => data.push({ data: doc }));

    return (<Row><Col>
        <Table columns={columns} data={data} />
    </Col></Row>);
}

function DownloadCell({ data, type }) {
    if (typeof data === 'string') {
        return <Download file={data} />
    }
    return <DownloadSelector data={data} type={type} />;
}

function DownloadSelector({ data, type }) {
    const [selected, setSelected] = React.useState(data[0]);

    const linkClass = selected ? '' : 'disabled';
    return <div className='my-1 d-flex align-items-center' style={{ whiteSpace: 'nowrap' }}>
        {type}:
        <Form.Select size='sm' className='mx-2' value={selected} onChange={e => setSelected(e.target.value)}>
            {data.map(file => <option key={file} value={file}>{file}</option>)}
        </Form.Select>
        <DownloadLink href={'/downloads/documents/' + encodeURIComponent(selected)} className={linkClass}
            title={"Download " + selected} download={selected}>
                <FontAwesomeIcon icon={faDownload} size='lg' />
        </DownloadLink>
    </div>;
}

function Download({ file }) {
    return (<div className="py-1">
        <DownloadLink href={'/downloads/documents/' + encodeURIComponent(file)} title={"Download " + file} download={file}>
            {file}
        </DownloadLink>
    </div>);
}

async function loadData(session) {
    const { files } = await session.getJson('/downloads/documents');

    const docs = [], release = [], operational = [];
    if (Array.isArray(files)) {
        files.forEach(file => {
            if (file.endsWith(RELEASE_END)) {
                release.push(checkVersion(file));
            } else if (file.endsWith(OPERATIONAL_END)) {
                operational.push(checkVersion(file));
            } else {
                docs.push(file);
            }
        });
    }
    release.sort(compareFileVersions);
    operational.sort(compareFileVersions);
    const notes = { release: release.map(f => f.file), operational: operational.map(f => f.file) };

    return { docs, notes };
}

/**
 * @param {{file: string, version: string}} o1 
 * @param {{file: string, version: string}} o2 
 * @returns 
 */
function compareFileVersions(o1, o2) {
    let i = compareVersions(o2.version, o1.version);
    if (i === 0) {
        i = o2.file.localeCompare(o1.file);
    }
    return i;
}

function checkVersion(file) {
    const match = VERSION_REGEX.exec(file);
    const version = match?.[0] || '';
    return { file, version };
}
