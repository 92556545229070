
import Dropdown from "react-bootstrap/Dropdown";
import { DownloadDropdownItem } from "../../util/DownloadLink";

export function ActionDropdown({ session, scenario, reload, setToCompare }) {
    const actions = [
        { name: "Archive results", action: () => archiveResults(session, scenario, reload) },
        { name: "Delete results", action: () => deleteResults(session, scenario, reload) }
    ];
    if (scenario.hasDatabase) {
        actions.splice(0, 0, { name: "Delete database", action: () => deleteDb(session, scenario, reload) });
    }
    return (<Dropdown>
        <Dropdown.Toggle className="p-0 shadow-none" size="sm" variant="link" title="Click here to see other actions">
            Actions
        </Dropdown.Toggle>
        <Dropdown.Menu popperConfig={{ strategy: "fixed" }} renderOnMount={true}>
            <Dropdown.Item onClick={() => setToCompare(scenario)} >Compare to...</Dropdown.Item>
            <Dropdown.Divider />
            { actions.map(a => <Dropdown.Item key={a.name} onClick={a.action}>{a.name}</Dropdown.Item>)}
        </Dropdown.Menu>
    </Dropdown>);
}

function deleteDb(session, scenario, reload, force) {
    if (!force && !window.confirm("Are you sure you want to delete the database results for the " + scenario.scenario + " scenario?")) {
        return;
    }
    let url = '/results/database/' + encodeURIComponent(scenario.scenario) + '/' + scenario.resultDir;
    if (force) {
        url = url + "?force=true";
    }
    session.delete(url).then(() => reload()).catch(error => {
        if (error === "Conflict") { // response code 409
            if (!window.confirm("The result database appears to be open. Do you want to delete it anyway?")) {
                return;
            }
            deleteDb(session, scenario, reload, true);
        } else {
            console.log("Error deleting database for scenario", error);
            window.alert("Unable to delete result database!");
        }
    });

}

function archiveResults(session, scenario, reload) {
    if (!window.confirm("Are you sure you want to archive the results for the " + scenario.scenario + " scenario?")) {
        return;
    }
    session.delete('/results/archive/' + encodeURIComponent(scenario.scenario) + '/' + scenario.resultDir)
        .then(() => reload())
        .catch(error => {
            console.log("Error deleting results for scenario", error);
            window.alert("Unable to archive results!");
        });
}

function deleteResults(session, scenario, reload) {
    if (!window.confirm("Are you sure you want to delete the results for the " + scenario.scenario + " scenario?")) {
        return;
    }
    session.delete('/results/' + encodeURIComponent(scenario.scenario) + '/' + scenario.resultDir)
        .then(() => reload())
        .catch(error => {
            console.log("Error deleting results for scenario", error);
            window.alert("Unable to delete results!");
        });
}

export function SharedActionDropdown({ scenario, setToCompare }) {
    return (<Dropdown>
        <Dropdown.Toggle className="p-0 shadow-none" size="sm" variant="link" title="Click here to see other actions">
            Actions
        </Dropdown.Toggle>
        <Dropdown.Menu popperConfig={{ strategy: "fixed" }} renderOnMount={true}>
            <Dropdown.Item onClick={() => setToCompare(scenario)} >Compare to...</Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>);
}

export function DownloadDropdown({session, scenario}) {
    const actions = [
        fullZip(scenario),
        partialZip(scenario, "Calvin results", "calvin"),
        partialZip(scenario, "Custom results", "customReport"),
        inputZip(scenario)
    ];
    return (<Dropdown>
        <Dropdown.Toggle className="p-0 shadow-none" size="sm" variant="link" title="Click here to download zip files">
            Download
        </Dropdown.Toggle>
        <Dropdown.Menu popperConfig={{ strategy: "fixed" }} renderOnMount={true}>
            { actions.map(a => <DownloadDropdownItem key={a.name} href={a.href}>
                {a.name}
            </DownloadDropdownItem>) }
        </Dropdown.Menu>
    </Dropdown>);
}

function fullZip(scenario) {
    // download zip file
    let href = `/results/${encodeURIComponent(scenario.scenario)}/${scenario.resultDir}/zip`;
    if (scenario.user?.name) { // shared scenario
        href += "?shared_user=" + scenario.user.name;
    }
    return { name: "Full results", href };
}

function partialZip(scenario, name, type) {
    // download zip file
    let href = `/results/${encodeURIComponent(scenario.scenario)}/${scenario.resultDir}/${type}/zip`;
    if (scenario.user?.name) { // shared scenario
        href += "?shared_user=" + scenario.user.name;
    }
    return { name, href };
}

function inputZip(scenario) {
    let href = '/scenarios/' + encodeURIComponent(scenario.scenario) + "/" + scenario.resultDir;
    if (scenario.user?.name) { // shared scenario
        href += "?shared_user=" + scenario.user.name;
    }
    return { name: "Input scenario", href };
}
