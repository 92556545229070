
// filter operations
export const OpSymbol = {
    // TODO: support complex records
    // and: 'and',
    // or: 'or',

    eq: '=',
    ne: '!=',

    gt: '>',
    gte: '>=',
    lt: '<',
    lte: '<=',

    starts: 'starts with',
    ends: 'ends with',
    contains: 'contains',

};

let Op = Object.keys(OpSymbol).reduce((object, op) => { object[op] = op; return object }, {});
export { Op };

export function matches(value, operation, testVal) {
    if (value !== 0 && !value) {
        value = '';
    }
    if (testVal !== 0 && !testVal) {
        testVal = '';
    }
    switch(operation) {
        case Op.eq:
            // eslint-disable-next-line
            return value == testVal;
        case Op.ne:
            // eslint-disable-next-line
            return value != testVal;
        case Op.gt:
            return value > testVal;
        case Op.gte:
            return value >= testVal;
        case Op.lt:
            return value < testVal;
        case Op.lte:
            return value <= testVal;
        case Op.starts:
            return (value + "").startsWith(testVal + "");
        case Op.ends:
            return (value + "").endsWith(testVal + "");
        case Op.contains:
            return (value + "").indexOf(testVal + "") >= 0;
        default:
            return false;
    }
}
