
import React from 'react';
import { Buffer } from 'buffer';

const KEEP_ALIVE_LIMIT = 120; // minutes to keep a live with inactivity

export const SessionContext = React.createContext(null);

/**
 * Get the current session context
 * @returns {Session|null}
 */
export function useSessionContext() {
    return React.useContext(SessionContext);
}

export function buildFromSessionString(sessionString, clearSession, keepAliveLimit = KEEP_ALIVE_LIMIT) {
    if (!sessionString || typeof sessionString !== 'string') {
        throw new Error("Invalid session string!");
    }
    const split = sessionString.split(".");
    if (split.length !== 3) {
        throw new Error("Invalid session token!");
    }
    const userString = split[1];
    let userObj = null;
    try {
        userObj = JSON.parse(Buffer.from(userString, 'base64').toString()).user;
    } catch (error) {
        throw new Error("Invalid session user!");
    }
    if (!userObj) {
        throw new Error("Invalid user!");
    }
    return new Session(userObj, sessionString, clearSession, keepAliveLimit);
}

export class Session {
    constructor(currentUser, sessionString, clearSession, keepAliveLimit) {
        this.user = currentUser;
        this.sessionString = sessionString;
        this.clearSession = clearSession;
        this.keepAliveLimit = keepAliveLimit;
        this.keepAliveCount = 0;
    }

    clearKeepAliveCount() {
        this.keepAliveCount = 0;
    }

    keepAlive() {
        // only run so many keepAlives in a row
        this.keepAliveCount++;
        if (this.keepAliveCount > this.keepAliveLimit) {
            this.clearSession();
            return;
        }

        fetch('/authenticate/validate', baseOptions("POST", this.sessionString))
            .then(res => this.checkResponseAuth(res))
            .catch(error => console.log("Error running keepalive: " + error))
    }
    
    checkResponseAuth(res) {
        if (!res.ok) {
            console.log("Invalid response", res);
            if (res.status === 403 || res.status === 401) {
                this.clearSession();
            }
            // XXX: throw Error(message) causes issues with error boundaries?
            // even though we're catching down the line
            return Promise.reject(res?.statusText || "Unknown error");
        }
        return Promise.resolve(res);
    }

    /**
     * Get an endpoint
     * @param {string} url 
     * @returns {Promise<any>}
     */
    async getJson(url) {
        this.clearKeepAliveCount();
        return fetch(url, baseOptions("GET", this.sessionString))
            .then(res => this.checkResponseAuth(res)).then(res => res.json());
    }

    /**
     * Post an object to an endpoint
     * @param {string} url 
     * @param {any} data 
     * @returns {Promise<Response>}
     */
    async postJson(url, data) {
        this.clearKeepAliveCount();
        const options = baseOptions("POST", this.sessionString);
        if (data) {
            options.body = JSON.stringify(data);
            options.headers['Content-Type'] = 'application/json';
        }

        return fetch(url, options).then(res => this.checkResponseAuth(res));
    }

    /**
     * Post an object to an endpoint
     * @param {string} url 
     * @param {FormData} data 
     * @returns {Promise<Response>}
     */
    async postFormData(url, data) {
        this.clearKeepAliveCount();
        const options = baseOptions("POST", this.sessionString);
        options.body = data;
        const response = await fetch(url, options);
        return this.checkResponseAuth(response);
    }

    async delete(url) {
        this.clearKeepAliveCount();
        return fetch(url, baseOptions("DELETE", this.sessionString)).then(res => this.checkResponseAuth(res));
    }

    toString() {
        return "Session for: " + this.user.name;
    }

}

function baseOptions(method, sessionString) {
    return {
        method, headers: { Authorization: "Basic " + sessionString }
    };
}
