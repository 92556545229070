
import React from 'react';

/**
 * Create a resize observer.
 * The first element returned is the boundingClientRect of the node.
 * The second element reterned is the hook to pass to <ele ref={hook}></ele>.
 * @returns {[ DOMRect?, React.Dispatch<React.SetStateAction> ]}
 */
export default function useResizeObserver() {
    const [node, setNode] = React.useState(null);
    const [boundingRect, setBoundingRect] = React.useState(null);

    React.useEffect(() => {
        if (node) {
            // console.log("Creating observer");
            // on observation, update rect
            const ro = new ResizeObserver(() => setBoundingRect(node.getBoundingClientRect()));
            ro.observe(node);
            return () => ro.unobserve(node);
        }
    }, [node]);

    return [ boundingRect, setNode ];
}
