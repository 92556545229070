import React from "react";

import moment from 'moment';

import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faEye, faLock, faPenToSquare, faUsers } from '@fortawesome/free-solid-svg-icons';

import PaginatedTable, { Header } from "../Table/PaginatedTable";
import useFilter from "../../util/useFilter";
import useUsers from "../../util/useUsers";
import { DATE_FMT } from "../../util/scenarioFunctions";
import genericSort from "../../util/generic-sort";

export const COL_ID = "routeprofileid";

export default function ProfileTable({ profiles, actions }) {
    const [tableData, setTableData] = React.useState([]); // profiles sorted & filtered
    const [filter, updateFilter] = useFilter();
    const [sort, setSort] = React.useState(null);
    const [ users ] = useUsers({ includeInactive: true });

    React.useEffect(() => {
        setTableData(doSortAndFilter(profiles, filter, sort));
    }, [filter, sort, profiles]);

    const onSort = (column, direction) => {
        let order = null;
        if (column === "ownerid") {
            order = users.ids; // pre-sorted by user name
        }
        setSort(direction ? { column, direction, order } : null);
    };

    // build filter headings
    const columns = buildHeaders(sort, onSort, updateFilter, users, actions);

    const tableProps = {
        columns,
        fixedHeader: true,
        fixedHeaderScrollHeight: "500px",
    };

    return (<PaginatedTable data={tableData} tableProps={tableProps} />);
}

/**
 * @param {{ column: string, direction: string }?} sort
 * @param {function} onSort 
 * @param {function} onFilter 
 * @param {{ ids: [], data: {} }} users
 * @param {object} actions
 * @returns 
 */
function buildHeaders(sort, onSort, onFilter, users, actions) {
    
    const opts = { sortable: true, sort, onSort, onFilter };

    const idHead = <Header text="ID" column={COL_ID} {...opts} />;
    const nameHead = <Header text="Profile" column="name" {...opts} />;
    const creatorHead = <Header text="Created By" column="ownerid" {...opts}
        idList={users.ids} displayFn={id => users.data[id]?.name} />;
    const descHead = <Header text="Description" column="description" {...opts} />;
    const dateHead = <Header text="Created" column="createdate" {...opts} />;
    const lockHead = <Header text="Locked" column="locked" {...opts}
        idList={[true, false]} displayFn={locked => locked ? "LOCKED" : "UNLOCKED"} />;

    const columns = [
        { name: idHead, grow: 0.25, selector: row => row[COL_ID] },
        { name: nameHead, selector: row => row.name },
        { name: creatorHead, selector: row => row.ownerid, format: row => users.data[row.ownerid]?.name || "--" },
        { name: descHead, selector: row => row.description },
        { name: dateHead, selector: row => row.createdate, format: row => dateFormat(row.createdate) },
        { name: lockHead, selector: row => row.locked, format: row => row.locked ? "LOCKED" : "UNLOCKED" },
        { name: '', width: "48px", center: true, selector: row => row[COL_ID],
            format: row => <EditViewCol row={row} /> },
        { name: '', width: "48px", center: true, selector: row => row[COL_ID],
            format: row => <CloneCol row={row} onClone={actions.onClone} /> },
        { name: '', width: "48px", center: true, selector: row => row[COL_ID],
            format: row => <ShareCol row={row} onShare={actions.onShare} /> },
        { name: '', width: "48px", center: true, selector: row => row[COL_ID],
            format: row => <LockCol row={row} onLock={actions.onLock} /> },
    ];

    return columns;
}

function EditViewCol({ row }) {
    const canEdit = !row.locked && row.isAuthor;
    const linkPrefix = canEdit ? "Edit" : "View";
    const linkIcon = canEdit ? faPenToSquare : faEye;

    return <Link to={"./" + row[COL_ID]} title={`${linkPrefix} Routes`}>
        <FontAwesomeIcon icon={linkIcon} />
    </Link>
}

function CloneCol({ row, onClone }) {
    return <FontAwesomeIcon icon={faCopy} title="Copy profile" className="btn btn-sm btn-link" onClick={() => onClone(row)}/>;
}

function ShareCol({ row, onShare }) {
    if (!row.isCreator) {
        return null;
    }
    return <FontAwesomeIcon icon={faUsers} title="Share profile" className="btn btn-sm btn-link" onClick={() => onShare(row)}/>;
}

function LockCol({ row, onLock }) {
    if (!row.isCreator || row.locked) {
        return null;
    }
    return <FontAwesomeIcon icon={faLock} title="Lock profile" className="btn btn-sm btn-link" onClick={() => onLock(row)}/>;
}

function dateFormat(millis) {
    if (typeof millis === 'number') {
        return moment(millis).format(DATE_FMT);
    }
    return '--';
}

function doSortAndFilter(profiles, filter, sort) {
    const data = profiles.filter(profile => checkFilter(profile, filter));
    if (Array.isArray(sort?.order)) {
        const field = sort.column;
        sort = { ...sort, column: (row) => sort.order.indexOf(row[field]) };
    }
    return genericSort(data, sort);
}

function checkFilter(profile, filter) {
    return !Object.entries(filter).find(([field, value]) => {
        // TODO: string wildcard?
        return profile[field] !== value;
    })
}
