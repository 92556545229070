import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

import DataTable, { createTheme } from 'react-data-table-component';

createTheme('purple-striped', {
    divider: { default: "#000000" },
    striped: {
        default: 'rgb(205, 208, 249)',
        text: '#000000',
    }
}, 'light');

// table defaults that can be overridden
const paddingStyle = { paddingLeft: '0.5rem', paddingRight: '0.5rem' };

function getDefault(pagination) {
    const val = {
        dense: true,
        fixedHeaderScrollHeight: "600px",
        striped: true,
        theme: 'purple-striped',
        noDataComponent: "There are no records to display",
        progressComponent: <Spinner animation="border" role="status" />,
        customStyles: {
            headCells: {
                style: { fontWeight: 'bold', fontSize: '1rem', ...paddingStyle }
            },
            rows: { style: { fontSize: '1rem' }},
            cells: { style: { ...paddingStyle }}
        }
    };
    if (!pagination) {
        val.customStyles.rows.style.borderBottom = "1px solid black";
    }
    return val;
}

export default function Table(props) {
    // defaults
    const input = { ...getDefault(props.pagination), ...props };

    return <DataTable {...input} />;
}

export function ArrayTable({ header, content, sortable, tableProps }) {
    const columns = React.useMemo(() =>{
        return header.map((value, i) => {
            const props = { sortable, selector: row => row[i], sortFunction: optNumberSort(i) };
            if (typeof value === 'string') {
                return { name: value, ...props };
            }
            return { ...value, ...props };
        });
    }, [ header, sortable ]);

    return <Table {...tableProps} columns={columns} data={content} />;
}

function optNumberSort(index) {
    return (rowA, rowB) => {
        const a = rowA[index], b = rowB[index];
        if (typeof a === "number" && typeof b === "number") {
            if (a > b) {
                return 1;
            } else if (b > a) {
                return -1;
            } else {
                return 0;
            }
        }
        return (a + "").localeCompare(b + "");
    }
}
