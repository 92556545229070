
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import ActionIcon from '../../util/ActionIcon';

import { Action } from './dataReducer';

const CONTAINER_CLS = "d-flex justify-content-center justify-content-md-end";

export default function PaginationControl({ records, data, dataDispatch }) {
    const { pageSize, pageRecord } = data;
    if (records < pageSize) {
        return <div className={CONTAINER_CLS}>Showing {records > 0 ? 1 : 0} to {records} of {records}</div>
    }

    const setPageRecord = page => dataDispatch({type: Action.PAGE_RECORD, payload: page});

    const firstPage = () => setPageRecord(0);
    const prevPage = () => setPageRecord(pageRecord < pageSize ? 0 : pageRecord-pageSize);
    const nextPage = () => {
        if (pageRecord < (records - pageSize)) {
            setPageRecord(pageRecord + pageSize);
        }
    };
    const lastPage = () => {
        if (pageRecord < (records - pageSize)) {
            setPageRecord(records - pageSize);
        }
    };

    // use custom padding
    const actClassName = "px-2 py-0 shadow-none";
    const pageInput =
        <input type="number" min="1" max={records} value={pageRecord + 1}
            onChange={e => setPageRecord(Math.min(e.target.value, records) - 1)}></input>

    return (<div className={CONTAINER_CLS}>
        <ActionIcon className={actClassName} icon={faAngleDoubleLeft} title="First page" action={e => firstPage()} />
        <ActionIcon className={actClassName} icon={faAngleLeft} title="Previous page" action={e => prevPage()} />
        <span className="px-2">
            Showing {pageInput} to {Math.min(records, pageRecord + pageSize)} of {records}
        </span>
        <ActionIcon className={actClassName} icon={faAngleRight} title="Next page" action={e => nextPage()} />
        <ActionIcon className={actClassName} icon={faAngleDoubleRight} title="Last page" action={e => lastPage()} />
    </div>);
}
