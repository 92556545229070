
import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { faFilter, faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Op, OpSymbol } from './filter';
import { Action } from './dataReducer';

// TODO: for now, we use a simple AND on filters. Eventually we could build trees: and { columns, or { columns }}

export default function TableFilter({ data, dataDispatch, columns, refreshHeight }) {
    const [collapsed, setCollapsed] = React.useState(true);

    React.useEffect(() => refreshHeight(), [collapsed, data.filter, refreshHeight]);

    const { filter } = data;

    const filterString = getFilterString(filter, columns);

    return (<Card>
        <Card.Header className="py-1" onClick={() => setCollapsed(val => !val)} title="Click here to filter">
            <Button className="p-0 shadow-none" size="sm" variant="link">
                <FontAwesomeIcon icon={faFilter}/> {filterString || "Filter"}
            </Button>
        </Card.Header>
        <Card.Body className={(collapsed ? 'collapse' : '') + " py-2"}>
            <FilterBuilder filter={filter} columns={columns} dataDispatch={dataDispatch} />
        </Card.Body>
    </Card>);
}

function FilterBuilder({filter, columns, dataDispatch}) {
    const [newFilter, setNewFilter] = React.useState({ column: '', op: '', value: '' });
    const filterKeys = Object.keys(filter);
    const unusedCols = columns.filter(col => filterKeys.indexOf(col.name) < 0);

    const newFilterChange = (key, val) => setNewFilter(filter => ({...filter, [key]: val}));
    const operations = Object.keys(Op);
    const addFilter = () => {
        dataDispatch({
            type: Action.SET_FILTER,
            payload: { column: newFilter.column, filter: { ...newFilter } }
        });
        setNewFilter({ column: '', op: '', value: '' });
    };
    const newFilterValid = newFilter.column && newFilter.op;

    const existingRows = filterKeys.map(column =>
        <ExistingRow key={column} column={column} filter={filter[column]} operations={operations}
            columnObj={columns.find(col => col.name === column)} dataDispatch={dataDispatch} />);

    return (<Form onSubmit={e => e.preventDefault()}>
        {existingRows}
        <FilterRow {...newFilter} operations={operations} columns={unusedCols} updateFn={newFilterChange} >
            <RowAction icon={faPlusSquare} className={newFilterValid ? "text-success" : "text-muted"}
                enabled={newFilterValid} action={addFilter} title="Add filter" />
        </FilterRow>
    </Form>);
}

function ExistingRow({ column, columnObj, filter, operations, dataDispatch }) {
    const deleteAction = () => dataDispatch({ type: Action.SET_FILTER, payload: { column }});
    const updateFn = (key, val) => dataDispatch({ type: Action.SET_FILTER, payload: { column, filter: { [key]: val} }});
    return (<FilterRow {...filter} column={column} operations={operations}
        columns={[columnObj]} updateFn={updateFn} disableCol={true}>
        <RowAction icon={faMinusSquare} className="text-danger" enabled={true} action={deleteAction} title="Delete filter" />
    </FilterRow>);
}

function FilterRow({ column, op, value, operations, columns, updateFn, children, disableCol }) {
    const colObject = column ? columns.find(col => column === col.name) : null;

    return (<Row className="mb-1">
        <ColumnSelect value={column} columns={columns} onChange={e => updateFn('column', e.target.value)} disabled={disableCol} />
        <OpSelect value={op} operations={operations} onChange={e => updateFn('op', e.target.value)} />
        <ValueSelect value={value} column={colObject} onChange={e => updateFn('value', e.target.value)} />
        <Col xs="1" className="px-0">{children}</Col>
    </Row>);
}

function ColumnSelect({ value, onChange, columns, disabled }) {
    return (<Col xs='5' lg='3' className="px-2" title={value}>
        <Form.Select size="sm" value={value} onChange={onChange} className="w-100" disabled={disabled}>
            <option value="">Select column...</option>
            {columns.map(col => <option key={col.name}>{col.name}</option>)}
        </Form.Select>
    </Col>);
}

function OpSelect({ value, onChange, operations }) {
    return (<Col xs='2' className="px-2" title={value ? OpSymbol[value] : ""}>
        <Form.Select size="sm" value={value} onChange={onChange} className="w-100">
            <option value=""></option>
            {operations.map(op => <option key={op} value={op}>{OpSymbol[op]}</option>)}
        </Form.Select>
    </Col>);
}

function ValueSelect({ value, onChange, column }) {
    return (<Col xs='4' lg='3' className="px-2" title={value}>
        <Form.Control size="sm" disabled={!column} type={column ? column._input.type : "text"} value={value} onChange={onChange} />
    </Col>);
}

function RowAction({ icon, className, enabled, action, title }) {
    className = className + " p-0 shadow-none w-100 h-100 text-start";
    return (<Button className={className} size="sm" variant="link" title={title} disabled={!enabled} onClick={action}>
        <FontAwesomeIcon icon={icon} size="2x" />
    </Button>);
}

function getFilterString(filter, columns) {
    const filterColumns = Object.keys(filter);
    if (filterColumns.length < 1) {
        return "";
    }
    const filterStrings = filterColumns.map(columnName => {
        const column = columns.find(col => col.name === columnName);
        if (!column) {
            console.log("Invalid filter column: " + columnName);
            return null;
        }
        const { value, op } = filter[columnName];
        return columnName + " " + OpSymbol[op || Op.eq] + " '" + value + "'";
    });
    return filterStrings.filter(str => !!str).join(" AND ");
}
