
import React from 'react';

const CELL_STYLE_BASE = { position: "sticky", zIndex: "1", background: "white" };
const STICKY_CELL_STYLE = { ...CELL_STYLE_BASE, left: "0" };
const HEADER_STYLE = { ...CELL_STYLE_BASE, top: "0" };
const STICKY_HEADER_STYLE = { ...HEADER_STYLE, left: "0", zIndex: "2" };

// TODO: best way to add styling?

export default function StickyTable({ rowIds, data, columns, numSticky }) {
    const [offsets, setOffsets] = React.useState([]);
    // console.log(offsets);

    return <table className="sticky-table" style={{ whiteSpace: "nowrap", tableLayout: "fixed" }}>
        <thead>
            <tr>
                <StickyHeaders columns={columns.slice(0, numSticky)} {...{ offsets, setOffsets }} />
                <Headers columns={columns.slice(numSticky)} />
            </tr>
        </thead>
        <tbody>
            {rowIds.map(id => <tr key={id}>
                <StickyCells columns={columns.slice(0, numSticky)} rowId={id} row={data[id]} offsets={offsets} />
                {columns.slice(numSticky).map((col, idx) => <td key={idx}>{col.getValue(data[id], id)}</td>)}
            </tr>)}
        </tbody>
    </table>
}

StickyTable.defaultProps = {
    numSticky: 0,
};

function StickyHeaders({ columns, offsets, setOffsets }) {
    if (columns.length < 1) {
        return null;
    }

    return columns.map((column, index) => <StickyHeader key={index} {...{ index, column, offsets, setOffsets }} />);
}

function StickyHeader({ index, column, offsets, setOffsets }) {
    const [myWidth, setMyWidth] = React.useState(0);

    const refCb = React.useCallback(node => {
        if (node) {
            // console.log(node.getBoundingClientRect());
            setMyWidth(node.getBoundingClientRect().width);
        }
    }, []);

    React.useEffect(() => {
        setOffsets(old => {
            const update = [...old];
            update[index] = myWidth;
            return update;
        });
    }, [index, myWidth, setOffsets]);

    const offset = calcOffset(offsets, index);
    const left = (offset || 0) + "px";
    // console.log(offset, left);
    return <Header column={column} refProp={refCb} style={{ ...STICKY_HEADER_STYLE, left }} />;
}

function Headers({ columns }) {
    return columns.map((col, idx) => <Header key={idx} column={col} style={HEADER_STYLE} />);
}

function Header({ column, refProp, style }) {
    let content = column.name;
    if (typeof column.getHeader === 'function') {
        content = column.getHeader();
    }
    const { onClick, title } = column;
    const props = { style, onClick, title };
    if (refProp) {
        props.ref = refProp;
    }

    return <th {...props}>{content}</th>
}

function StickyCells({ columns, rowId, row, offsets }) {
    if (columns.length < 1) {
        return null;
    }

    return columns.map((col, idx) => <StickyCell key={idx} {...{col, row, rowId}} offset={calcOffset(offsets, idx)} />);
}

function StickyCell({ col, row, rowId, offset }) {
    const left = (offset || 0) + "px";
    const style = { ...STICKY_CELL_STYLE, left };
    if (col.centered) {
        style.textAlign = "center";
    }
    return <th style={style}>{col.getValue(row, rowId)}</th>;
}

/**
 * 
 * @param {number[]} offsets 
 * @param {number} index 
 * @returns 
 */
function calcOffset(offsets, index) {
    if (index < 1) {
        return 0;
    }
    const result = offsets.slice(0, index).reduce((a, b) => a + b, 0);
    // console.log(offsets, index, result);
    return result;
}
