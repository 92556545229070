
import moment from 'moment';

export const DATE_FMT = "YYYY-MM-DD HH:mm";

const OLD_NGSAM_FMT = "YYYYMMDD_HHmm";

export function resultDirToMoment(resultDir) {
    // value should be milliseconds
    const millis = Number(resultDir);
    if (!Number.isNaN(millis)) {
        return moment(millis);
    } // else it might be in the old format
    const date = moment(resultDir, OLD_NGSAM_FMT);
    if (date.isValid()) {
        return date
    }
    console.log("Unknown result dir format:", resultDir);
    return undefined;
}
