
import React from 'react';

import { useSessionContext } from '../../session-context';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import useUsers from '../../util/useUsers';

export default function ShareModal({profile, setProfile}) {
    const session = useSessionContext();
    const [sharing, setSharing] = React.useState({});
    const [ users ] = useUsers({ excludeSelf: true });

    React.useEffect(() => {
        if (!profile) {
            setSharing({});
            return;
        }
        // else, get current sharing
        session.getJson("/user-edits/profiles/" + profile.id + "/sharing").then(r => {
            const sharing = r.data.map(s => s.username);
            const userList = Object.values(users.data).map(u => u.name);
            if (userList.length < 1 || !sharing) {
                throw new Error("Unable to load users/sharing for scenario!");
            }
            const newState = {};
            userList.forEach(u => newState[u] = (sharing.indexOf(u) >=0));
            setSharing(newState);
        }).catch(error => {
            console.log("error loading sharing options", error);
        });
    }, [session, users, profile]); // refresh every profile change
    
    return (<Modal animation={false} show={!!profile} onHide={() => setProfile(null)}>
        <Modal.Header closeButton>
            <Modal.Title>Manage profile sharing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <ShareBody session={session} sharing={sharing} setSharing={setSharing} profile={profile} setProfile={setProfile} />
        </Modal.Body>
    </Modal>);
}

function ShareBody({session, sharing, setSharing, profile, setProfile}) {
    return (<>
        <p className="mb-0"><strong>Profile: </strong>{profile.profileName}</p>
        <p><strong>Data Version: </strong>{(profile.schema || {}).name}</p>
        <Form onSubmit={e => doSubmit(e, session, profile, sharing, setProfile)}>
            <UserCheckboxes state={sharing} setState={setSharing} />
            
            <Button variant="primary" type="submit">Save</Button>
            <Button className="ms-2" variant="danger" type="button" onClick={() => setProfile(null)}>Cancel</Button>
        </Form>
    </>);
}

function UserCheckboxes({state, setState}) {

    const handleUserCheck = (e, name) => {
        const isChecked = e.target.checked;
        setState(previous => {
            const newValue = {...previous};
            newValue[name] = isChecked;
            return newValue;
        });
    };

    return (<>
        {Object.keys(state).sort().map(name => (<Form.Group className="mb-3" key={name} controlId={name + "-check"}>
            <Form.Check type="checkbox" label={name} checked={state[name] ? true : false}
                onChange={e => handleUserCheck(e, name)}/>
        </Form.Group>))}
    </>);
}

function doSubmit(e, session, profile, userState, setProfile) {
    e.preventDefault();
    const users = []
    Object.keys(userState).forEach(name => {
        if (userState[name]) {
            users.push(name);
        }
    });
    // save
    session.postJson("/user-edits/profiles/" + profile.id + "/sharing", { users })
        .then(() => setProfile(null))
        .catch(error => console.log("Error updating sharing", error));
}
