
import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';

import { readString } from 'react-papaparse';
import { getPrimaryKeyFor, parseAddedRecord } from './data';
import { Action, getRowValue } from './dataReducer';
import {
    convertDateTimeInputToMysql,
    convertDateTimeMysqlToInput,
    convertDateInputToMysql,
    convertDateMysqlToInput,
    INPUT_DATETIME_FORMAT,
    INPUT_DATE_FORMAT,
    MYSQL_FORMAT
} from './dateConvert';

import exportEditsToCsv, { DELETED_COL } from './exportEditsToCsv';

export default function CsvImportForm({ data, dataDispatch, table, columns, setModalMessage }) {
    const [file, setFile] = React.useState(null);
    const [parsed, setParsed] = React.useState(null);
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        setParsed(null);
        setError(null);
        if (file) {
            // process the file async
            parseFile(file)
                .then(parseResult => preprocess(parseResult, data, table, columns)).then(setParsed)
                .catch(setError);
        }
    }, [data, table, columns, file]);

    const apply = () => {
        // build the payload and dispatch
        const changes = {
            keysDeleted: parsed.keysDeleted,
            rowsAdded: parsed.keysAdded.map(key => parseAddedRecord(table, columns, key, parsed.rows[key])),
            rowEdits: parsed.rowEdits
        };
        dataDispatch({ type: Action.APPLY_BATCH, payload: changes });
        setModalMessage({});
    };

    return (<>
        <p>Choose a CSV file to import. The CSV must have column names that match the table columns.</p>
        <p><Button type="button" variant="link" title="Download template" className="p-0 shadow-none"
            onClick={e => exportEditsToCsv(table.name + "-template.csv", columns, {})}>
            Click here to download a template for import
        </Button></p>
        <div className="d-flex justify-content-between mb-3">
            <input type="file" accept="text/csv,.csv" onChange={e => setFile(e.target.files[0])} />
            { parsed && parsed.hasChanges ? <Button type="button" variant="success" onClick={apply}>Apply</Button> : null}
        </div>
        <ChangeDisplay file={file} error={error} parsed={parsed} columns={columns} />
    </>);
}

function ChangeDisplay({ file, error, parsed, columns }) {
    if (!file) {
        return null;
    }
    if (error) {
        return <p>{error.message || error}</p>;
    } 
    if (!parsed) {
        return <p>Loading...</p>;
    }
    if (!parsed.hasChanges && parsed.errors.length < 1) {
        return <p>No changes detected.</p>;
    }
    // build each card for the accordion
    const cards = [];
    var count = parsed.errors.length;
    if (count > 0) {
        cards.push({ key: count > 1 ? "errors" : "error", items: parsed.errors });
    }
    count = parsed.keysAdded.length;
    if (count > 0) {
        cards.push({
            key: count > 1 ? "rows added" : "row added",
            items: parsed.keysAdded.map(key => rowString(parsed.rows[key], columns))
        });
    }

    const rowEditKeys = Object.keys(parsed.rowEdits);
    count = rowEditKeys.length;
    if (count > 0) {
        cards.push({
            key: count > 1 ? "rows edited" : "row edited",
            items: rowEditKeys.map(key => {
                const changedCols = Object.keys(parsed.rowEdits[key]);
                const cols = columns.filter(col => col._isPrimaryKey || changedCols.indexOf(col.name) >= 0);
                return rowString(parsed.rows[key], cols);
            })
        });
    }
    count = parsed.keysDeleted.length;
    if (count > 0) {
        cards.push({
            key: count > 1 ? "rows deleted" : "row deleted",
            items: parsed.keysDeleted.map(key => rowString(parsed.rows[key], columns.filter(col => col._isPrimaryKey)))
        });
    }

    return (<>
        <p>File details (click to expand):</p>
        <Accordion alwaysOpen={true}>
            { cards.map((card, idx) => <Accordion.Item key={card.key} eventKey={"" + idx}>
                <Accordion.Header>{card.items.length} {card.key}</Accordion.Header>
                <Accordion.Body>
                    <ul>{card.items.map((val, idx) => <li key={idx}>{val}</li>)}</ul>
                </Accordion.Body>
            </Accordion.Item>) }
        </Accordion>
    </>);
}

function rowString(row, columns) {
    return columns.map(col => col.name + ":\u00A0" + row[col.name]).join(', ');
}

function parseFile(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('error', e => {
            console.log(e);
            reject("Error reading file!");
        });
        reader.addEventListener('load', e => resolve(e.target.result));
        reader.readAsText(file);
    }).then(parseLines);
}

function parseLines(contents) {
    if (!contents || !(contents = contents.trim())) {
        throw new Error("File is empty!");
    }
    // just give us array of arrays (header false)
    return readString(contents, { header: false });
}

// analyze what the file contains and what it will change about data (without making changes!)
function preprocess(parseResult, data, table, columns) {
    const results = {
        errors: parseResult.errors.map(err => "Line " + err.row + ": " + err.message),
        hasChanges: false,
        rows: {},
        keysAdded: [],
        keysDeleted: [],
        rowEdits: {},
    };

    const lines = parseResult.data;
    if (!lines || lines.length < 2) {
        if (results.errors.length < 1) { // no lines, but no errors? No good.
            throw new Error("No data parsed!");
        }
        return results; // nothing to parse, return with error array filled
    }
    const [rows, errors] = rowsToObjects(lines, columns, table._primaryKey);
    if (errors && errors.length > 0) {
        results.errors = results.errors.concat(errors);
    }
    results.rows = rows;
    countChanges(results, data, columns);
    return results;
}

function rowsToObjects(lines, columns, primaryKey) {
    const headerLine = lines[0];
    // create array of { column name, index, type }
    const columnData = [];
    const missingCols = [];
    columns.forEach(column => {
        const index = headerLine.findIndex(ele => matchIgnoreCase(column.name, (ele ||"").trim()));
        if (index < 0) {
            missingCols.push(column.name);
        } else {
            columnData.push({ name: column.name, index, type: column._input.type });
        }
    });
    if (missingCols.length > 0) {
        return [ {}, ["Missing column(s): " + missingCols.join(", ")] ];
    }
    // optionally included 'deleted?' column to indicate record deletion - may be -1
    const deletedColIndex = headerLine.findIndex(ele => matchIgnoreCase(DELETED_COL, (ele ||"").trim()));

    // iterate through the remaining lines, parsing rows and storing by primary key value
    const rows = {};
    let rowErrors = [];
    for (var i = 1; i < lines.length; ++i) {
        const { row, errors } = getRow(lines[i], columnData);
        if (errors && errors.length > 0) {
            // eslint-disable-next-line
            rowErrors = rowErrors.concat(errors.map(err => ("Line " + i + ": " + err)));
            continue;
        }
        const pkVal = getPrimaryKeyFor(row, primaryKey);
        if (rows[pkVal]) {
            rowErrors.push("Line " + i + ": duplicate primary key: " + pkVal);
            continue;
        }
        
        if (deletedColIndex >= 0 && deletedColIndex < lines[i].length && matchIgnoreCase(lines[i][deletedColIndex], "true")) {
            row._deleted = true;
        }
        rows[pkVal] = row;
    }

    return [ rows, rowErrors ];
}

/**
 * 
 * @param {string[]} line 
 * @param {object} columnData 
 * @returns {{ row: object, errors: string[] }}
 */
function getRow(line, columnData) {
    const errors = [];
    const row = {};
    // use for loop so we can bail early
    for (var i = 0; i < columnData.length; ++i) {
        const {name, index, type} = columnData[i];
        if (line.length <= index) {
            return [ {}, [ "Only " + line.length + " columns found!" ] ];
        }
        // accumulate errors about typing
        try {
            row[name] = parseRowValue(line[index], type);
        } catch (err) {
            errors.push(err.message);
        }
    }

    return { row, errors };
}

/**
 * 
 * @param {string} val 
 * @param {string} type 
 * @returns 
 */
function parseRowValue(val, type) {
    if (val === "null" || val === undefined || val === null) {
        return null;
    }
    switch (type) {
        case 'checkbox':
            if (matchIgnoreCase(val, "true")) {
                return true;
            } else if (matchIgnoreCase(val, "false")) {
                return false;
            }
            throw new Error("Cannot convert '" + val + "' to a boolean!");
        case 'number':
            const num = parseFloat(val);
            if (isNaN(num) || num + "" !== val) {
                throw new Error("Cannot convert '" + val + "' to a number!");
            }
            return num;
        case 'datetime-local':
            try {
                return convertDateTimeInputToMysql(val, true); // it worked, and this is the format we want
            } catch (err) {} // silent fail, try the other format
            try {
                const inputVal = convertDateTimeMysqlToInput(val, true);
                // it worked, but we want the mysql format
                return convertDateTimeInputToMysql(inputVal);
            } catch (err) {}
            throw new Error("Cannot parse '" + val + "' as a date! Valid formats: "
                + [MYSQL_FORMAT, INPUT_DATETIME_FORMAT].map(fmt => "'" + fmt + "'").join(', '));
        case 'date':
            try {
                return convertDateInputToMysql(val, true); // it worked, and this is the format we want
            } catch (err) {} // silent fail, try the other format
            try {
                const inputVal = convertDateMysqlToInput(val, true);
                // it worked, but we want the mysql format
                return convertDateInputToMysql(inputVal);
            } catch (err) {}
            throw new Error("Cannot parse '" + val + "' as a date! Valid formats: "
                + [MYSQL_FORMAT, INPUT_DATE_FORMAT].map(fmt => "'" + fmt + "'").join(', '));
        case 'text':
            return val;
        default: break;

    }
    console.log("Unknown column type!", type, val);
    return val;
}

// match strings case insensitive
function matchIgnoreCase(string1, string2) {
    return string1.localeCompare(string2, undefined, { sensitivity: "base" }) === 0;
}

function countChanges(results, data, columns) {
    const newPks = Object.keys(results.rows);
    if (newPks.length < 1) {
        return; // no changes
    }
    newPks.forEach(key => {
        const newRow = results.rows[key];
        const oldRow = data.rows[key];
        // check for deleted and added
        if (newRow._deleted) {
            if (oldRow && !oldRow._deleted) {
                results.keysDeleted.push(key);
                results.hasChanges = true;
            }
            return;
        }
        if (!oldRow) {
            results.keysAdded.push(key);
            results.hasChanges = true;
            return;
        }
        // else check for edits
        const edits = {};
        let hasEdits = false;
        columns.forEach(col => {
            if (newRow[col.name] !== getRowValue(oldRow, col.name)) {
                edits[col.name] = newRow[col.name];
                hasEdits = true;
            }
        });
        if (hasEdits) {
            results.rowEdits[key] = edits;
            results.hasChanges = true;
        }
    });
}
