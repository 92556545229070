
/**
 * @param {object} queueResponse
 * @param {object[]} queueResponse.scenarios
 * @param {object[]} queueResponse.statusList
 * @param {{node: number, slots: number}[]} nodes
 */
export default function parseData({ scenarios, statusList }, nodes) {
    // parse the data into the structure we want
    const queue = convertScenarios(scenarios, statusList);
    queue.running = mapRunningNodes(queue.running || [], nodes);
    queue.queued = (queue.queued || []).concat(queue.new || []);
    if (queue.canceled && queue.canceled.length > 0) {
        queue.canceled.forEach(scen => scen.notes = "Canceled");
        queue.error = (queue.error || []).concat(queue.canceled);
    }

    return queue;
}

/**
 * @param {any[]} scenarios 
 * @param {{node: number, slots: number}[]} nodes
 * @returns 
 */
function mapRunningNodes(scenarios, nodes) {
    // just in case server_id is invalid
    const nodeScenarios = [], noNodeScenarios = [];
    scenarios.forEach(scenario => {
        if (typeof scenario.server_id === 'number') {
            nodeScenarios.push(scenario);
        } else {
            noNodeScenarios.push(scenario);
        }
    });
    nodes.forEach(node => {
        const runCount = nodeScenarios.filter(s => s.server_id === node.node).length;
        for (let i = runCount; i < node.slots; ++i) {
            nodeScenarios.push({ server_id: node.node });
        }
    });

    nodeScenarios.sort((s1, s2) => s1.server_id - s2.server_id);

    return nodeScenarios.concat(noNodeScenarios);
}

/**
 * 
 * @param {object[]} scenarios 
 * @param {object[]} statusList 
 * @returns 
 */
function convertScenarios(scenarios, statusList) {
    // group by status
    const statusMap = {};
    statusList.forEach(({ id, status_text }) => statusMap[id] = status_text);
    // also trim the 'directory' column to just the last name
    scenarios.forEach(scenario => {
        if (scenario.directory) {
            scenario.directory_trimmed = trimDirectory(scenario.directory);
        }
        scenario.username = scenario.user?.name;
        const status = statusMap[scenario.status_id];
        if (!status) {
            console.log("Unknown status for:", scenario.status_id, scenario);
        } else {
            scenario.status = status;
        }
    });
    return scenarios.reduce((obj, ele) => {
        if (!obj[ele.status]) {
            obj[ele.status] = [];
        }
        obj[ele.status].push(ele);
        return obj;
    }, {});
}

function trimDirectory(dirName) {
    if (dirName.endsWith('/')) {
        dirName = dirName.slice(0, -1);
    }
    var idx = dirName.lastIndexOf('/');
    if (idx >= 0) {
        return dirName.substring(idx + 1);
    }
    return dirName;
}
