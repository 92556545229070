
import { 
    convertDateTimeInputToMysql, convertDateInputToMysql,
    convertDateMysqlToInput, convertDateTimeMysqlToInput
} from './dateConvert';

export default function ColumnInput({ column, value, onChange, className, disabled }) {
    const inputProps = { ...column._input, className, disabled };
    if (inputProps.type === "checkbox") {
        inputProps.checked = value === 1;
        // return 1 for true, 0 for false
        inputProps.onChange = e => onChange(e.target.checked ? 1 : 0);
    // step == 1 is not enough to prevent decimals - use parseInt
    } else if (inputProps.type === "number" && inputProps.step === 1) {
        inputProps.value = value;
        // input comes through as text - parse to float value or null
        inputProps.onChange = e => onChange(e.target.value ? parseInt(e.target.value, 10) : null);
    } else if (inputProps.type === "number") {
        inputProps.value = value;
        // input comes through as text - parse to float value or null
        inputProps.onChange = e => onChange(e.target.value ? parseFloat(e.target.value) : null);
    } else if (inputProps.type === "datetime-local") {
        inputProps.value = value;
        inputProps.onChange = e => onChange(convertDateTimeInputToMysql(e.target.value, true));
    } else if (inputProps.type === "date") {
        inputProps.value = value;
        inputProps.onChange = e => onChange(convertDateInputToMysql(e.target.value, true));
    } else { // string
        inputProps.value = value;
        // treat both null and '' as null
        inputProps.onChange = e => onChange(e.target.value || null);
    }
    return <input {...inputProps} />;
}

export function getCellValue(data, column) {
    const editedColName = "_u_" + column.name;
    let value = nullsToBlank(data[column.name]);
    let edited = false;
    if (Object.keys(data).indexOf(editedColName) >= 0 && value !== data[editedColName]) {
        value = nullsToBlank(data[editedColName]);
        edited = true;
    }
    if (column._input.type === "datetime-local") {
        try {
            value = nullsToBlank(convertDateTimeMysqlToInput(value, true));
        } catch (error) {
            console.log(error);
            value = '';
        }
    } else if (column._input.type === "date") {
        try {
            value = nullsToBlank(convertDateMysqlToInput(value, true));
        } catch (error) {
            console.log(error);
            value = '';
        }
    }

    return [value, edited];
}

// react does not play well with null values, use blanks instead
export function nullsToBlank(value) {
    if (value === null || value === undefined){
        return '';
    }
    return value;
}
