
import React from 'react';
import { useSessionContext } from '../session-context';

// from routes provider
const FIPS_PATH = "/api/v1/routes/fips";

/**
 * Fips context, loaded from routes manager - requires session
 * @returns
 */
export default function useFips(refresh) {
    const session = useSessionContext();
    const [fips, setFips] = React.useState({ ids: [], data: {} });
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        setError(null);
        loadData(session).then(setFips).catch(error => {
            console.log(error);
            setFips({ ids: [], data: {} });
            setError(error);
        });
    }, [session, refresh]);

    return [ fips, error ];
}

async function loadData(session) {
    const rows = await session.getJson(FIPS_PATH);
    // sort ids by name
    const ids = rows.sort(nameCompare).map(f => f.fips);
    const data = {};
    rows.forEach(f => data[f.fips] = f);
    return { ids, data };
}

/**
 * @param {{ fips: string }} u1 
 * @param {{ fips: string }} u2 
 * @returns 
 */
function nameCompare(u1, u2) {
    return u1.fips.localeCompare(u2.fips, undefined, { sensitivity: 'base' });
}
