
import { faX } from '@fortawesome/free-solid-svg-icons';
import ActionIcon from '../../util/ActionIcon';
import Table from '../../util/Table';

// base columns (no session needed)
const BASE_COLUMNS = [
    { name: "User", sortable: true, selector: row => row.user, wrap: true },
    { name: "Scenario", sortable: true, selector: row => row.name, wrap: true },
    { name: "Run Date", sortable: true, selector: row => row.targetFormat, wrap: true },
];

export default function ScenarioTable({ scenarios, onDelete }) {

    const columns = BASE_COLUMNS.slice();
    columns.push({ name: "Action", ignoreRowClick: true, grow: 0.5, selector: row => row.target, center: true,
        format: row => <ActionIcon className="text-danger" icon={faX} action={() => onDelete(row)} /> });

    return <Table columns={columns} data={scenarios} pagination />
}
