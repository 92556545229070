
import { useSessionContext } from '../../session-context';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

export default function ThermalCell({pkg, setModalInfo}) {
    const session = useSessionContext();
    const load = () => {
        session.getJson("/database/user/thermallimits/" + pkg.id).then(({limits}) => {
            setModalInfo({
                title: "Thermal maps for " + pkg.model_id,
                body: <LimitBody limits={limits}/>
            });
        }).catch(error => {
            console.log(error);
            setModalInfo({
                title: "Error loading maps for " + pkg.model_id,
                body: "If this error persists, please contact your administrator."
            });
        });
    };
    return (<Button className="p-0 shadow-none" style={{textDecoration: "none"}} variant="link" type="button" size="sm"
        onClick={() => load()}>View</Button>);
}

function LimitBody({limits}) {
    if (!limits || limits.length < 1) {
        return <p>No limits found</p>;
    }
    const patternMap = parseLimits(limits);
    
    return (<div className="p-1">
        {Object.keys(patternMap).map(key => <LimitTable key={key} pattern={patternMap[key]}/>)}
    </div>);
}

function LimitTable({pattern}) {
    return (<>
        <p className="mb-0"><strong>Pattern: {pattern.pattern} ({pattern.type})</strong></p>
        <Row className="border-bottom"><Col xs='2'>Limit</Col><Col>Slots</Col></Row>
        {Object.keys(pattern.zones).map((key, index) => <Row key={index} className="border-bottom">
            <Col xs='2'>{pattern.zones[key].limit}</Col>
            <Col>{getRanges(pattern.zones[key].slots).join(', ')}</Col>
        </Row>)}
    </>);
}

function parseLimits(limits) {
    const patternMap = {};
    limits.forEach(limit => {
        const key = limit.pattern + "-" + limit.type;
        const pattern = patternMap[key] || (patternMap[key] = {
            pattern: limit.pattern,
            type: limit.type,
            zones: {},
        });
        var zone = pattern.zones[limit.limit] || (pattern.zones[limit.limit] = {
            limit: limit.limit,
            slots: [],
        });
        zone.slots.push(limit.position);
    });
    return patternMap;
}

// https://stackoverflow.com/a/2270987/6322589
// convert [1, 2, 3, 5, 6, 7] into [1-3, 5-7], etc
function getRanges(array) {
    array.sort(function (i1, i2) { return i1 - i2; });
    var ranges = [], rstart, rend;
    for (var i = 0; i < array.length; i++) {
        rstart = array[i];
        rend = rstart;
        while (array[i + 1] - array[i] === 1) {
            rend = array[i + 1]; // increment the index if the numbers sequential
            i++;
        }
        ranges.push(rstart === rend ? rstart + '' : rstart + '-' + rend);
    }
    return ranges;
}