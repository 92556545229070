
import React from 'react';
import { useSessionContext } from '../session-context';

/**
 * User context - requires session
 * @param {{ includeInactive: boolean?, excludeSelf: boolean? }} parameters
 * @returns
 */
export default function useUsers(parameters, refresh) {
    const session = useSessionContext();
    const [users, setUsers] = React.useState({ ids: [], data: {} });
    const [error, setError] = React.useState(null);

    const includeInactive = parameters?.includeInactive || false;
    const excludeSelf = parameters.excludeSelf || false;

    React.useEffect(() => {
        setError(null);
        session.getJson('/authenticate/user').then(userList => {
            if (!includeInactive) {
                userList = userList.filter(u => u.active);
            }
            if (excludeSelf) {
                userList = userList.filter(u => u.id !== session.user.id);
            }
            // sort ids by name
            const ids = userList.sort(userNameCompare).map(u => u.id);
            const data = {};
            userList.forEach(user => data[user.id] = user);
            setUsers({ ids, data });
        }).catch(error => {
            console.log(error);
            setUsers({ ids: [], data: {} });
            setError(error);
        });
    }, [session, includeInactive, excludeSelf, refresh]);

    return [ users, error ];
}

/**
 * @param {{ name: string }} u1 
 * @param {{ name: string }} u2 
 * @returns 
 */
function userNameCompare(u1, u2) {
    return u1.name.localeCompare(u2.name, undefined, { sensitivity: 'base' });
}
