
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";

export default function ActionIcon({icon, title, action, size, className, disabled}) {
    className = className || "p-0 shadow-none";
    size = size || 'sm';
    return (<Button className={className} variant='link' size={size} type="button" title={title}
        onClick={action} disabled={disabled}>
            <FontAwesomeIcon icon={icon} size="lg"></FontAwesomeIcon>
    </Button>)
}
