
import React from 'react';

import { useSessionContext } from '../../session-context';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

import { useParams, Link } from 'react-router-dom';

import { readString } from 'react-papaparse';

import { parseComparison } from '.';

import { ArrayTable } from '../../util/Table';

import { DATE_FMT } from '../../util/scenarioFunctions';

const FAILED = "FAILED", COMPLETE = "COMPLETE";

export default function ComparisonDetail() {
    const session = useSessionContext();
    // object id comes from the url
    const { id } = useParams();
    const [comparison, setComparison] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        // load until we hit complete or failure
        let interval = null;
        const load = () => {
            setLoading(true);
            session.getJson(`/results/compare/${id}`).then(c => {
                setComparison(parseData(c));
                if (c.state === FAILED || c.state === COMPLETE) {
                    clearInterval(interval);
                }
            }).catch(error => {
                console.log(error);
                setComparison(null);
                clearInterval(interval);
            }).finally(() => setLoading(false));
        };
        load();
        interval = setInterval(load, 5000);
        return () => clearInterval(interval);
    }, [session, id]);

    let content;
    if (loading) {
        content = <Row className="text-center"><Col><Spinner animation="border" role="status" /></Col></Row>;
    } else if (comparison) {
        content = <>
            <ComparisonHeader comparison={comparison} />
            <ComparisonData comparison={comparison} />
        </>;
    } else {
        content = <>
            <p className='fw-bold'>Unable to load comparison!</p>
            <Link to="./..">Return to comparison table</Link>
        </>;
    }
    return <div className="px-3 mb-5">
        <Container fluid className="text-center">
            { content }
        </Container>
    </div>;
}

function ComparisonHeader({ comparison }) {

    return <>
        <Row>
            <Col xs={{ span: 12, order: 'last' }} lg={{ span: 3, order: 'first' }} className="text-lg-start">
                <Link to="./..">&lt;- All comparisons</Link>
            </Col>
            <Col xs="12" lg="6"><h3>Run comparison</h3></Col>
        </Row>
        <Row className="mb-3 d-none d-md-block">
            <Col xs="12">
                <div className="d-inline-block text-end me-2">
                    <p className='fw-bold mb-0'>Base Scenario: </p>
                    <p className='fw-bold mb-0'>Target Scenario: </p>
                </div>
                <div className="d-inline-block text-start">
                    <p className="mb-0">
                        {comparison.baseScenario} run on {comparison.baseRun.format(DATE_FMT)} by {comparison.baseUser}
                    </p>
                    <p className="mb-0">
                        {comparison.targetScenario} run on {comparison.targetRun.format(DATE_FMT)} by {comparison.targetUser}
                    </p>
                </div>
            </Col>
        </Row>
        <Row className="mb-2 d-md-none">
            <Col xs="12">
                <p className='fw-bold mb-0'>Base Scenario:</p>
                <p className="mb-0">
                    {comparison.baseScenario} run on {comparison.baseRun.format(DATE_FMT)} by {comparison.baseUser}
                </p>
                <p className='fw-bold mb-0'>Target Scenario:</p>
                <p className="mb-0">
                    {comparison.targetScenario} run on {comparison.targetRun.format(DATE_FMT)} by {comparison.targetUser}
                </p>
            </Col>
        </Row>
    </>;
}

function ComparisonData({ comparison }) {
    if (comparison.state === FAILED || (comparison.state === COMPLETE && !Array.isArray(comparison.results))){
        return <Row><Col>
            <p>
                Comparison failed. Please verify the results you are trying to compare and contact your
                administrator if this issue persists.
            </p>
            <Link to="./..">Return to comparison table</Link>
        </Col></Row>;
    } else if (comparison.state !== COMPLETE) {
        return <Row><Col className="d-flex align-items-center justify-content-center">
            <Spinner animation="border" role="status" /> <span className="ms-2">Running Comparison</span>
        </Col></Row>;
    }
    return <ResultSelection results={comparison.results} />
}

function ResultSelection({ results }) {
    const [index, setIndex] = React.useState(0);

    return <>
        <Row className="text-start"><Col>
            <p className='fw-bold mb-1'>Choose a file:</p>
            <Form.Select className="mb-3" value={index} onChange={e => setIndex(parseInt(e.target.value, 10))}>
                {results.map((r, i) => <option key={i} value={i}>{r.file} - {fileSummary(r)}</option>)}
            </Form.Select>
        </Col></Row>
        <Row className="text-start"><Col>
            {/* Include a key here so that we're drawing new tables and not retaining sorts, etc. */}
            <ResultItem key={index} result={results[index]} />
        </Col></Row>
    </>
}

function ResultItem({ result }) {
    // some results are just string messages
    let message = "";
    if (result.error || typeof result.result === "string") {
        message = result.error || result.result;
    } else if (noDifferences(result.result)) {
        message = "Files matched";
    }

    if (message) {
        return <p className="fw-bold">{message}</p>;
    }

    // check === target
    const { headerInfo, baseUniqueLines, checkUniqueLines, matchedDiffLines, errors } = result.result;
    const diffHeaderInfo = [ "Source", ...headerInfo ];
    return <>
        <TableDisplay label="Different lines:" header={diffHeaderInfo} lines={matchedDiffLines} />
        <TableDisplay label="Unique Base lines:" header={headerInfo} lines={baseUniqueLines} />
        <TableDisplay label="Unique Target lines:" header={headerInfo} lines={checkUniqueLines} />
        { errors.length > 0 ? <>
            <p className="fw-bold">Comparison errors:</p>
            {errors.map((e, i) => <p key={i} className="mb-0">{e}</p>)}
        </> : null}
    </>
}

function TableDisplay({ label, header, lines }) {
    if (lines.length < 1) {
        return null;
    }
    const tableProps = { pagination: true };
    return <>
        <p className="fw-bold">{label}</p>
        <ArrayTable header={header} content={lines} sortable={true} tableProps={tableProps} />
    </>
}

function parseData(comparison) {
    comparison = parseComparison(comparison);
    // also look at results
    if (!Array.isArray(comparison.results)) {
        return comparison;
    }
    comparison.results.forEach(file => {
        if (typeof file.result !== "object") {
            return;
        }
        let { headerInfo, baseUniqueLines, checkUniqueLines, matchedDiffLines, errors } = file.result;
        // TODO: do we compare headers? should always be the same...
        headerInfo = headerInfo.base || headerInfo.check;
        headerInfo.splice(0, 0, "Line");

        baseUniqueLines = baseUniqueLines.map(parseLine);
        checkUniqueLines = checkUniqueLines.map(parseLine);
        matchedDiffLines = matchedDiffLines.flatMap(diff => {
            const base = parseLine(diff.base);
            base.splice(0, 0, "base");
            const check = parseLine(diff.check);
            check.splice(0, 0, "target");
            return [base, check];
        });

        file.result = { headerInfo, baseUniqueLines, checkUniqueLines, matchedDiffLines, errors };
    });
    return comparison;
}

function parseLine(line) {
    try {
        // Line XX: {csv-string}
        const index = line.indexOf(': ');
        if (index < 0) {
            throw new Error("Invalid format");
        }
        const lineNum = line.slice(0, index);
        const csvString = line.slice(index + 2);
        const { data, errors } = readString(csvString, { header: false, dynamicTyping: true });
        if (!data || data.length !== 1 || errors.length > 0) {
            throw new Error(errors.length > 0 ? JSON.stringify(errors) : "CSV parse failed!");
        }
        // convert dates to strings

        return [lineNum, ...data[0].map(value => {
            if (value && Object.prototype.toString.call(value) === '[object Date]') {
                return value.toISOString();
            }
            return value;
        })];
    } catch (error) {
        console.log("Unable to parse line", line, error);
    }
    return [ line ]; // array for table display
}

function noDifferences({ baseUniqueLines, checkUniqueLines, matchedDiffLines, errors }) {
    return baseUniqueLines.length === 0 && checkUniqueLines.length === 0 &&
        matchedDiffLines.length === 0 && errors.length === 0;
}

function fileSummary(result) {
    // some results are just string messages
    if (result.error || typeof result.result === "string") {
        return result.error || result.result;
    } else if (noDifferences(result.result)) {
        return "Files matched";
    }

    // check === target
    const { baseUniqueLines, checkUniqueLines, matchedDiffLines, errors } = result.result;
    return buildTextSummary(
        [ matchedDiffLines, baseUniqueLines, checkUniqueLines, errors ],
        [ 'different line', 'unique based line', 'unique target line', 'error' ]
    );
}

function buildTextSummary(arrays, texts) {
    const result = [];
    arrays.forEach((arr, i) => {
        if (arr.length > 0) {
            result.push(`${arr.length} ${texts[i]}` + (arr.length > 1 ? 's' : ''));
        }
    });
    return result.join(', ');
}
