
import React from 'react';

import { useSessionContext } from '../../session-context';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Dropdown from "react-bootstrap/Dropdown";
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';

import moment from 'moment';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList, faEdit, faEye } from '@fortawesome/free-solid-svg-icons';

import ActionIcon from '../../util/ActionIcon';
import Table from '../../util/Table';

import ShareModal from './shareModal';
import CreateModal from './createModal';
import { Link } from 'react-router-dom';
import SummaryModal from './summaryModal';
import { DownloadDropdownItem } from '../../util/DownloadLink';

import { DATE_FMT } from '../../util/scenarioFunctions';

export default function ProfileTable() {
    const session = useSessionContext();
    const [profiles, setProfiles] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [filteredProfiles, setFilteredProfiles] = React.useState([]);
    const [showSchemaId, setShowSchemaId] = React.useState("");
    const [showUser, setShowUser] = React.useState("");

    const [refresh, setRefresh] = React.useState(false);
    const [createNew, setCreateNew] = React.useState(false);
    const [shareProfile, setShareProfile] = React.useState(null);
    const [summaryProfile, setSummaryProfile] = React.useState(null);

    // load user profiles
    React.useEffect(() => {
        setLoading(true);
        session.getJson('/user-edits/profiles').then(({ data }) => {
            // identify which profiles I own so that they show up first, then sort by user
            data.forEach(p => p.mine = (p.username === session.user.name));
            data = data.sort(profileSort);

            setProfiles(data || []);
            setLoading(false);
        }).catch(error => {
            console.log(error);
            setProfiles([]);
            setLoading(false);
        });
    }, [session, refresh]);

    React.useEffect(() => {
        let filtered = profiles;
        if (showSchemaId) filtered = filtered.filter(p => p.schema?.id === showSchemaId);
        if (showUser) filtered = filtered.filter(p => p.username === showUser);
        setFilteredProfiles(filtered);
    }, [profiles, showSchemaId, showUser]);

    const doRefresh = () => setRefresh(v => !v);
    
    const dropdownArgs = { session, setShareProfile, doRefresh, setCreateNew };

    const myProfiles = profiles.filter(p => p.mine);

    // filter config
    const schemaOpts = {};
    profiles.map(p => p.schema).forEach(s => schemaOpts[s.id] = s.name);
    const schemaDropArgs = { options: schemaOpts, value: showSchemaId, setValue: val => setShowSchemaId(val ? parseInt(val) : "") };

    const userOpts = {};
    profiles.forEach(({username}) => userOpts[username] = username);
    const userDropArgs = { options: userOpts, value: showUser, setValue: setShowUser };

    const onNotesUpdate = (profileId, newNotes) => {
        session.postJson('/user-edits/profiles/' + profileId, { notes: newNotes }).then(r => {
            if (!r.ok) {
                throw new Error(`Invalid response: ${r.status} ${r.statusText}`);
            }
            doRefresh();
            setSummaryProfile(old => ({ ...old, notes: newNotes }));
        }).catch(error => {
            console.log(error);
            window.alert("Error saving notes: " + error.message);
        });
    };

    const cells = [
        { name: "Name", sortable: true, selector: p => p.profileName },
        { name: "Schema", sortable: true, selector: p => p.schema?.name },
        { name: "Owner", sortable: true, selector: p => p.username },
        { name: "Created", sortable: true, selector: p => p.created, format: formatCreated },
        { name: "Summary", grow: 0.5,
            selector: p => <SummaryButton profile={p} setSummaryProfile={setSummaryProfile} /> },
        { name: "Edit/View", grow: 0.5,
            selector: p => <LaunchButton profile={p} /> },
        { name: "Actions", allowOverflow: true, selector: p => <ActionDropdown profile={p} {...dropdownArgs} /> }
    ];

    return (<>
        <Container fluid="xl" className="text-center">
            <Row><Col><h3>User Edit Profiles</h3></Col></Row>
            <Row>
                <Col xs="4" lg="2" className="mb-2 d-flex align-items-center justify-content-end fw-bold">Schema:</Col>
                <Col xs="8" lg="4" className="mb-2"><FilterDropdown { ...schemaDropArgs} /></Col>
                <Col xs="4" lg="2" className="mb-2 d-flex align-items-center justify-content-end fw-bold">User:</Col>
                <Col xs="8" lg="4" className="mb-2"><FilterDropdown { ...userDropArgs} /></Col>
            </Row>
            <Table columns={cells} data={filteredProfiles} noDataComponent="No profiles found" progressPending={loading}/>
            <Row><Col>
                <Button className="mt-2" type="button" onClick={() => setCreateNew(true)}>Create New</Button>
            </Col></Row>
        </Container>
        <CreateModal createNew={createNew} setCreateNew={setCreateNew} myProfiles={myProfiles} doRefresh={doRefresh} />
        <ShareModal profile={shareProfile} setProfile={setShareProfile} />
        <SummaryModal profile={summaryProfile} setProfile={setSummaryProfile} onNotesUpdate={onNotesUpdate} />
    </>);
}

function FilterDropdown({ options, value, setValue }) {
    return <Form.Select size="sm" value={value} onChange={e => setValue(e.target.value)}>
        <option value="">Show all</option>
        { Object.keys(options).map(id => <option key={id} value={id}>{options[id]}</option>) }
    </Form.Select>;
}

function LaunchButton({ profile }) {
    return  (<Link to={"./" + profile.id} className="p-0 ms-2 btn btn-link btn-sm shadow-none" title={profile.mine ? "Edit" : "View"}>
        <FontAwesomeIcon icon={profile.mine ? faEdit : faEye} size="lg"></FontAwesomeIcon>
    </Link>);
}

function ActionDropdown({ profile, session, setShareProfile, doRefresh, setCreateNew }) {
    const downloadHref = "/user-edits/profiles/" + profile.id + "/edits?format=json";

    return (<Dropdown>
        <Dropdown.Toggle className="p-0 shadow-none" size="sm" variant="link" title="Click here to see other profile actions">
            Actions
        </Dropdown.Toggle>
        <Dropdown.Menu popperConfig={{ strategy: "fixed" }} renderOnMount={true}>
            <DownloadDropdownItem href={downloadHref}>Download JSON</DownloadDropdownItem>
            <Dropdown.Item onClick={() => setCreateNew(profile)}>Create copy</Dropdown.Item>
            {!profile.mine ? null : (<>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => setShareProfile(profile)}>Edit sharing</Dropdown.Item>
                <Dropdown.Item onClick={() => deleteProfile(session, profile, doRefresh)}>Delete</Dropdown.Item>
            </>)}
        </Dropdown.Menu>
    </Dropdown>);
}

function formatCreated(row) {
    return row.created ? moment(row.created).format(DATE_FMT) : '--';
}

function deleteProfile(session, profile, doRefresh) {
    if (!window.confirm("Are you sure you want to delete " + profile.profileName + "? This cannot be undone!")) {
        return;
    }
    session.delete('/user-edits/profiles/' + profile.id).then(doRefresh).catch(error => {
        console.log("error deleting", error);
        window.alert("Unable to delete profile!");
    });
}

function SummaryButton({profile, setSummaryProfile}) {
    return (<ActionIcon icon={faClipboardList} className="ms-2 p-0 shadow-none"
        title="View edits summary" action={e => setSummaryProfile(profile)} />);
}

function profileSort(p1, p2) {
    const val = userSort(p1, p2);
    if (val !== 0) {
        return val;
    }
    return p1.profileName.localeCompare(p2.profileName);
}

function userSort(p1, p2) {
    if (p1.mine && p2.mine) {
        return 0;
    } else if (p1.mine) {
        return -1;
    } else if (p2.mine) {
        return 1;
    }
    return p1.username.localeCompare(p2.username);
}
