
// see https://www.debuggr.io/react-update-unmounted-component
// when loading asychronously in conditionally rendered components, we have
// to be careful about aborting actions

import React from 'react';

export default function useIsMountedRef() {
    const ref = React.useRef(null);
    React.useEffect(() => {
        ref.current = true;
        return () => ref.current = false;
    });
    return ref;
}
