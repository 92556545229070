import React from 'react';

import { useSessionContext } from '../../session-context';
import StorageDisplay from '../StorageDisplay';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import ScenariosTable from './scenariosTable';
import ShareModal from './shareModal';
import { loadResults } from '../../util/result-data';
import NewComparisonModal from '../Comparison/NewComparisonModal';

export default function Results() {
    const session = useSessionContext();
    const [scenarios, setScenarios] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    // modal state
    const [shareModalState, setShareModalState] = React.useState(null);
    // toggle for when we archive or delete a scenario
    const [reload, setReload] = React.useState(false);
    // hook for comparing a scenario
    const [toCompare, setToCompare] = React.useState(null);

    React.useEffect(() => {
        setLoading(true);
        setScenarios([]);
        loadResults(session).then(data => {
            data.sort(dateSort);
            setScenarios(data);
            setLoading(false);
        }).catch(error => {
            console.log("Error loading scenarios!", error);
            setScenarios([]);
            setLoading(false);
        });
    }, [session, reload]);

    const doReload = () => setReload(val => !val);

    return (<>
        <StorageDisplay />
        <div className="px-3">
            <Container fluid className="text-center">
                <Row><Col><h3>Scenario Results</h3></Col></Row>
                <Row><Col>
                    <ScenariosTable scenarios={scenarios} loading={loading}
                        reload={doReload} setShareModalState={setShareModalState}
                        setToCompare={setToCompare} />
                </Col></Row>
            </Container>
        </div>
        <ShareModal state={shareModalState} setState={setShareModalState}/>
        <NewComparisonModal show={!!toCompare} setShow={() => setToCompare(null)} seed={toCompare} />
    </>);
}

function dateSort(s1, s2) {
    if (s1.runDate && s2.runDate) {
        return s2.runDate.valueOf() - s1.runDate.valueOf();
    } else if (s1.runDate) {
        return -1;
    } else if (s2.runDate) {
        return 1;
    } else {
        return 0;
    }
}
