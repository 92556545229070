
export const Actions = {
    SAVE: "SAVE",
    EXIT: "EXIT"
};

export default class ActionEventEmitter {
    constructor() {
        this.listeners = {};
    }

    on(event, listener) {
        // console.log("Registering", event, listener);
        if (Object.values(Actions).indexOf(event) < 0) {
            console.log("Unknown event", event);
            return;
        }
        if (typeof listener !== "function") {
            console.log("Cannot add non-function as a listener!");
            return;
        }
        this.listeners[event] = listener;
    }

    dispatchEvent(event) {
        const listener = this.listeners[event] || (e => console.log("No listener for", e));
        listener(event);
    }
}
