import React from "react";

import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

export function ListFilter({ idList, displayFn, onChange, maxHeight }) {
    const [selected, setSelected] = React.useState(null);
    const [show, setShow] = React.useState(false);

    const doChange = value => {
        setSelected(value);
        onChange(value);
    };

    const noVal = selected === null || typeof selected === "undefined";
    const mapper = id => (
        <Dropdown.Item key={id} className={selected === id ? "active" : ""} onClick={e => {
            doChange(id);
            setShow(false);
        }}>
            {displayFn ? displayFn(id) : id}
        </Dropdown.Item>
    );

    const toggleClear = e => doChange(null);

    return (<CoreFilter noVal={noVal} show={show} setShow={setShow} maxHeight={maxHeight}>
        {noVal ? null : (
            <Dropdown.Item onClick={toggleClear}>
                <em>Clear filter</em>
            </Dropdown.Item>
        )}
        {idList.map(mapper)}
    </CoreFilter>);
}

/**
 * 
 * @param {object} params
 * @param {function} onChange Make sure this is a react callback!
 * @returns 
 */
export function WildcardFilter({ onChange }) {
    const [text, setText] = React.useState('');
    const [show, setShow] = React.useState(false);

    const noVal = !text;
    // when show changes to false, update parent
    React.useEffect(() => {
        if (!show) {
            onChange(wildcardValue(text));
        }
    }, [show, text, onChange]);

    const toggleClear = e => {
        setText('');
        setShow(false);
    };
    
    const onKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setShow(false);
        }
    };

    return <CoreFilter noVal={noVal} show={show} setShow={setShow}>
        <Dropdown.ItemText className="p-0">
            <InputGroup size="sm" className="mx-2 my-1" style={{ width: '200px' }}>
                <Form.Control type="text" value={text} aria-describedby="cancel-button"
                    onChange={e => setText(e.target.value)}
                    onKeyDown={onKeyPress} />
                <Button variant="outline-danger" id="cancel-button" onClick={toggleClear}>X</Button>
            </InputGroup>
            <small className="mx-2 text-muted">% can be used as a wildcard</small>
        </Dropdown.ItemText>
    </CoreFilter>
}

/**
 * @param {string} text 
 */
function wildcardValue(text) {
    if (!text) {
        return null;
    } else if (text.indexOf('%') >= 0) {
        return { like: text };
    } else {
        return text;
    }
}

/**
 * 
 * @param {object} props
 * @param {boolean} props.noVal
 * @param {boolean} props.show
 * @param {Function} props.setShow
 * @param {JSX.Element} props.children
 * @returns 
 */
function CoreFilter({ noVal, children, show, setShow, maxHeight }) {
    // XXX: max height here has to be less than the PaginatedTable min hight
    // because the header is embedded inside the table
    // maybe there's someway to check the table height
    maxHeight = maxHeight || "250px";
    const menuStyle = { maxHeight, overflowY: "auto", overflowX: 'hidden' };

    return (<span className="d-flex opacity-100" style={{ flexGrow: 1, justifyContent: 'end' }}>
        <Dropdown className="d-inline" show={show} onToggle={nextVal => setShow(nextVal)}>
            <Dropdown.Toggle variant="" size="sm" className={noVal ? "" : "text-info"}>
                <FontAwesomeIcon icon={faFilter} />
            </Dropdown.Toggle>
            <Dropdown.Menu style={menuStyle}>
                { children }
            </Dropdown.Menu>
        </Dropdown>
    </span>);
}
