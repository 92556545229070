
import moment from 'moment';

import { readString } from 'react-papaparse';

import { ASSEMBLY_HEADERS } from './DataTable';

export async function parseFile(file) {
    const lines = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('error', e => {
            console.log(e);
            reject(newFileError("Error reading file!"));
        });
        reader.addEventListener('load', e => resolve(e.target.result));
        reader.readAsText(file);
    });
    return parseLines(lines);
}

function parseLines(contents) {
    if (!contents || !(contents = contents.trim())) {
        throw newFileError("File is empty!");
    }
    // just give us array of arrays (header false)
    const result = readString(contents, { header: false });
    if (result?.errors?.length > 0) {
        throw newFileError("Couldn't parse CSV contents: " + result.errors[0]?.message);
    } else if (result?.data?.length > 0) {
        return result.data;
    }
    throw newFileError("No lines were parsed!");
}

/**
 * 
 * @param {string[][]} result 
 * @param {boolean} append 
 * @param {any[][]} data 
 * @returns 
 */
export async function process(result, append, data) {
    // first, make sure the shape of our data is right
    const header = result[0];
    ASSEMBLY_HEADERS.forEach(({ name }, index) => {
        if (name.toUpperCase() !== (header[index]?.trim().toUpperCase())) {
            throw newFileError(`Invalid header, found '${header[index]}' instead of '${name}'`);
        }
    });

    // figure out what will be changed if the file is submitted
    const output = { added: [], changed: [], removed: [], unchanged: [] };
    // maybe the file was only the header
    if (result.length <= 1) {
        if (append) {
            output.unchanged = [...data];
        } else {
            output.removed = [...data];
        }
        return output;
    }
    // create a map of data by ID to identify matches
    const dataMap = {};
    data.forEach(record => dataMap[record[0]] = record);
    // make sure we don't have dupes
    const idMap = {};

    for (let i = 1; i < result.length; ++i) {
        const input = parseRecord(result[i]);
        const id = input[0];
        if (idMap[id]) {
            throw newFileError("Duplicate id in input data: " + input[0]);
        }
        idMap[id] = true;
        const existing = dataMap[id];
        if (!existing) {
            output.added.push(input);
            continue;
        }
        delete dataMap[id];
        const changed = compareRecords(existing, input);
        if (changed) {
            output.changed.push(changed);
        } else {
            output.unchanged.push(existing);
        }
    }
    // check for leftovers
    if (append) {
        output.unchanged = output.unchanged.concat(Object.values(dataMap));
    } else {
        output.removed = Object.values(dataMap);
    }
    return output;
}

/**
 * @param {string[]} input 
 */
function parseRecord(input) {
    return ASSEMBLY_HEADERS.map((header, index) => {
        const textValue = input[index]?.trim() || "";
        if (header._parsing?.isInt) {
            const intVal = parseInt(textValue, 10);
            if (!isNaN(intVal) && intVal.toString() === textValue) {
                return intVal;
            }
            throw newFileError(`Invalid value for ${header.name} value in line: ` + input.join(","));
        } else if (header._parsing?.isFloat) {
            const floatVal = parseFloat(textValue);
            if (!isNaN(floatVal)) {
                return floatVal;
            }
            throw newFileError(`Invalid value for ${header.name} value in line: ` + input.join(","));
        } else if (header._parsing?.isDate) {
            const dateVal = parseDate(textValue);
            if (dateVal) {
                return dateVal;
            }
            throw newFileError(`Invalid value for ${header.name} value in line: ` + input.join(","));
        } else if (header._parsing.options) {
            if (!header._parsing.options.includes(textValue)) {
                throw newFileError(`Invalid value for ${header.name} value in line: ` + input.join(","));
            }
        }
        return textValue;
    });
}

function parseDate(textValue) {
    // try a couple moment formats...
    const m = moment(textValue, [ "YYYY-MM-DD", "M/D/YYYY", "MM/DD/YYYY" ], true);
    return m.isValid() ? m.format("YYYY-MM-DD") : null;
}

function compareRecords(prev, next) {
    const changedArr = [ ...prev ];
    // track only fields that changed
    const changedObj = {};
    for (let i = 0; i < ASSEMBLY_HEADERS.length; ++i) {
        const header = ASSEMBLY_HEADERS[i];
        if (header._parsing?.isFloat) {
            if (Math.abs(prev[i] - next[i]) > 0.0001) {
                changedArr[i] = "*" + next[i];
                changedObj[header.name] = next[i];
            }
            continue;
        }
        if (prev[i] !== next[i]) {
            changedArr[i] = "*" + next[i];
            changedObj[header.name] = next[i];
        }
    }
    // only return changes if we actually had a difference
    if (Object.keys(changedObj).length > 0) {
        changedArr.push(changedObj);
        return changedArr;
    }
    return null;
}

function newFileError(message) {
    const err = new Error(message);
    err.isFileError = true;
    return err;
}
