
import React from 'react';

import qs from 'qs';

/**
 * @returns {[ object, function, function ]}
 */
export default function useFilter() {
    const [filter, setFilter] = React.useState({});

    const updateFilter = React.useCallback((key, value) => setFilter(filter => {
        if (value == null) {
            const newVal = { ...filter };
            delete newVal[key];
            return newVal;
        }
        return { ...filter, [key]: value };
    }), []);

    return [ filter, updateFilter, setFilter ];
}

export function buildFilterString(filter) {
    // console.log(filter);
    const size = Object.keys(filter).length;
    if (size < 1) {
        return null;
    }
    // if more than 1, this is effectively an and
    return encodeURIComponent(qs.stringify(filter));
}
