import React from 'react';

import path from 'path-browserify';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { useSessionContext } from '../../session-context';
import StorageDisplay from '../StorageDisplay';
import NewComparisonModal from './NewComparisonModal';
import ComparisonTable from './ComparisonTable';
import { resultDirToMoment } from '../../util/scenarioFunctions';

export default function Comparison() {
    const session = useSessionContext();
    const [comparisons, setComparisons] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [modal, setModal] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        setComparisons([]);
        session.getJson('/results/compare').then(data => {
            // initial sort by id descending
            data.sort((a, b) => b.run_date - a.run_date);
            setComparisons(data.map(parseComparison));
            setLoading(false);
        }).catch(error => {
            console.log("Error loading comparisons!", error);
            setComparisons([]);
            setLoading(false);
        });
    }, [session, refresh]);

    const deleteComparison = id => {
        if (!window.confirm("Are you sure you want to delete the comparison?")) {
            return;
        }
        session.delete('/results/compare/' + id).then(() => setRefresh(v => !v)).catch(error => {
            window.alert("Unable to delete comparison!");
            console.log(error);
        });
    };

    return (<>
        <StorageDisplay />
        <div className="px-3">
            <Container fluid className="text-center">
                <Row><Col><h3>Result Comparison</h3></Col></Row>
                <Row className="mb-2"><Col>
                    <ComparisonTable comparisons={comparisons} loading={loading} deleteComparison={deleteComparison} />
                </Col></Row>
                <Row><Col className="text-start">
                    <Button type="button" variant="success" onClick={() => setModal(true)}>New comparison</Button>
                </Col></Row>
            </Container>
        </div>
        <NewComparisonModal show={modal} setShow={setModal}/>
    </>);
}

export function parseComparison(comparison) {
    let { user: baseUser, scenario: baseScenario, run: baseRun } = parseDirectory(comparison.base);
    let { user: targetUser, scenario: targetScenario, run: targetRun } = parseDirectory(comparison.target);
        
    return { ...comparison, baseUser, baseScenario, baseRun, targetUser, targetScenario, targetRun };
}

// ".../{user}/{scenario}/output/{run}"
function parseDirectory(directory) {
    const runStr = path.basename(directory);
    directory = path.dirname(path.dirname(directory));
    const scenario = path.basename(directory);
    const user = path.basename(path.dirname(directory));
    return { run: resultDirToMoment(runStr), scenario, user };
}
