
import React from 'react';

import Dropdown from 'react-bootstrap/Dropdown';

// apparently in modern browsers it is impossible to control the height of the dropdown for
// a select component. This attempts to rectify that by using a dropdown instead.

const CustomToggle = React.forwardRef(({ children, onClick, size }, ref) => {
    let className = "form-select text-truncate";
    if (size === "lg") {
        className += " form-select-lg";
    } else if (size === "sm") {
        className += " form-select-sm";
    }
    return <div ref={ref} onClick={onClick} className={className}>{children}</div>;
});

// TODO: there are all sorts of options we could add, but this is a good start

/**
 * Create a select-lookalike dropdown component
 * @param { Object } props 
 * @param { { key, value }[] } props.options - array of options
 * @param { Object= } props.value - selected key in the options array
 * @param { function } props.onChange - function to call with new key selection
 * @param { string= } props.dropMaxHeight - maxHeight of the dropdown. 200px, 2rem, etc.
 * @param { ('sm'|'lg')= } props.size - size of the input button
 * @returns 
 */
export default function Select({ options, value, onChange, dropMaxHeight, size }) {

    const current = options.find(opt => opt.key === value)?.value || "Select";

    const style = {};
    if (dropMaxHeight) {
        style.maxHeight = dropMaxHeight;
        style.overflowY = "auto";
    }

    return <Dropdown className="user-select-none">
        <Dropdown.Toggle as={CustomToggle} size={size}>{current}</Dropdown.Toggle>
        <Dropdown.Menu style={style} className="w-100">
            {options.map(option => <Dropdown.Item key={option.key} className="text-truncate" onClick={() => onChange(option.key)} >
                {option.value}
            </Dropdown.Item>)}
        </Dropdown.Menu>
    </Dropdown>;
}
