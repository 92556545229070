
import React from 'react';

import Leaflet from 'leaflet';

import { GestureHandling } from "leaflet-gesture-handling";
import LoadingSpinner from './LoadingSpinner';

import MessageControl from '../util/leaflet/MessageControl';

Leaflet.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

// default view
const CENTER_US_LAT_LON = [37, -95];

export default function Map({ height, geoJsonFeatures, loading }) {
    const [mapNode, setMapNode] = React.useState(null);
    const [mapState, setMapState] = React.useState(null);
    const [/* features */, setFeatures] = React.useState({});

    React.useEffect(() => {
        if (mapNode) {
            // console.log("setting up map");
            const map = Leaflet.map(mapNode, { gestureHandling: true }).setView(CENTER_US_LAT_LON, 4);
            Leaflet.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 19,
                attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            }).addTo(map);
            map.attributionControl.addAttribution(' Use CTRL + scroll to zoom');
            const messageControl = new MessageControl();
            messageControl.addTo(map);
            setMapState({ map, messageControl });
            return () => {
                // console.log("clearing map");
                map.gestureHandling.disable();
                map.remove();
                setMapState(null);
            };
        }
    }, [mapNode]);

    React.useEffect(() => {
        if (mapState) {
            setFeatures(prev => updateFeatures(prev, { ...geoJsonFeatures }, mapState.map, mapState.messageControl));
        }
    }, [mapState, geoJsonFeatures]);

    const mapRef = React.useCallback(node => {
        if (node) {
            setMapNode(node);
        }
    }, []);

    return <div className="position-relative">
        { loading ? <LoadingSpinner /> : null }
        <div ref={mapRef} style={{ height }} />
    </div>;
}

/**
 * 
 * @param {Object.<string, import('leaflet').GeoJSON | string>} prevFeatures 
 * @param {Object.<string, import('leaflet').GeoJSON | string>} nextFeatures 
 * @param {import('leaflet').Map} map 
 * @param {MessageControl} messageControl
 * @returns 
 */
function updateFeatures(prevFeatures, nextFeatures, map, messageControl) {
    const result = {};
    const messages = [];
    // remove any that were deleted
    Object.entries(prevFeatures).forEach(([key, geojson]) => {
        if (typeof geojson !== 'string') {
            if (!nextFeatures[key]) {
                    geojson.removeFrom(map);
            } else {
                result[key] = geojson;
                delete nextFeatures[key];
            }
        }
    });
    // then add any new
    Object.entries(nextFeatures).forEach(([key, geojson]) => {
        if (typeof geojson === 'string') {
            messages.push(geojson);
            return;
        }
        try {
            result[key] = geojson.addTo(map);
        } catch (error) {
            console.log(error);
        }
    });
    messageControl.setMessages(messages);

    return result;
}
