
import React from 'react';

import { useSessionContext } from '../../session-context';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';

export default function SummaryModal({ projection, setProjection, onNotesUpdate }) {
    const session = useSessionContext();
    const [summary, setSummary] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        loadData(session, projection, setSummary).finally(() => setLoading(false));
    }, [session, projection]); // refresh every projection change

    return (<Modal size='lg' animation={false} show={!!projection} onHide={() => setProjection(null)}>
        <Modal.Header closeButton>
            <Modal.Title style={{ wordBreak: 'break-all' }}>
                Summary {projection ? ("'" + projection.name + "'") : ''}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <SummaryBody summary={summary} loading={loading} />
            { projection?.id ? <NotesBody projection={projection} onNotesUpdate={onNotesUpdate} /> : null }
        </Modal.Body>
    </Modal>);
}

function SummaryBody({ summary, loading }) {
    if (loading) {
        return <p>Loading...</p>;
    }
    if (!summary || summary.length < 1) {
        return <p>No edits found</p>;
    }
     // server data is [assemblyCount, reactor count, type]
    return (<ul>
        {summary.map((row, index) =>
            <li key={index}>{row[0]} {row[2]} assemblies at {row[1]} reactors</li>)}
    </ul>);
}

function NotesBody({ projection, onNotesUpdate }) {
    const [notes, setNotes] = React.useState("");
    const [rows, setRows] = React.useState(4);

    React.useEffect(() => {
        const newNotes = projection?.notes || "";
        setNotes(newNotes);
        const lines = newNotes.split('\n').length;
        setRows(lines < 4 ? 4 : (lines > 10 ? 10 : lines));
    }, [projection]);

    const noSave = notes.trim() === projection?.notes?.trim();
    const onSave = () => onNotesUpdate(projection.id, notes.trim());

    if (!projection?.mine) {
        return (<>
            <p className="fw-bold mb-1">Notes:</p>
            <p style={{ whiteSpace: "pre-wrap" }}>{projection?.notes || "{no notes}"}</p>
        </>);
    }
    return (<>
        <p className="fw-bold mb-1">Notes:</p>
        <FormControl as="textarea" className='w-100 mb-3' rows={rows} value={notes} onChange={e => setNotes(e.target.value)} />
        <Button disabled={noSave} variant='success' onClick={onSave}>Save notes</Button>
    </>);
}

async function loadData(session, projection, setSummary) {
    if (!projection) {
        setSummary([]);
        return;
    } // else, get projection summary
    try {
        // either base or user
        let url = null;
        if (projection.id) {
            url = `/user-edits/user-projections/${projection.id}/summary`;
        } else {
            url = `/user-edits/fuel-projections/${projection.name}/summary`;
        }
        const { data } = await session.getJson(url);
        setSummary(data);
    } catch (error) {
        console.log("Error loading summary", error);
        setSummary([]);
    }
}
