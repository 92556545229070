
import React from 'react';

import { useSessionContext } from '../../session-context';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Modal from 'react-bootstrap/Modal';

const FORM_ID = "user-edits-create-modal-form";

const DATA_DEFAULTS = { profileName: "", schemaId: "", notes: "" };

export default function CreateModal({ createNew, setCreateNew, myProfiles, doRefresh }) {
    const session = useSessionContext();
    const [data, setData] = React.useState(DATA_DEFAULTS)
    const [schemas, setSchemas] = React.useState([]);

    // load schemas
    React.useEffect(() => {
        session.getJson('/user-edits/data/schemas').then(({ data }) => {
            setSchemas(data || []);
        }).catch(error => {
            console.log(error);
            setSchemas([]);
        });
    }, [session]);

    React.useEffect(() => {
        const update = { ...DATA_DEFAULTS };
        if (createNew?.profileName) {
            update.profileName = createNew.profileName + " copy";
        }
        if (createNew?.schema?.id) {
            update.schemaId = createNew.schema.id;
        }
        if (createNew?.notes) {
            update.notes = createNew.notes;
        }
        setData(update);
    }, [createNew]);

    const isDupe = !!myProfiles?.find(p => p.profileName === data.profileName && p.schemaId === data.schemaId);

    const onSubmit = (event) => {
        event.preventDefault();
        doSubmit(session, data, createNew, setCreateNew, doRefresh);
    };

    return (<Modal animation={false} show={!!createNew} onHide={() => setCreateNew(false)}>
        <Modal.Header closeButton>
            <Modal.Title>Create New Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {createNew.profileName ? <p>Copy of {createNew.profileName}</p> : null}
            <CreateBody {...{ onSubmit, schemas, createNew, data, setData, isDupe }} />
        </Modal.Body>
        <Modal.Footer>
            <Button disabled={isDupe} variant="success" type="submit" form={FORM_ID}>Save</Button>
            <Button className="ms-2" variant="danger" type="button" onClick={() => setCreateNew(false)}>Cancel</Button>
        </Modal.Footer>
    </Modal>);
}

function CreateBody({ onSubmit, schemas, createNew, data, setData, isDupe }) {
    const initialSchemaId = createNew?.schema?.id || "";

    let warning = null;
    if (initialSchemaId !== "" && initialSchemaId !== data.schemaId) {
        warning = <strong>Changing schemas may result in issues in the edits. Please review the edits after copying.</strong>;
    }

    const onChangeName = e => setData(old => ({ ...old, profileName: e.target.value }));
    const onChangeSchema = e => setData(old => ({ ...old, schemaId: e.target.value ? parseInt(e.target.value) : "" }));
    const onChangeNotes = e => setData(old => ({ ...old, notes: e.target.value }));

    return (<Form id={FORM_ID} onSubmit={onSubmit}>
        <FormGroup controlId="formProfileName" className="mb-3">
            <Form.Label>Profile name</Form.Label>
            <Form.Control type="text" required value={data.profileName} onChange={onChangeName} />
            { isDupe ? <Form.Text className='text-danger'>
                You already have a profile named `{data.profileName}` for the selected schema!
            </Form.Text> : null }
        </FormGroup>
        <FormGroup controlId="formSchemaSelect" className="mb-3">
            <Form.Label>Data version</Form.Label>
            <Form.Select required value={data.schemaId} onChange={onChangeSchema}>
                <option value="">Select one</option>
                {schemas.map(s => <option key={s.id} value={s.id}>{s.name}</option>)}
            </Form.Select>
            {warning}
        </FormGroup>
        <FormGroup controlId="formProfileNotes" className="mb-3">
            <Form.Label>Notes</Form.Label>
            <Form.Control as="textarea" value={data.notes} rows="4" onChange={onChangeNotes} />
        </FormGroup>
    </Form>);
}

async function doSubmit(session, { schemaId, profileName, notes }, createNew, setCreateNew, doRefresh) {
    // if create new is a boolean, we're actualy creating new, else we're copying
    const postData = { schemaId, profileName, notes };
    if (typeof createNew?.id === 'number') {
        postData.copyOf = createNew.id;
    }
    try {
        const r = await session.postJson("/user-edits/profiles", postData);
        if (!r.ok) {
            throw new Error(`Invalid response ${r.status} ${r.statusText}`);
        }
        setCreateNew(false);
        doRefresh();
    } catch (error) {
        console.log("Error creating profile", error);
        window.alert("Unable to create new!");
    }
}
